var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { Trad, TradProp } from "trad-lib";
import { DataProvider } from "../provider";
import { KPIsManagerCache, ToEKPIType, eKPIType, eKPI, IsPrice } from "../models/KPIsManager.bin";
import { IndicateurToString, eIndicateurType } from "adwone-engine/index.bin";
import { Typed, duplicate, toArray } from "../tools.bin";
import { eColumnType } from "../models/types.bin";
import { GetIndicateurKPIName } from "./IndicateurListing";
import { ref_DiscountClasses } from "../models/orientdb/ref_DiscountClasses.bin";
import { ref_DiscountTypes } from "../models/orientdb/ref_DiscountTypes.bin";
import { Sort } from "format-lib/index.bin";
import { eDiscountOptionType, eDiscountOptionValue } from "../models/external.bin";
import { ref_PropertyType } from "../models/orientdb/ref_PropertyType.bin";
export var DicoColumnType = (_a = {},
    _a[eColumnType.Property] = 0,
    _a[eColumnType.KPI] = 1,
    _a[eColumnType.PriceReturned] = 2,
    _a[eColumnType.Discount] = 3,
    _a[eColumnType.DiscountValue] = 4,
    _a[eColumnType.DiscountFO] = 5,
    _a[eColumnType.DiscountFOValue] = 6,
    _a[eColumnType.DiscountFOS] = 7,
    _a[eColumnType.DiscountFOSValue] = 8,
    _a[eColumnType.PriceBound] = 9,
    _a[eColumnType.DiscountValueBound] = 10,
    _a[eColumnType.DiscountFOValueBound] = 11,
    _a[eColumnType.Barter] = 12,
    _a);
var AggregatorBase = /** @class */ (function () {
    /**
     *
     */
    function AggregatorBase(objectType) {
        var _this = this;
        this.Provide = function () { return __awaiter(_this, void 0, void 0, function () {
            var propertyIOs, contents;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.MetadataToIndicateurOptions()];
                    case 1:
                        propertyIOs = _a.sent();
                        contents = __spreadArray([], propertyIOs, true);
                        return [2 /*return*/, this.Sort_IO(contents)];
                }
            });
        }); };
        this._objectType = objectType;
    }
    AggregatorBase.prototype.GetDefaultColumnNames = function () {
        return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, Typed([])];
        }); });
    };
    ;
    AggregatorBase.prototype.GetDefaultVentilationNames = function () {
        return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, []];
        }); });
    };
    ;
    AggregatorBase.prototype.GetEditableColumns = function () { return []; };
    ;
    AggregatorBase.prototype.GetEditableColumnsFullObject = function () { return []; };
    AggregatorBase.prototype.GetDefaultConfig = function () {
        return __awaiter(this, void 0, Promise, function () {
            var indicateurs, colConfigs, ventilationNames;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.Provide()];
                    case 1:
                        indicateurs = (_a.sent());
                        return [4 /*yield*/, this.GetDefaultColumnNames()];
                    case 2:
                        colConfigs = _a.sent();
                        return [4 /*yield*/, this.GetDefaultVentilationNames()];
                    case 3:
                        ventilationNames = _a.sent();
                        return [2 /*return*/, colConfigs.map(function (_a) {
                                var name = _a.name, colNames = _a.colNames;
                                return ({
                                    Name: name,
                                    Columns: colNames.map(function (c) { var _a; return (_a = indicateurs.find(function (i) { return c == i.indicateur.name; })) === null || _a === void 0 ? void 0 : _a.indicateur; }).filter(function (i) { return i; }),
                                    Ventilations: ventilationNames.map(function (c) { var _a; return (_a = indicateurs.find(function (i) { return c == i.indicateur.name; })) === null || _a === void 0 ? void 0 : _a.indicateur; }).filter(function (i) { return i; })
                                });
                            })];
                }
            });
        });
    };
    AggregatorBase.prototype.GetMediaFamilyII = function () {
        return Typed({
            type: eIndicateurType.info,
            name: TradProp('ModelProperties.MediaFamily', this._objectType),
            field: 'ModelProperties.MediaFamily',
            valueType: eKPIType.Rid,
            options: {
                priorityToField: 'ModelProperties.MediaFamilyOther'
            }
        });
    };
    AggregatorBase.prototype.GetPeriodicityII = function () {
        return Typed({
            type: eIndicateurType.info,
            name: TradProp('ModelProperties.Periodicity', this._objectType),
            field: 'ModelProperties.Periodicity',
            valueType: eKPIType.Rid,
            options: {
                priorityToField: 'ModelProperties.PeriodicityComment'
            }
        });
    };
    AggregatorBase.prototype.GetAgencyResII = function () {
        return Typed({
            type: eIndicateurType.info,
            name: TradProp('Agency_Res', this._objectType),
            field: 'Agency_Res',
            valueType: eKPIType.Rid,
            options: {
                priorityToField: 'ModelProperties.Agency_ResOther'
            }
        });
    };
    AggregatorBase.prototype.GetModelRidIO = function () {
        return Typed({
            indicateur: {
                type: eIndicateurType.info,
                name: TradProp('@rid', this._objectType),
                field: '@rid',
                valueType: eKPIType.String
            },
            columnType: eColumnType.Property
        });
    };
    AggregatorBase.prototype.getDiscountClasses = function (filter) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, barterType_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.discounts) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, DataProvider.search(ref_DiscountClasses.name)];
                    case 1:
                        _a.discounts = _b.sent();
                        _b.label = 2;
                    case 2:
                        if (!!filter) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getDiscountTypes(function (t) { return t.Name == "Barter"; })];
                    case 3:
                        barterType_1 = (_b.sent())[0];
                        filter = function (c) { return c.DiscountType != barterType_1["@rid"]; };
                        _b.label = 4;
                    case 4: return [2 /*return*/, this.discounts.filter(filter)];
                }
            });
        });
    };
    AggregatorBase.prototype.getDiscountTypes = function (filter) {
        return __awaiter(this, void 0, Promise, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.discountTypes) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, DataProvider.search(ref_DiscountTypes.name)];
                    case 1:
                        _a.discountTypes = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, this.discountTypes.filter(filter !== null && filter !== void 0 ? filter : (function (d) { return d.Name != "Barter"; }))];
                }
            });
        });
    };
    AggregatorBase.prototype.GetForcedIndicateurs = function () {
        return {};
    };
    AggregatorBase.prototype.MetadataToIndicateurOptions = function () {
        return __awaiter(this, void 0, Promise, function () {
            var properties, forcedIndicateurs, propertyTypes;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetUserProperties()];
                    case 1:
                        properties = _a.sent();
                        forcedIndicateurs = this.GetForcedIndicateurs();
                        return [4 /*yield*/, DataProvider.search(ref_PropertyType.name)];
                    case 2:
                        propertyTypes = _a.sent();
                        return [2 /*return*/, properties.filter(function (p) { return !p.hidden; }).flatMap(function (p) {
                                var _a;
                                var indInfo = undefined;
                                if (forcedIndicateurs === null || forcedIndicateurs === void 0 ? void 0 : forcedIndicateurs[p.name])
                                    indInfo = toArray(forcedIndicateurs[p.name]);
                                else {
                                    var propertyType = propertyTypes.find(function (pt) { return p.name == "ModelProperties.".concat(pt.Type); });
                                    indInfo = [{
                                            type: IsPrice(propertyType === null || propertyType === void 0 ? void 0 : propertyType.ValueType) ? eIndicateurType.kpi : eIndicateurType.info,
                                            name: TradProp(p.name, _this._objectType),
                                            field: p.name,
                                            valueType: (_a = propertyType === null || propertyType === void 0 ? void 0 : propertyType.ValueType) !== null && _a !== void 0 ? _a : ToEKPIType(p.type)
                                        }];
                                }
                                return indInfo.map(function (i) { return (Typed({
                                    indicateur: i,
                                    columnType: eColumnType.Property
                                })); });
                            })];
                }
            });
        });
    };
    AggregatorBase.prototype.KPIToIndicateurOptions = function (kpiType) {
        return __awaiter(this, void 0, Promise, function () {
            var kpis, lnk_kpis, options, columnType;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetUserKPIs()];
                    case 1:
                        kpis = _a.sent();
                        return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetLnkHasKPIs()];
                    case 2:
                        lnk_kpis = _a.sent();
                        ;
                        options = {};
                        columnType = eColumnType.KPI;
                        if (kpiType == eKPIType.PriceBound) {
                            columnType = eColumnType.PriceBound;
                            options = { isPriceBound: true };
                        }
                        if (kpiType == eKPIType.PriceReturned) {
                            columnType = eColumnType.PriceReturned;
                            options = { isPriceReturned: true };
                        }
                        return [2 /*return*/, kpis.filter(function (k) { return !kpiType ||
                                (kpiType && lnk_kpis.filter(function (lnk) { return lnk.KPI === k["@rid"] && [eKPIType.Price, kpiType].includes(lnk.ValueType); })).length; })
                                .map(function (k) {
                                /** on cherche le lnkKpi pour avoir la clé et le type */
                                var lnkKpi = lnk_kpis.find(function (lnk) { return lnk.KPI === k["@rid"]; });
                                if (!lnkKpi) {
                                    //console.log(`KPI not found`, k);
                                    return null;
                                }
                                var indKpi = {
                                    type: eIndicateurType.kpi,
                                    name: GetIndicateurKPIName(k.Name, kpiType),
                                    valueType: kpiType !== null && kpiType !== void 0 ? kpiType : lnkKpi.ValueType,
                                    field: lnkKpi.Id,
                                    options: __assign({ rid: k["@rid"] }, options)
                                };
                                if (k.Aggregate === false)
                                    indKpi.options.aggregate = false;
                                return {
                                    indicateur: indKpi,
                                    columnType: columnType
                                };
                            })
                                .filter(function (k) { return k; })];
                }
            });
        });
    };
    AggregatorBase.prototype.DiscountToIndicateurOptions = function () {
        return __awaiter(this, void 0, Promise, function () {
            var fraisRid, discounts, _a, _b, cpmDiscountIOs, discountIOs;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.getDiscountTypes()];
                    case 1:
                        fraisRid = (_c.sent()).find(function (t) { return t.Name === "Frais"; })["@rid"];
                        _a = Sort;
                        _b = [ref_DiscountClasses.name];
                        return [4 /*yield*/, this.getDiscountClasses()];
                    case 2:
                        discounts = _a.apply(void 0, _b.concat([_c.sent()]));
                        cpmDiscountIOs = discounts.filter(function (d) { return d.DiscountType == fraisRid && d.IsCPM; }).map(function (d) {
                            return ({
                                indicateur: {
                                    type: eIndicateurType.discount,
                                    valueType: eKPIType.Price,
                                    name: "".concat(Trad("CPM"), " ").concat(Trad(d.Name)),
                                    options: { rid: d["@rid"], value: eDiscountOptionValue.ValueCPM, type: eDiscountOptionType.CO }
                                },
                                columnType: eColumnType.DiscountCPM
                            });
                        });
                        discountIOs = discounts.map(function (d) {
                            return ([{
                                    indicateur: {
                                        type: eIndicateurType.discount,
                                        valueType: eKPIType.Percent,
                                        name: "".concat(Trad("taux"), " ").concat(Trad(d.Name)),
                                        options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.CO }
                                    },
                                    columnType: eColumnType.Discount
                                }, {
                                    indicateur: {
                                        type: eIndicateurType.discount,
                                        valueType: eKPIType.Percent,
                                        name: "".concat(Trad("taux"), " ").concat(Trad(d.Name), " FO"),
                                        options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.FO }
                                    },
                                    columnType: eColumnType.DiscountFO
                                }, {
                                    indicateur: {
                                        type: eIndicateurType.discount,
                                        valueType: eKPIType.Percent,
                                        name: "".concat(Trad("taux"), " ").concat(Trad(d.Name), " FOS"),
                                        options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.FOS }
                                    },
                                    columnType: eColumnType.DiscountFOS
                                }, {
                                    indicateur: {
                                        type: eIndicateurType.discount,
                                        valueType: eKPIType.Price,
                                        name: "".concat(Trad("montant"), " ").concat(Trad(d.Name)),
                                        options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.CO }
                                    },
                                    columnType: eColumnType.DiscountValue
                                }, {
                                    indicateur: {
                                        type: eIndicateurType.discount,
                                        valueType: eKPIType.Price,
                                        name: "".concat(Trad("montant"), " ").concat(Trad(d.Name), " FO"),
                                        options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.FO }
                                    },
                                    columnType: eColumnType.DiscountFOValue
                                }, {
                                    indicateur: {
                                        type: eIndicateurType.discount,
                                        valueType: eKPIType.Price,
                                        name: "".concat(Trad("montant"), " ").concat(Trad(d.Name), " FOS"),
                                        options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.FOS }
                                    },
                                    columnType: eColumnType.DiscountFOSValue
                                }]);
                        });
                        return [2 /*return*/, __spreadArray(__spreadArray([], cpmDiscountIOs, true), discountIOs.reduce(function (a, b) { return a.concat(b); }, [])
                                .sort(function (a, b) { return a.indicateur.type.localeCompare(b.indicateur.type); }), true)];
                }
            });
        });
    };
    AggregatorBase.prototype.GetDiscountIO_Typed = function (discountIOs, kpiType) {
        var options = {};
        var GetColumnType = function (columnType) {
            if (kpiType == eKPIType.PriceBound)
                return (columnType + "Bound");
            return columnType;
        };
        if (kpiType == eKPIType.PriceBound) {
            options = { isPriceBound: true };
        }
        if (kpiType == eKPIType.PriceReturned) {
            options = { isPriceReturned: true };
        }
        return discountIOs
            .filter(function (p) {
            var indicD = p.indicateur;
            // seulement les CO/FO en montant pour les valeurs déversées
            return (kpiType != eKPIType.PriceBound || [eDiscountOptionType.CO, eDiscountOptionType.FO].includes(indicD.options.type))
                && indicD.options.value === eDiscountOptionValue.Value;
        })
            .map(function (p) { return ({
            indicateur: __assign(__assign({}, duplicate(p.indicateur)), { name: GetIndicateurKPIName(p.indicateur.name, kpiType), valueType: kpiType, options: __assign(__assign({}, p.indicateur.options), options) }),
            columnType: GetColumnType(p.columnType)
        }); })
            .sort(function (a, b) { return a.indicateur.name.localeCompare(b.indicateur.name); });
    };
    AggregatorBase.prototype.DiscountToCumulIndicateurOption = function (discountTypeName, indicateurName, args) {
        var _a, _b;
        return __awaiter(this, void 0, Promise, function () {
            var isPriceReturned, taxesRid, discounts, taxesClasses, cumul;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        isPriceReturned = ((_a = args === null || args === void 0 ? void 0 : args.computedOptions) === null || _a === void 0 ? void 0 : _a.isPriceReturned) || ((_b = args === null || args === void 0 ? void 0 : args.discountOptions) === null || _b === void 0 ? void 0 : _b.isPriceReturned);
                        return [4 /*yield*/, this.getDiscountTypes()];
                    case 1:
                        taxesRid = (_c.sent()).find(function (t) { return t.Name === discountTypeName; })["@rid"];
                        return [4 /*yield*/, this.getDiscountClasses(function (d) { return d.DiscountType === taxesRid; })];
                    case 2:
                        discounts = _c.sent();
                        taxesClasses = discounts.map(function (d) { return (Typed({
                            type: eIndicateurType.discount,
                            valueType: isPriceReturned ? eKPIType.PriceReturned : eKPIType.Price,
                            name: "montant ".concat(d.Name),
                            options: __assign({ rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.CO }, args === null || args === void 0 ? void 0 : args.discountOptions)
                        })); });
                        cumul = __assign({ indicateur: Typed(__assign({ name: indicateurName, indicateurs: taxesClasses, operator: "+", type: eIndicateurType.computed, valueType: isPriceReturned ? eKPIType.PriceReturned : eKPIType.Price }, ((args === null || args === void 0 ? void 0 : args.computedOptions) != undefined && { options: args.computedOptions }))), columnType: eColumnType.DiscountValue }, args === null || args === void 0 ? void 0 : args.option);
                        return [2 /*return*/, cumul];
                }
            });
        });
    };
    AggregatorBase.prototype.Sort_IO = function (indicateurs) {
        return indicateurs.sort(function (a, b) {
            var type = DicoColumnType[a.columnType] - DicoColumnType[b.columnType];
            if (type != 0)
                return type;
            return a.indicateur.name.localeCompare(b.indicateur.name);
        });
    };
    AggregatorBase.prototype.UpdateIndicateursNames = function (indicateurs, _allIndicateurs) {
        return __awaiter(this, void 0, void 0, function () {
            var allIndicateurs, _a, signatures, _loop_1, _i, indicateurs_1, indicateur;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(_allIndicateurs !== null && _allIndicateurs !== void 0)) return [3 /*break*/, 1];
                        _a = _allIndicateurs;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.Provide()];
                    case 2:
                        _a = (_b.sent()).map(function (op) { return op.indicateur; });
                        _b.label = 3;
                    case 3:
                        allIndicateurs = _a;
                        signatures = allIndicateurs.map(function (ind) { return ({ signature: IndicateurToString(ind), name: ind.name }); });
                        if (indicateurs) {
                            _loop_1 = function (indicateur) {
                                var sign = IndicateurToString(indicateur);
                                var found = signatures.find(function (s) { return s.signature === sign; });
                                if (found)
                                    indicateur.name = found.name;
                            };
                            for (_i = 0, indicateurs_1 = indicateurs; _i < indicateurs_1.length; _i++) {
                                indicateur = indicateurs_1[_i];
                                _loop_1(indicateur);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AggregatorBase.prototype.CreateCompareIndicateur = function (title, field1, field2) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Typed({
                        name: title,
                        valueType: eKPIType.Number,
                        operator: "=",
                        type: eIndicateurType.computed,
                        indicateurs: [
                            Typed({
                                name: "net",
                                valueType: eKPIType.String,
                                type: eIndicateurType.info,
                                field: field1
                            }),
                            Typed({
                                name: "net",
                                valueType: eKPIType.String,
                                type: eIndicateurType.info,
                                field: field2
                            }),
                        ]
                    })];
            });
        });
    };
    AggregatorBase.prototype.GetCPMIndicateurOptions = function (kpiType) {
        if (kpiType === void 0) { kpiType = eKPIType.Price; }
        return __awaiter(this, void 0, Promise, function () {
            var kpis, lnk_kpis, medias, mediasPress, netKpi, netLnkKpi, cpmIOs, getIndicateurCPM;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetUserKPIs()];
                    case 1:
                        kpis = _a.sent();
                        return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetLnkHasKPIs()];
                    case 2:
                        lnk_kpis = _a.sent();
                        return [4 /*yield*/, KPIsManagerCache.GetMedias()];
                    case 3:
                        medias = _a.sent();
                        mediasPress = medias.find(function (m) { return m.Name === "PRESSE"; });
                        netKpi = kpis.find(function (k) { return k.Name == eKPI.Net; });
                        netLnkKpi = lnk_kpis.find(function (lnk) { return lnk.KPI === netKpi["@rid"]; });
                        cpmIOs = [];
                        getIndicateurCPM = function (name, performanceKPI, volumeKPI) {
                            var pKpi = kpis.find(function (k) { return k.Name == performanceKPI; });
                            var vKpi = kpis.find(function (k) { return k.Name == volumeKPI; });
                            if (!pKpi || !vKpi)
                                return;
                            var pLnKpi = lnk_kpis.find(function (lnk) { return lnk.KPI === pKpi["@rid"]; });
                            var vLnkKpi = lnk_kpis.find(function (lnk) { return lnk.KPI === vKpi["@rid"]; });
                            var cpm = _this.CreateCPM(name, kpiType, netLnkKpi, netKpi, pLnKpi, pKpi, mediasPress, vLnkKpi, vKpi);
                            cpmIOs.push({ indicateur: cpm, columnType: eColumnType.KPI });
                        };
                        getIndicateurCPM("cpm_net", eKPI.TotalCirculation, eKPI.Total);
                        getIndicateurCPM("cpm_paye_net", eKPI.PaidCirculation, eKPI.Paid);
                        return [2 /*return*/, cpmIOs];
                }
            });
        });
    };
    AggregatorBase.prototype.CreateCPM = function (name, kpiType, netLnkKpi, netKpi, diffusionLnKpi, diffusionKpi, mediasPress, nbtotalLnkKpi, nbtotalKpi) {
        return Typed({
            name: GetIndicateurKPIName(name, kpiType),
            valueType: kpiType,
            operator: "/",
            type: eIndicateurType.computed,
            options: { rate: 1000, isPriceReturned: kpiType == eKPIType.PriceReturned },
            indicateurs: [
                Typed({
                    name: "net",
                    valueType: kpiType,
                    type: eIndicateurType.kpi,
                    field: netLnkKpi.Id,
                    options: { rid: netKpi["@rid"], isPriceReturned: kpiType == eKPIType.PriceReturned }
                }),
                Typed({
                    name: 'diviseur_total',
                    valueType: eKPIType.Number,
                    type: eIndicateurType.computed,
                    operator: '+',
                    indicateurs: [
                        Typed({
                            name: "diffusion_total",
                            valueType: eKPIType.Number,
                            type: eIndicateurType.kpi,
                            field: diffusionLnKpi.Id,
                            options: {
                                rid: diffusionKpi["@rid"],
                                filter: { Media: mediasPress === null || mediasPress === void 0 ? void 0 : mediasPress["@rid"] }
                            }
                        }),
                        Typed({
                            name: "nb_total",
                            valueType: eKPIType.Number,
                            type: eIndicateurType.kpi,
                            field: nbtotalLnkKpi.Id,
                            options: {
                                rid: nbtotalKpi["@rid"],
                                filterIgnore: { Media: mediasPress === null || mediasPress === void 0 ? void 0 : mediasPress["@rid"] }
                            }
                        })
                    ]
                }),
            ]
        });
    };
    return AggregatorBase;
}());
export { AggregatorBase };
