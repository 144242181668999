var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Row, Ventilation, Table, Column, CellValue } from "./types.bin";
import { clone, compareArrays, distinct, GetSubElement, groupBy, hasOwnProperty, IndicateurInfoFilter, IsPromise, ReleaseEventLoop, safeArray, safeNumber, splitBlocks, toArray, toDictionaryList, Typed } from "hub-lib/tools.bin";
import { Trad, TradProp } from "trad-lib";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { GetDateRanges, IsIntersec, recurseAll, recurse as recuseData } from "tools-lib";
import { eDiscountOptionValue } from "hub-lib/models/external.bin";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { ReturnCurrencyProvider } from "hub-lib/business/ReturnCurrencyProvider.bin";
import { eColumnType } from "hub-lib/models/types.bin";
import moment from "moment";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { BuildProperties, EngineTools } from "./EngineTools";
import stringSimilarity from "string-similarity";
import { DataProvider } from "hub-lib/provider";
import { RightManager } from "hub-lib/models/types/rights.bin";
export var eIndicateurType;
(function (eIndicateurType) {
    eIndicateurType["kpi"] = "kpi";
    eIndicateurType["discount"] = "discount";
    eIndicateurType["info"] = "info";
    eIndicateurType["computed"] = "computed";
    eIndicateurType["join"] = "join";
    eIndicateurType["link"] = "link";
})(eIndicateurType || (eIndicateurType = {}));
var EngineOptions = /** @class */ (function () {
    function EngineOptions() {
        // options for the format of the table
        this.formatTable = true;
        // only compute the total of the table
        this.onlyTotal = false;
        // try to get cache
        this.uuidCache = null;
    }
    return EngineOptions;
}());
export { EngineOptions };
export function ConvertToIndicateur(r) {
    if (!Object.values(eIndicateurType).includes(r.type)) {
        return Typed({
            type: eIndicateurType.info,
            valueType: r.type == "@rid" ? eKPIType.Rid : eKPIType.String,
            field: r.field,
            name: r.field ? TradProp(r.field) : r.field
        });
    }
    return r;
}
export function CreateIndicateur(data) {
    var ind = undefined;
    switch (data.type) {
        case eIndicateurType.kpi:
            ind = new IndicateurKPI();
            break;
        case eIndicateurType.discount:
            ind = new IndicateurDiscount();
            break;
        case eIndicateurType.info:
            ind = new IndicateurInfo();
            break;
        case eIndicateurType.computed:
            ind = new IndicateurComputed();
            break;
        case eIndicateurType.join:
            ind = new IndicateurJoin();
            break;
        case eIndicateurType.link:
            ind = new IndicateurLink();
            break;
        default: {
            console.log("Not implemented CreateIndicateur", data);
            ind = new IndicateurInfo();
            //throw new Error("Not implemented CreateIndicateur");
        }
    }
    ind === null || ind === void 0 ? void 0 : ind.Load(data);
    return ind;
}
// ATTENTION, IGNORED PROPERTIES !!!!!!!!!!!!!!!!!!!
var ignoredProperties = ['name', 'headerGroup', 'isSchedulerHidden'];
var interv = null;
var totalMs = 0;
var nbCalls = 0;
export function IndicateurToString(ind) {
    var start = new Date().getTime();
    var copy = clone(ind);
    if (copy) {
        recurseAll(copy, function (rec) {
            if (typeof rec === "object")
                ignoredProperties.forEach(function (p) { return delete rec[p]; });
        });
        var sortObjectKeys_1 = function (obj) {
            if (Array.isArray(obj)) {
                return obj.map(sortObjectKeys_1).sort(function (a, b) { return JSON.stringify(a).localeCompare(JSON.stringify(b)); });
            }
            else if (typeof obj === 'object' && obj !== null) {
                var sortedObj_1 = {};
                Object.keys(obj).sort().forEach(function (key) {
                    sortedObj_1[key] = sortObjectKeys_1(obj[key]);
                });
                return sortedObj_1;
            }
            return obj;
        };
        copy = sortObjectKeys_1(copy);
    }
    var res = JSON.stringify(copy);
    // const elapsed = new Date().getTime() - start;
    // totalMs += elapsed;
    // nbCalls++;
    // if (!interv)
    //     interv = setInterval(() => {
    //         console.log(`[IndicateurToString] ${nbCalls} Elapsed ${Math.trunc(totalMs / 100) / 10}s`);
    //     }, 4000);
    return res;
}
/**
 * Boolean, est ce que l'indicateur est en devise restituée
 */
export function IsIndicateurReturned(ind) {
    var _a, _b;
    switch (ind.type) {
        case eIndicateurType.info:
            return false;
        case eIndicateurType.discount:
            return (_a = ind.options) === null || _a === void 0 ? void 0 : _a.isPriceReturned;
        case eIndicateurType.kpi:
            return (_b = ind.options) === null || _b === void 0 ? void 0 : _b.isPriceReturned;
        default:
            break;
    }
    return false;
}
export function isDirectionPercent(d) {
    return d == eDirection["%Vertical"] || d == eDirection["%VerticalTotal"] || d == eDirection["%Horizontal"] || d == eDirection["%HorizontalTotal"] || d == eDirection["%Evolution"];
}
export function isDirectionEvolution(d) {
    return d == eDirection.EvolutionU || d == eDirection["%Evolution"];
}
export var eDirection;
(function (eDirection) {
    eDirection["U"] = "Unit\u00E9";
    eDirection["%Vertical"] = "%V parent";
    eDirection["%VerticalTotal"] = "%V total";
    eDirection["%Horizontal"] = "%H parent";
    eDirection["%HorizontalTotal"] = "%H total";
    eDirection["EvolutionU"] = "Evolution (Unit\u00E9)";
    eDirection["%Evolution"] = "% Evolution";
})(eDirection || (eDirection = {}));
var IndicateurOptionBase = /** @class */ (function () {
    function IndicateurOptionBase() {
    }
    return IndicateurOptionBase;
}());
export { IndicateurOptionBase };
;
var IndicateurBase = /** @class */ (function () {
    function IndicateurBase() {
        var _this = this;
        this.Load = function (data) {
            Object.entries(data).forEach(function (_a) {
                var k = _a[0], v = _a[1];
                if (v && Object.keys(eIndicateurType).includes(v.type)) {
                    _this[k] = _this.Load(v);
                }
                else {
                    _this[k] = v;
                }
            });
        };
    }
    IndicateurBase.prototype.GetSubElement = function (data, prop) {
        var _a, _b, _c, _e;
        var subPropertyValueAsKey = ((_a = this.options) !== null && _a !== void 0 ? _a : new IndicateurOptionBase()).subPropertyValueAsKey;
        var result = GetSubElement(data, prop);
        if (subPropertyValueAsKey) {
            var keyVal = GetSubElement(data, subPropertyValueAsKey);
            result = GetSubElement(result, (_e = (_c = (_b = this.options) === null || _b === void 0 ? void 0 : _b.mapValueTo) === null || _c === void 0 ? void 0 : _c[keyVal]) !== null && _e !== void 0 ? _e : keyVal);
        }
        return result;
    };
    IndicateurBase.prototype.Compute = function (msg) {
        var _this = this;
        var _a, _b, _c, _e, _f;
        if (this.field === "Start")
            return new Date((_b = (_a = __spreadArray([], msg, true).sort(function (a, b) { return new Date(a.Start).getTime() - new Date(b.Start).getTime(); })) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.Start);
        if (this.field === "End")
            return new Date((_e = (_c = __spreadArray([], msg, true).sort(function (a, b) { return new Date(b.End).getTime() - new Date(a.End).getTime(); })) === null || _c === void 0 ? void 0 : _c[0]) === null || _e === void 0 ? void 0 : _e.End);
        // if type is date, return the min date
        if (this.valueType == eKPIType.Date) {
            var values_1 = msg.map(function (m) { return _this.GetSubElement(m, _this.field); }).filter(function (v) { return v; });
            var dates = (_f = values_1 === null || values_1 === void 0 ? void 0 : values_1.map) === null || _f === void 0 ? void 0 : _f.call(values_1, function (v) { return new Date(v); });
            if (!(dates === null || dates === void 0 ? void 0 : dates.length))
                return "";
            return new Date(Math.min.apply(Math, dates.map(function (d) { return d.getTime(); })));
        }
        if (this.field === "NbDays")
            return msg.map(function (m) { return Math.ceil((new Date(m.End).getTime() - new Date(m.Start).getTime()) / (1000 * 60 * 60 * 24)); });
        if (this.field === "NbWeeks")
            return msg.map(function (m) { return Math.ceil((new Date(m.End).getTime() - new Date(m.Start).getTime()) / (1000 * 60 * 60 * 24 * 7)); });
        var map = function (c) { var _a; return ((_a = c === null || c === void 0 ? void 0 : c.constructor) === null || _a === void 0 ? void 0 : _a.name) == 'RecordID' ? c.toString() : c; };
        var flatValues = [];
        var values = msg.map(function (m) { return _this.GetSubElement(m, _this.field); });
        values.forEach(function (v) {
            if (Array.isArray(v))
                v.forEach(function (e) { return flatValues.push(map(e)); });
            else
                flatValues.push(map(v));
        });
        return Array.from(new Set(flatValues));
    };
    /**
     * Prepare the data before computing the indicator,
     * for example, if we need to retrieve some data from the database and group the calls
     * @param msg
     * @returns
     */
    IndicateurBase.prototype.Prepare = function (msg) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                // Do nothing by default
                return [2 /*return*/];
            });
        });
    };
    return IndicateurBase;
}());
export { IndicateurBase };
var IndicateurJoin = /** @class */ (function (_super) {
    __extends(IndicateurJoin, _super);
    function IndicateurJoin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = eIndicateurType.join;
        _this.Compute = function (messages) { return __awaiter(_this, void 0, void 0, function () {
            var indicateurValues, indicInstance, computedValues, _loop_1, _i, messages_1, msg, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        indicateurValues = [];
                        indicInstance = this.indicateurs.map(function (i) { return CreateIndicateur(i); });
                        computedValues = [];
                        _loop_1 = function (msg) {
                            var indicateurValues_1, _b, indicInstance_1, indicateur, value;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        indicateurValues_1 = [];
                                        _b = 0, indicInstance_1 = indicInstance;
                                        _c.label = 1;
                                    case 1:
                                        if (!(_b < indicInstance_1.length)) return [3 /*break*/, 4];
                                        indicateur = indicInstance_1[_b];
                                        return [4 /*yield*/, indicateur.Compute([msg])];
                                    case 2:
                                        value = _c.sent();
                                        indicateurValues_1.push(value);
                                        _c.label = 3;
                                    case 3:
                                        _b++;
                                        return [3 /*break*/, 1];
                                    case 4:
                                        if (!computedValues.some(function (cv) { return JSON.stringify(cv) == JSON.stringify(indicateurValues_1); }))
                                            computedValues.push(indicateurValues_1);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _i = 0, messages_1 = messages;
                        _a.label = 1;
                    case 1:
                        if (!(_i < messages_1.length)) return [3 /*break*/, 4];
                        msg = messages_1[_i];
                        return [5 /*yield**/, _loop_1(msg)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, computedValues];
                    case 5:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [2 /*return*/, ""];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    return IndicateurJoin;
}(IndicateurBase));
export { IndicateurJoin };
export var eTotalDirection;
(function (eTotalDirection) {
    eTotalDirection["totalCrossedParentRow"] = "{totalCrossedParentRow}";
    eTotalDirection["totalRow"] = "{totalRow}";
    eTotalDirection["totalCrossedParentColumn"] = "{totalCrossedParentColumn}";
    eTotalDirection["totalColumn"] = "{totalColumn}";
})(eTotalDirection || (eTotalDirection = {}));
var IndicateurComputed = /** @class */ (function (_super) {
    __extends(IndicateurComputed, _super);
    function IndicateurComputed() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = eIndicateurType.computed;
        _this.Compute = function (messages) {
            var _a, _b, _c, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
            try {
                if ((_a = _this.options) === null || _a === void 0 ? void 0 : _a.match) {
                    var _loop_2 = function (m) {
                        if ("value" in m)
                            messages = messages.filter(function (msg) { return m['value'] == GetSubElement(msg, m['subProperty']); });
                        if ("filter" in m) {
                            if (!IndicateurInfoFilter(messages, m))
                                return { value: "-" };
                        }
                    };
                    for (var _i = 0, _v = _this.options.match; _i < _v.length; _i++) {
                        var m = _v[_i];
                        var state_1 = _loop_2(m);
                        if (typeof state_1 === "object")
                            return state_1.value;
                    }
                }
                if (_this.valueType == eKPIType.Price)
                    if (new Set((_b = messages === null || messages === void 0 ? void 0 : messages.map(function (m) { return m.Currency; })) !== null && _b !== void 0 ? _b : []).size != 1)
                        return '-';
                var getValues_1 = function (_messages) {
                    var _a, _b, _c;
                    var indicateurValues = [];
                    for (var _i = 0, _e = _this.indicateurs; _i < _e.length; _i++) {
                        var indicateur = _e[_i];
                        var instanceIndic = CreateIndicateur(indicateur);
                        var indicateurValue = instanceIndic.Compute(_messages);
                        if ((indicateurValue !== "" && !isNaN(indicateurValue)) || (["similarity", "="].includes(_this.operator)))
                            indicateurValues.push(indicateurValue);
                    }
                    if ((_b = (_a = _this.options) === null || _a === void 0 ? void 0 : _a.valueTransform) === null || _b === void 0 ? void 0 : _b.length) {
                        var _loop_3 = function (i) {
                            (_c = _this.options) === null || _c === void 0 ? void 0 : _c.valueTransform.forEach(function (vt) {
                                var value = indicateurValues[i];
                                var func = vt.function;
                                var funcArgs = vt.args;
                                if (value && typeof (value === null || value === void 0 ? void 0 : value[func]) === "function") {
                                    var funcPtr = value[func];
                                    if (funcArgs)
                                        indicateurValues[i] = funcPtr.apply(value, funcArgs);
                                    else
                                        indicateurValues[i] = funcPtr.apply(value);
                                }
                            });
                        };
                        for (var i = 0; i < indicateurValues.length; i++) {
                            _loop_3(i);
                        }
                    }
                    return indicateurValues;
                };
                var value = 0;
                var indicateurValues_2 = (_this.operator != "*") && getValues_1(messages);
                switch (_this.operator) {
                    case "*":
                        value = messages.map(function (m) {
                            var indicateurValues = getValues_1([m]);
                            if (indicateurValues.length != _this.indicateurs.length)
                                return 0;
                            return indicateurValues.reduce(function (a, b) { return a * b; }, 1);
                        }).reduce(function (a, b) { return a + b; }, 0);
                        break;
                    case "%":
                        if (indicateurValues_2.length != 2 || indicateurValues_2[1] === 0)
                            value = 0;
                        else
                            value = ((indicateurValues_2[0] / indicateurValues_2[1]) - 1);
                        break;
                    case "/":
                        if (indicateurValues_2.length != 2 || indicateurValues_2[1] === 0)
                            value = 0;
                        else
                            value = indicateurValues_2[0] / indicateurValues_2[1];
                        break;
                    case "=":
                        if (indicateurValues_2.length != 2)
                            value = -1;
                        else if ((((_c = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[0]) === null || _c === void 0 ? void 0 : _c[0]) == undefined || ((_e = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[1]) === null || _e === void 0 ? void 0 : _e[0]) == undefined) && (((_f = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[0]) === null || _f === void 0 ? void 0 : _f[0]) || ((_g = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[1]) === null || _g === void 0 ? void 0 : _g[0]))) {
                            if ((_h = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[0]) === null || _h === void 0 ? void 0 : _h[0])
                                value = 0;
                            else
                                value = -1;
                        }
                        else if (((_j = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[0]) === null || _j === void 0 ? void 0 : _j[0]) == undefined || ((_k = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[1]) === null || _k === void 0 ? void 0 : _k[0]) == undefined)
                            value = -1;
                        else
                            value = (((_l = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[0]) === null || _l === void 0 ? void 0 : _l[0]) === ((_m = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[1]) === null || _m === void 0 ? void 0 : _m[0])) ? 1 : 0;
                        break;
                    case "==":
                        // every values must be the same
                        if (hasOwnProperty(_this.options, "valueEquals")) {
                            var valueEquals_1 = _this.options.valueEquals;
                            if (indicateurValues_2.some(function (v) { return v[0] != valueEquals_1; }))
                                value = 0;
                            else
                                value = 1;
                        }
                        else if (indicateurValues_2.length < 2)
                            value = 1;
                        else if (indicateurValues_2.some(function (v, i) { return indicateurValues_2[i][0] !== indicateurValues_2[0][0]; }))
                            value = -1;
                        else
                            value = 1;
                        break;
                    case "|":
                        value = indicateurValues_2.reduce(function (a, b) { return a | b; }, 0);
                        break;
                    case "||":
                        value = indicateurValues_2.reduce(function (a, b) {
                            if (a !== undefined && a !== null)
                                return a;
                            return b;
                        }, null);
                        break;
                    case "-":
                        value = indicateurValues_2.reduce(function (a, b) { return a - b; }, ((_o = indicateurValues_2 === null || indicateurValues_2 === void 0 ? void 0 : indicateurValues_2[0]) !== null && _o !== void 0 ? _o : 0) * 2);
                        break;
                    case "+":
                        value = indicateurValues_2.reduce(function (a, b) { return a + b; }, 0);
                        break;
                    case "similarity":
                        if (indicateurValues_2.length != 2 || !indicateurValues_2.every(function (e) { return typeof e == "string"; }))
                            value = 0;
                        else
                            value = stringSimilarity.compareTwoStrings((_p = indicateurValues_2[0]) === null || _p === void 0 ? void 0 : _p.toString().toLocaleUpperCase(), (_q = indicateurValues_2[1]) === null || _q === void 0 ? void 0 : _q.toString().toLocaleUpperCase());
                        value = Math.round(value * 100);
                        break;
                    default:
                        throw new Error("Operator ".concat(_this.operator, " not implemented"));
                }
                switch ((_r = _this.options) === null || _r === void 0 ? void 0 : _r.round) {
                    case "ceil":
                        value = Math.ceil(value);
                        break;
                    default:
                        break;
                }
                return (((_t = (_s = _this.options) === null || _s === void 0 ? void 0 : _s.baseValue) !== null && _t !== void 0 ? _t : 0) + value) * (((_u = _this.options) === null || _u === void 0 ? void 0 : _u.rate) || 1);
            }
            catch (error) {
                console.error(error);
                return 0;
            }
        };
        return _this;
    }
    IndicateurComputed.prototype.Prepare = function (msg) {
        return __awaiter(this, void 0, Promise, function () {
            var _i, _a, indic;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _i = 0, _a = this.indicateurs;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        indic = _a[_i];
                        return [4 /*yield*/, CreateIndicateur(indic).Prepare(msg)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return IndicateurComputed;
}(IndicateurBase));
export { IndicateurComputed };
var IndicateurDiscount = /** @class */ (function (_super) {
    __extends(IndicateurDiscount, _super);
    function IndicateurDiscount() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Info type
         */
        _this.type = eIndicateurType.discount;
        _this.Compute = function (msg) {
            var _a, _b, _c, _e, _f, _g, _h;
            var promises = [];
            /** On ne prend pas les totaux en % pour le moment */
            if (_this.valueType === eKPIType.Percent && msg.length !== 1)
                return "";
            // récupère la valeur du discount
            var discounts = [];
            var _loop_4 = function (m) {
                var value = 0;
                if (_this.options.isPriceBound)
                    value = m.KPIs["Bound:".concat(_this.options.rid, ":").concat(_this.options.type == 'CO' ? eColumnType.DiscountValueBound : eColumnType.DiscountFOValueBound)];
                else {
                    if (_this.options.barter)
                        value = DiscountManager.GetValue(m.BarterPercents, _this.options.rid, _this.options.type, _this.options.value);
                    else {
                        var cascade = (_a = m["cache-cascade"]) !== null && _a !== void 0 ? _a : DiscountManager.UpdateCascadeSync(m, null); //TODO GET Campaign (CPM)
                        m["cache-cascade"] = cascade;
                        var foundDiscount = (_b = cascade[_this.options.type].find(function (d) { return d.Discount.DiscountClass == _this.options.rid; })) === null || _b === void 0 ? void 0 : _b.Discount;
                        if (_this.options.value == eDiscountOptionValue.ValueCPM)
                            value = (_c = foundDiscount === null || foundDiscount === void 0 ? void 0 : foundDiscount.ValueCPM) !== null && _c !== void 0 ? _c : 0;
                        else if (_this.options.value == eDiscountOptionValue.Rate)
                            value = (_f = (_e = foundDiscount === null || foundDiscount === void 0 ? void 0 : foundDiscount[_this.options.type]) === null || _e === void 0 ? void 0 : _e.Rate) !== null && _f !== void 0 ? _f : 0;
                        else
                            value = (_h = (_g = foundDiscount === null || foundDiscount === void 0 ? void 0 : foundDiscount[_this.options.type]) === null || _g === void 0 ? void 0 : _g.Value) !== null && _h !== void 0 ? _h : 0;
                    }
                }
                if (!isNaN(value) && _this.options.isPriceReturned) {
                    var res_1 = ToReturnedCurrency(m, value);
                    ;
                    if (IsPromise(res_1))
                        promises.push(Promise.resolve().then(function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _b = (_a = discounts).push;
                                        return [4 /*yield*/, res_1];
                                    case 1:
                                        _b.apply(_a, [(_c.sent()).value]);
                                        return [2 /*return*/];
                                }
                            });
                        }); }));
                    else
                        discounts.push(res_1.value);
                }
                else
                    discounts.push(value);
            };
            for (var _i = 0, msg_1 = msg; _i < msg_1.length; _i++) {
                var m = msg_1[_i];
                _loop_4(m);
            }
            var compute = function () { var _a; return (_a = discounts.filter(function (d) { return d; }).reduce(function (a, b) { return a + b; }, 0)) !== null && _a !== void 0 ? _a : 0; };
            if (promises.length)
                return Promise.all(promises).then(compute);
            return compute();
        };
        return _this;
    }
    return IndicateurDiscount;
}(IndicateurBase));
export { IndicateurDiscount };
var propertyOption = /** @class */ (function (_super) {
    __extends(propertyOption, _super);
    function propertyOption() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return propertyOption;
}(IndicateurOptionBase));
export { propertyOption };
var IndicateurInfo = /** @class */ (function (_super) {
    __extends(IndicateurInfo, _super);
    function IndicateurInfo(e) {
        var _this = _super.call(this) || this;
        /**
         * Locker on Info type
         */
        _this.type = eIndicateurType.info;
        _this.GetSubElement = function (data, prop) {
            var _a, _b, _c, _e, _f, _g, _h, _j;
            var _k = (_a = _this.options) !== null && _a !== void 0 ? _a : new propertyOption(), priorityToField = _k.priorityToField, subProperty = _k.subProperty, subPropertyFallback = _k.subPropertyFallback, MetaData = _k.MetaData, subPropertyValueAsKey = _k.subPropertyValueAsKey;
            var result = undefined;
            if (priorityToField) {
                var valuePriority = GetSubElement(data, priorityToField, (_b = _this.options) === null || _b === void 0 ? void 0 : _b.match);
                result = Boolean(valuePriority) ? valuePriority : GetSubElement(data, prop, (_c = _this.options) === null || _c === void 0 ? void 0 : _c.match);
            }
            else {
                var propertyName = subProperty ? "".concat(prop, ".").concat(subProperty).replace(/\./g, '') : prop;
                if (MetaData === null || MetaData === void 0 ? void 0 : MetaData.name)
                    propertyName = MetaData.name;
                result = GetSubElement(data, propertyName, (_e = _this.options) === null || _e === void 0 ? void 0 : _e.match);
                if (!result && subPropertyFallback)
                    result = GetSubElement(data, "".concat(prop, ".").concat(subPropertyFallback).replace(/\./g, ''), (_f = _this.options) === null || _f === void 0 ? void 0 : _f.match);
                if (subPropertyValueAsKey) {
                    var keyVal = GetSubElement(data, subPropertyValueAsKey);
                    result = GetSubElement(result, (_j = (_h = (_g = _this.options) === null || _g === void 0 ? void 0 : _g.mapValueTo) === null || _h === void 0 ? void 0 : _h[keyVal]) !== null && _j !== void 0 ? _j : keyVal);
                }
            }
            return result;
        };
        _this.Compute = function (msg) {
            var _a, _b;
            var value = _super.prototype.Compute.call(_this, msg);
            if ((_a = _this.options) === null || _a === void 0 ? void 0 : _a.value) {
                switch (_this.options.value.type) {
                    case "moment":
                        var dateVal = moment(new Date(value));
                        if (dateVal.isValid())
                            value = dateVal.valueOf();
                        break;
                    default:
                        break;
                }
            }
            // if (this.options?.formater) {
            //     switch (this.options.formater.type) {
            //         case "moment":
            //             const dateVal = moment(new Date(value));
            //             if (dateVal.isValid())
            //                 if (this.options.formater.format) {
            //                     value = dateVal.format(this.options.formater.format);
            //                     if (this.options.formater.format === "MMMM") {
            //                         value = Trad("month_" + dateVal.month())
            //                     }
            //                     if (this.options.formater.format === "WW") {
            //                         value = Trad("week_very_short") + value
            //                     }
            //                 } else if (this.options.formater.periodicity === "semester") {
            //                     value = "S" + (parseInt(dateVal.format('Q')) > 2 ? 2 : 1)
            //                 } else if (this.options.formater.periodicity === "datedWeek") {
            //                     value = Trad("week_of") + ' ' + GetCellTemplate(ePropType.Date)(dateVal.startOf("week"))
            //                 }
            //                 else value = "";
            //             break;
            //         case "trad":
            //             //const text = toArray(value).filter(Boolean).map(v => Trad(v)).join(", ");
            //             //console.log(value, text);
            //             //value = text;
            //             break;
            //         default:
            //             break;
            //     }
            // }
            if ((_b = _this.options) === null || _b === void 0 ? void 0 : _b.formater) {
                switch (_this.options.formater.type) {
                    case "moment":
                        var dateVal = moment(new Date(value));
                        if (dateVal.isValid()) {
                            if (_this.options.formater.format) {
                                switch (_this.options.formater.format) {
                                    case "MMMM":
                                        value = dateVal.month();
                                        break;
                                    case "MM":
                                        value = dateVal.month() + 1;
                                        break;
                                    case "WW":
                                        value = dateVal.week();
                                        break;
                                    case "YYYY":
                                        value = dateVal.year();
                                        break;
                                    case "DD":
                                        value = dateVal.date();
                                        break;
                                    case "[T]Q":
                                        value = dateVal.quarter();
                                        break;
                                    default:
                                        value = dateVal.format(_this.options.formater.format);
                                        break;
                                }
                            }
                            if (_this.options.formater.periodicity) {
                                switch (_this.options.formater.periodicity) {
                                    case "datedWeek":
                                        value = dateVal.startOf("week").toDate();
                                        break;
                                    case "semester":
                                        value = dateVal.quarter() > 2 ? 2 : 1;
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                        else
                            value = "";
                        break;
                    case "trad":
                        //const text = toArray(value).filter(Boolean).map(v => Trad(v)).join(", ");
                        //console.log(value, text);
                        //value = text;
                        break;
                    default:
                        break;
                }
            }
            if (Array.isArray(value) && value.length === 1)
                return value[0];
            return value;
        };
        if (e)
            Object.entries(e).forEach(function (_a) {
                var k = _a[0], v = _a[1];
                return _this[k] = v;
            });
        return _this;
    }
    return IndicateurInfo;
}(IndicateurBase));
export { IndicateurInfo };
var IndicateurKPI = /** @class */ (function (_super) {
    __extends(IndicateurKPI, _super);
    function IndicateurKPI() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Locker on KPI type
         */
        _this.type = eIndicateurType.kpi;
        _this.Compute = function (_msg) {
            var _a, _b, _c, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            if ((_a = _this.options) === null || _a === void 0 ? void 0 : _a.match) {
                (_b = _this.options) === null || _b === void 0 ? void 0 : _b.match.forEach(function (m) {
                    if ("value" in m)
                        _msg = _msg.filter(function (msg) { return m['value'] == GetSubElement(msg, m['subProperty']); });
                    if ("filter" in m) {
                        if (!IndicateurInfoFilter(_msg, m))
                            return "-";
                    }
                });
            }
            var msg = _msg.filter(function (m) { return Boolean(m); });
            var promises = [];
            var kpis = [];
            if (_this.valueType == eKPIType.Price)
                if (new Set(msg.map(function (m) { return m === null || m === void 0 ? void 0 : m.Currency; })).size != 1)
                    return '-';
            var flagged = new Set();
            var _loop_5 = function (m) {
                var field = ((_c = _this.options) === null || _c === void 0 ? void 0 : _c.isPriceBound) ? "Bound".concat(_this.field) : _this.field;
                var continueLoop = false;
                /** On ignore les messages qui ne remplissent pas la condition de filter */
                if ((_e = _this.options) === null || _e === void 0 ? void 0 : _e.filter) {
                    for (var _p = 0, _q = Object.entries(_this.options.filter); _p < _q.length; _p++) {
                        var _r = _q[_p], k = _r[0], v = _r[1];
                        /** Le KPI est considéré à 0 s'il ne répond pas un filtre */
                        if (m[k] != v) {
                            continueLoop = true;
                            continue;
                        }
                    }
                }
                /** On ignore les messges qui remplissent la condition du filterIgnore */
                if ((_f = _this.options) === null || _f === void 0 ? void 0 : _f.filterIgnore) {
                    for (var _s = 0, _t = Object.entries(_this.options.filterIgnore); _s < _t.length; _s++) {
                        var _u = _t[_s], k = _u[0], v = _u[1];
                        /** Le KPI est considéré à 0 s'il ne répond au filtre Ignore */
                        if (m[k] === v) {
                            continueLoop = true;
                            continue;
                        }
                    }
                }
                if (continueLoop)
                    return "continue";
                var getValue = function () {
                    var _a, _b, _c, _e, _f, _g;
                    // Do not count many times same data if groupBy is set
                    if ((_a = _this.options) === null || _a === void 0 ? void 0 : _a.groupBy) {
                        var val = _this.GetSubElement(m, field);
                        if (val && flagged.has(val))
                            return 0;
                        else if (val)
                            flagged.add(val);
                    }
                    if ((_b = _this.options) === null || _b === void 0 ? void 0 : _b.forceValue)
                        return _this.options.forceValue;
                    if ((_c = _this.options) === null || _c === void 0 ? void 0 : _c.subProperty)
                        return _this.GetSubElement(m, field + _this.options.subProperty.replace(/\./g, ''));
                    if ((_e = field === null || field === void 0 ? void 0 : field.includes) === null || _e === void 0 ? void 0 : _e.call(field, "."))
                        return _this.GetSubElement(m, field);
                    if ((_f = _this.options) === null || _f === void 0 ? void 0 : _f.subPropertyValueAsKey)
                        return _this.GetSubElement(m, field);
                    return (_g = m.KPIs) === null || _g === void 0 ? void 0 : _g[field];
                };
                var value = null;
                if ((_g = _this.options) === null || _g === void 0 ? void 0 : _g.groupBy)
                    value = (_h = getValue()) !== null && _h !== void 0 ? _h : 0;
                else
                    value = (_l = (_k = (_j = _this.options) === null || _j === void 0 ? void 0 : _j.forceValue) !== null && _k !== void 0 ? _k : getValue()) !== null && _l !== void 0 ? _l : 0;
                if (!isNaN(value) && value != 0 && ((_m = _this.options) === null || _m === void 0 ? void 0 : _m.isPriceReturned)) {
                    var res_2 = ToReturnedCurrency(m, value);
                    if (IsPromise(res_2))
                        promises.push(Promise.resolve().then(function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _b = (_a = kpis).push;
                                        return [4 /*yield*/, res_2];
                                    case 1:
                                        _b.apply(_a, [(_c.sent()).value]);
                                        return [2 /*return*/];
                                }
                            });
                        }); }));
                    else
                        kpis.push(res_2.value);
                }
                else
                    kpis.push(value);
            };
            for (var _i = 0, msg_2 = msg; _i < msg_2.length; _i++) {
                var m = msg_2[_i];
                _loop_5(m);
            }
            var aggregate = !hasOwnProperty(_this.options, "aggregate") || ((_o = _this.options) === null || _o === void 0 ? void 0 : _o.aggregate);
            if (!aggregate) {
                return kpis.length == 1 ? kpis[0] : "";
            }
            var compute = function () {
                // check if all values are numbers
                // if not, try to convert them
                // if canno convert, return '-'
                if (kpis === null || kpis === void 0 ? void 0 : kpis.some(function (k) { return typeof k != "number"; })) {
                    // if some isNaN return '-'
                    if (kpis.some(function (k) { return isNaN(k); }))
                        return '';
                    var converted = kpis.map(function (k) { return safeNumber(k); });
                    return converted.reduce(function (a, b) { return a + b; }, 0);
                }
                return kpis.length ? kpis.reduce(function (a, b) { return a + b; }, 0) : 0;
            };
            if (promises.length)
                return Promise.all(promises).then(compute);
            return compute();
        };
        return _this;
    }
    return IndicateurKPI;
}(IndicateurBase));
export { IndicateurKPI };
var IndicateurLink = /** @class */ (function (_super) {
    __extends(IndicateurLink, _super);
    function IndicateurLink() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = eIndicateurType.link;
        _this.Prepare = function (_msg) { return __awaiter(_this, void 0, void 0, function () {
            var indcatorKey, res;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        indcatorKey = IndicateurToString(this);
                        return [4 /*yield*/, Promise.all(this.options.links.map(function (ld) {
                                var _a, _b, _c;
                                var subElements = _msg.map(function (m) { return GetSubElement(m, ld.property); }).filter(function (v) { return v; });
                                return DataProvider.search(ld.className, {
                                    in: (_c = (_b = (_a = RightManager.GetUser()) === null || _a === void 0 ? void 0 : _a.sources) === null || _b === void 0 ? void 0 : _b.find(function (s) { return s["@class"] == ld.sourceType; })) === null || _c === void 0 ? void 0 : _c['@rid'],
                                    Referential: subElements
                                });
                            }))];
                    case 1:
                        res = _a.sent();
                        _msg.forEach(function (m, i) {
                            var _a;
                            var properties = distinct(_this.options.links.map(function (ld) { return ld.property; }));
                            if (!properties.some(function (p) { return GetSubElement(m, p); })) {
                                m["caching_".concat(indcatorKey)] = 1;
                                return 1;
                            }
                            var found = distinct(_this.options.links.flatMap(function (ld, j) {
                                return res[j].filter(function (r) {
                                    var valElement = GetSubElement(m, ld.property);
                                    return valElement && r.Referential == valElement;
                                });
                            }), function (r) { return r['@rid']; });
                            if ((_a = _this.options) === null || _a === void 0 ? void 0 : _a.cross) {
                                var isOk = (found.length >= _this.options.links.length)
                                    && Object.values(groupBy(found, function (r) { return r.out; })).some(function (v) { return distinct(v, function (e) { return e["@class"]; }).length == _this.options.links.length; });
                                m["caching_".concat(indcatorKey)] = isOk ? 1 : 0;
                                return;
                            }
                            m["caching_".concat(indcatorKey)] = (found === null || found === void 0 ? void 0 : found.length) > 0 ? 1 : 0;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.Compute = function (msg) {
            if (msg.length > 1)
                return "";
            var indcatorKey = IndicateurToString(_this);
            var res = msg.map(function (m) { return m["caching_".concat(indcatorKey)]; });
            return res;
        };
        return _this;
    }
    return IndicateurLink;
}(IndicateurBase));
export { IndicateurLink };
export var DefaultReturnCurrencyProvider = new ReturnCurrencyProvider(2000);
export function ToReturnedCurrency(m, value, _mgr) {
    var apply = function (rateRes) {
        var code = undefined;
        if (rateRes) {
            value *= (rateRes.rate || 1);
            code = rateRes.currency;
        }
        return { value: value, code: code };
    };
    var currMgr = _mgr !== null && _mgr !== void 0 ? _mgr : DefaultReturnCurrencyProvider;
    if (currMgr.HasExpired())
        return currMgr.GetCurrency(m.AdvertiserGroup, m.Advertiser, m.Currency, m.Start, m.End).then(apply);
    return apply(currMgr.GetCurrencySync(m.AdvertiserGroup, m.Advertiser, m.Currency, m.Start, m.End));
}
export function CreateIndicateurInfo(field, valueType, name) {
    return {
        name: name !== null && name !== void 0 ? name : TradProp(field, ref_Messages),
        valueType: valueType,
        field: field,
        type: eIndicateurType.info
    };
}
export function CreateIndicateurKPI(field, valueType, name) {
    return {
        name: name !== null && name !== void 0 ? name : Trad(field),
        valueType: valueType,
        field: field,
        type: eIndicateurType.kpi
    };
}
var EngineArgs = /** @class */ (function () {
    function EngineArgs() {
    }
    return EngineArgs;
}());
export { EngineArgs };
var AEngine = /** @class */ (function () {
    function AEngine(prototype) {
        var _a;
        this.Key = (_a = prototype === null || prototype === void 0 ? void 0 : prototype.name) !== null && _a !== void 0 ? _a : "default";
    }
    AEngine.prototype.init = function (data, inds) {
        return __awaiter(this, void 0, Promise, function () {
            var time5577, indsWithLinks, groups, _loop_6, _i, _a, _b, key, inds_1, _time5577;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        time5577 = new Date().getTime();
                        indsWithLinks = inds.filter(function (i) { var _a; return (_a = i.options) === null || _a === void 0 ? void 0 : _a.linksDescriptor; });
                        if (!indsWithLinks.length)
                            return [2 /*return*/];
                        groups = toDictionaryList(indsWithLinks, function (i) { return i.options.linksDescriptor.className + '-' + i.options.linksDescriptor.property; });
                        _loop_6 = function (key, inds_1) {
                            var params, _e, _f, dataLinked;
                            var _g;
                            return __generator(this, function (_h) {
                                switch (_h.label) {
                                    case 0:
                                        _g = {
                                                Active: true
                                            },
                                            _g[inds_1[0].options.linksDescriptor.property] = data.map(function (d) { return d['@rid']; });
                                        _e = distinct;
                                        _f = [[]];
                                        return [4 /*yield*/, BuildProperties(inds_1[0].options.linksDescriptor.className, inds_1)];
                                    case 1:
                                        params = (_g.properties = _e.apply(void 0, [__spreadArray.apply(void 0, [__spreadArray.apply(void 0, _f.concat([_h.sent(), true])), [inds_1[0].options.linksDescriptor.property], false])]),
                                            _g);
                                        return [4 /*yield*/, DataProvider.search(inds_1[0].options.linksDescriptor.className, params)];
                                    case 2:
                                        dataLinked = _h.sent();
                                        data.forEach(function (d) { return d[key] = dataLinked.filter(function (dl) { return dl[inds_1[0].options.linksDescriptor.property] == d['@rid']; }); });
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _i = 0, _a = Object.entries(groups);
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        _b = _a[_i], key = _b[0], inds_1 = _b[1];
                        return [5 /*yield**/, _loop_6(key, inds_1)];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        _time5577 = new Date().getTime();
                        console.log("[PERF] [ENGINE INIT] ", _time5577 - time5577, " ms");
                        return [2 /*return*/];
                }
            });
        });
    };
    AEngine.prototype.aggregator = function (data, ind) {
        var _a;
        var indicateur = ind.Compute ? ind : CreateIndicateur(ind);
        if ((_a = ind === null || ind === void 0 ? void 0 : ind.options) === null || _a === void 0 ? void 0 : _a.linksDescriptor) {
            var subElements = data.flatMap(function (d) { return d[ind.options.linksDescriptor.className + '-' + ind.options.linksDescriptor.property]; });
            return indicateur.Compute(subElements);
        }
        return indicateur.Compute(data);
    };
    //protected abstract dimensionValueGetter(data: T, dimension: (ADWProperty | Indicateur)): any;
    AEngine.prototype.dimensionValueGetter = function (doc, dim) {
        return __awaiter(this, void 0, void 0, function () {
            var v, indicateur, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        v = undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        indicateur = CreateIndicateur(dim);
                        return [4 /*yield*/, indicateur.Compute([doc])];
                    case 2:
                        v = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        v = GetSubElement(doc, dim.field);
                        return [3 /*break*/, 4];
                    case 4:
                        if (v === undefined)
                            return [2 /*return*/, null];
                        return [2 /*return*/, v];
                }
            });
        });
    };
    //private dimensionResolver: DimensionResolver<T>;
    AEngine.prototype.Aggregate = function (data, conf, dates, options) {
        var _a, _b;
        return __awaiter(this, void 0, Promise, function () {
            var table, rows, flatMode, rowIndicateurs, colVentils, rowVentils, time4129, _time4129, time4130, _time4130, total, _i, _c, r, row, indicateurToColumn, recurseColumns, columnsFrozen, columns, columnsFrozenRid, tpsCols;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, ReleaseEventLoop()];
                    case 1:
                        _e.sent();
                        table = new Table();
                        table.DocumentType = this.Key;
                        table.Ventilations = conf.propRows;
                        table.VentilationsColumns = conf.propColumns;
                        rows = [];
                        flatMode = false;
                        rowIndicateurs = !((_a = conf.propRows) === null || _a === void 0 ? void 0 : _a.length) ?
                            [Typed({
                                    field: "@rid",
                                    type: eIndicateurType.info,
                                    name: "@rid",
                                    valueType: eKPIType.Rid
                                })] : conf.propRows;
                        colVentils = [];
                        rowVentils = [];
                        if (!!(options === null || options === void 0 ? void 0 : options.onlyTotal)) return [3 /*break*/, 4];
                        console.log("[Ventilate row] Start ...");
                        time4129 = new Date().getTime();
                        return [4 /*yield*/, this.ventilate(data, rowIndicateurs)];
                    case 2:
                        rowVentils = _e.sent();
                        _time4129 = new Date().getTime();
                        console.log("[Ventilate row] Elapsed ", _time4129 - time4129, "ms");
                        console.log("[Ventilate column] Start ...");
                        time4130 = new Date().getTime();
                        return [4 /*yield*/, this.ventilate(data, conf.propColumns)];
                    case 3:
                        colVentils = _e.sent();
                        _time4130 = new Date().getTime();
                        console.log("[Ventilate column] Elapsed ", _time4130 - time4130, "ms");
                        _e.label = 4;
                    case 4:
                        total = new Ventilation();
                        total.Children = rowVentils;
                        // if (!hasOwnProperty(options, 'hideDetailsData') || !options.hideDetailsData) total.Data = [...data];
                        // else total.Data = [];
                        total.Data = __spreadArray([], data, true);
                        total.Dimension = Typed({
                            field: "@class",
                            type: eIndicateurType.info,
                            name: Trad("Total_table"),
                            valueType: eKPIType.String
                        });
                        total.Value = "Total";
                        total.Formated = "Total";
                        for (_i = 0, _c = [total]; _i < _c.length; _i++) {
                            r = _c[_i];
                            row = new Row();
                            Object.assign(row, r);
                            rows.push(row);
                        }
                        indicateurToColumn = function (k, data) {
                            var newcol = new Column();
                            newcol.Indicateur = k;
                            //newcol.Label = k.name;
                            newcol.Cell = { Value: k.name, Formated: k.name, Type: 'header' };
                            newcol.Data = data;
                            return newcol;
                        };
                        recurseColumns = function (vent) {
                            var cols = [];
                            vent === null || vent === void 0 ? void 0 : vent.forEach(function (v) {
                                var _a;
                                var col = new Column();
                                col.Indicateur = v.Dimension;
                                col.Cell = { Value: v.Value, Formated: (_a = toArray(v.Value)) === null || _a === void 0 ? void 0 : _a.join(','), Type: 'header' };
                                col.Data = v.Data;
                                // col.Label = toArray(v.Value)?.join(','); // TradProp(v.Dimension.field);
                                cols.push(col);
                                if (v.Children) {
                                    col.Children = __spreadArray(__spreadArray([], recurseColumns(v.Children), true), conf.indicateurToDisplay.map(function (k) { return indicateurToColumn(k, v.Data); }), true);
                                }
                                else
                                    col.Children = conf.indicateurToDisplay.map(function (k) { return indicateurToColumn(k, v.Data); });
                            });
                            return cols;
                        };
                        columnsFrozen = conf.propRows.map(function (r) {
                            var col = new Column();
                            col.Indicateur = r;
                            // col.Label = r.name;
                            col.Cell = { Value: r.name, Formated: r.name, Type: 'header' };
                            col.IsRow = true;
                            return col;
                        });
                        columns = recurseColumns(colVentils);
                        columnsFrozenRid = columnsFrozen.find(function (c) { return c.Indicateur.field === "@rid"; });
                        table.Columns = __spreadArray(__spreadArray(__spreadArray([], columnsFrozen, true), columns, true), conf.indicateurToDisplay.map(function (k) { return indicateurToColumn(k, null); }), true);
                        tpsCols = groupBy(table.Columns.filter(function (c) { var _a; return (_a = c === null || c === void 0 ? void 0 : c.Indicateur) === null || _a === void 0 ? void 0 : _a.headerGroup; }), function (c) { return c.Indicateur.headerGroup; });
                        Object.entries(tpsCols).forEach(function (_a) {
                            var key = _a[0], value = _a[1];
                            var parentTp = new Column();
                            parentTp.Label = key;
                            parentTp.Cell = { Value: key, Formated: key, Type: 'header' };
                            parentTp.Children = clone(value);
                            parentTp.Children.forEach(function (c) {
                                c.Label = c.Indicateur.name.replace(key, "").trim();
                                c.Cell = { Value: c.Label, Formated: c.Label, Type: 'header' };
                            });
                            table.Columns.push(parentTp);
                            table.Columns = table.Columns.filter(function (c) { return !value.includes(c); });
                        });
                        if (flatMode) {
                            rows = rows[0].Children;
                        }
                        table.Rows = rows;
                        table.Indicateurs = conf.indicateurToDisplay;
                        if (options === null || options === void 0 ? void 0 : options.temporalTotal)
                            this.addTimeVentilations(dates, options, table, data);
                        return [4 /*yield*/, this.aggregate(table, { enableDirections: ((_b = conf === null || conf === void 0 ? void 0 : conf.propColumns) === null || _b === void 0 ? void 0 : _b.length) > 0, tableEvolution: options === null || options === void 0 ? void 0 : options.tableEvolution })];
                    case 5:
                        _e.sent();
                        /** remove "@rid" column, in case of includeDetails */
                        // if (table.Columns.some(c => c.Indicateur.field === "@rid"))
                        //     table.Columns = table.Columns.filter(c => c.Indicateur.field !== "@rid");
                        if (columnsFrozenRid)
                            table.Columns = table.Columns.filter(function (c) { return c !== columnsFrozenRid; });
                        return [4 /*yield*/, ReleaseEventLoop()];
                    case 6:
                        _e.sent();
                        return [2 /*return*/, table];
                }
            });
        });
    };
    AEngine.prototype.addTimeVentilations = function (dates, options, table, data) {
        var ranges = GetDateRanges(dates.start, dates.end, options.temporalTotal);
        table.TimeVentilations = {
            Granularity: options.temporalTotal,
            Field: "Gross",
            Ranges: ranges.map(function (r) {
                return {
                    Start: r.start,
                    End: r.end,
                    Row: new Row(Typed({
                        field: "@rid",
                        type: eIndicateurType.info,
                        name: "@rid",
                        valueType: eKPIType.Rid
                    }), data.filter(function (d) {
                        var startMsg = new Date(d.Start);
                        return IsIntersec(r.start, r.end, startMsg, startMsg);
                    }))
                };
            })
        };
    };
    AEngine.prototype.ventilate = function (data, dims) {
        var _a, e_1, _b, _c;
        var _e;
        return __awaiter(this, void 0, Promise, function () {
            var ventils, clone, first, dicoKeyValue, dico, dicoFlat, _loop_7, this_1, _f, _g, _h, e_1_1, _i, _j, _k, key, values, ventil, _l;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        if (!dims)
                            return [2 /*return*/, undefined];
                        ventils = [];
                        clone = __spreadArray([], dims, true);
                        first = clone.shift();
                        if (!first)
                            return [2 /*return*/, undefined];
                        dicoKeyValue = {};
                        dico = [];
                        dicoFlat = {};
                        _m.label = 1;
                    case 1:
                        _m.trys.push([1, 7, 8, 13]);
                        _loop_7 = function () {
                            var _d, _key, keys, d, _o, _loop_8, _p, keys_1, key;
                            return __generator(this, function (_q) {
                                switch (_q.label) {
                                    case 0:
                                        _c = _h.value;
                                        _f = false;
                                        _q.label = 1;
                                    case 1:
                                        _q.trys.push([1, , 6, 7]);
                                        _d = _c;
                                        return [4 /*yield*/, this_1.dimensionValueGetter(_d, first)];
                                    case 2:
                                        _key = _q.sent();
                                        keys = toArray(_key).map(function (k) {
                                            var _a;
                                            var key = (_a = k === null || k === void 0 ? void 0 : k.toString) === null || _a === void 0 ? void 0 : _a.call(k);
                                            dicoKeyValue[key] = k;
                                            return key;
                                        });
                                        if (!(keys.length > 1)) return [3 /*break*/, 4];
                                        return [4 /*yield*/, this_1.split(_d, keys.length - 1)];
                                    case 3:
                                        _o = _q.sent();
                                        return [3 /*break*/, 5];
                                    case 4:
                                        _o = _d;
                                        _q.label = 5;
                                    case 5:
                                        d = _o;
                                        _loop_8 = function (key) {
                                            if (Array.isArray(key)) {
                                                var found_1 = dico.find(function (e) { return compareArrays(e.key, key); });
                                                if (found_1)
                                                    found_1.values.push(d);
                                                else
                                                    dico.push({ key: key, values: [d] });
                                                var foundContained = dico.filter(function (e) { return !compareArrays(e.key, key) && key.every(function (k) { return e.key.includes(k); }); });
                                                foundContained.forEach(function (f) { return f.values.push(d); });
                                                var toInclude = dico.filter(function (e) { return (e != found_1) && ((!Array.isArray(e.key) && found_1.key.includes(e.key)) || (Array.isArray(e.key) && e.key.every(function (k) { return found_1.key.includes(k); }))); });
                                                toInclude.forEach(function (e) { return e.values.forEach(function (v) {
                                                    if (!found_1.values.includes(v))
                                                        found_1.values.push(v);
                                                }); });
                                                var toIncludeFlat = Object.entries(dicoFlat).filter(function (e) { return found_1.key.includes(e[0]); });
                                                toIncludeFlat.forEach(function (e) { return e[1].forEach(function (v) {
                                                    if (!found_1.values.includes(v))
                                                        found_1.values.push(v);
                                                }); });
                                            }
                                            else {
                                                var found = dicoFlat[key];
                                                if (found)
                                                    found.push(d);
                                                else
                                                    dicoFlat[key] = [d];
                                                var foundContained = dico.filter(function (e) { return e.key.includes(key); });
                                                foundContained.forEach(function (f) { return f.values.push(d); });
                                            }
                                        };
                                        for (_p = 0, keys_1 = keys; _p < keys_1.length; _p++) {
                                            key = keys_1[_p];
                                            _loop_8(key);
                                        }
                                        return [3 /*break*/, 7];
                                    case 6:
                                        _f = true;
                                        return [7 /*endfinally*/];
                                    case 7: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _f = true, _g = __asyncValues(splitBlocks(data));
                        _m.label = 2;
                    case 2: return [4 /*yield*/, _g.next()];
                    case 3:
                        if (!(_h = _m.sent(), _a = _h.done, !_a)) return [3 /*break*/, 6];
                        return [5 /*yield**/, _loop_7()];
                    case 4:
                        _m.sent();
                        _m.label = 5;
                    case 5: return [3 /*break*/, 2];
                    case 6: return [3 /*break*/, 13];
                    case 7:
                        e_1_1 = _m.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 13];
                    case 8:
                        _m.trys.push([8, , 11, 12]);
                        if (!(!_f && !_a && (_b = _g.return))) return [3 /*break*/, 10];
                        return [4 /*yield*/, _b.call(_g)];
                    case 9:
                        _m.sent();
                        _m.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        if (e_1) throw e_1.error;
                        return [7 /*endfinally*/];
                    case 12: return [7 /*endfinally*/];
                    case 13:
                        _i = 0, _j = __spreadArray(__spreadArray([], dico, true), Object.entries(dicoFlat).map(function (_a) {
                            var k = _a[0], v = _a[1];
                            return ({ key: k, values: v });
                        }), true);
                        _m.label = 14;
                    case 14:
                        if (!(_i < _j.length)) return [3 /*break*/, 17];
                        _k = _j[_i], key = _k.key, values = _k.values;
                        ventil = new Ventilation();
                        ventil.Value = key === 'undefined' ? Trad('not_specified') : (typeof key == "string" ? ((_e = dicoKeyValue[key]) !== null && _e !== void 0 ? _e : key) : key);
                        ventil.Dimension = __assign(__assign({}, first), { field: first.field.replace("Returned", "") });
                        ventil.Data = values;
                        _l = ventil;
                        return [4 /*yield*/, this.ventilate(values, clone)];
                    case 15:
                        _l.Children = _m.sent();
                        ventils.push(ventil);
                        _m.label = 16;
                    case 16:
                        _i++;
                        return [3 /*break*/, 14];
                    case 17: return [2 /*return*/, ventils];
                }
            });
        });
    };
    AEngine.prototype.aggregate = function (table, options) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var time7268, parents, flatIndicateursByKey, flatSignatures, valuesIndicateurs, columnPromises, allPromises, allCurrencies, addValue, tableIndicateurs, allData, aggregateData, addRatioCell, evolutionFlatRows, getEvolutionCellValue, recurse, time6442, _time6442;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, ReleaseEventLoop()];
                    case 1:
                        _e.sent();
                        console.log("[Aggregate] Start...");
                        time7268 = new Date().getTime();
                        parents = {};
                        flatIndicateursByKey = {};
                        flatSignatures = [];
                        valuesIndicateurs = table.Columns.filter(function (c) { return !c.IsRow; });
                        recuseData(valuesIndicateurs, 'Children', function (col, deepth) {
                            var _a;
                            if (deepth == 0)
                                parents = {};
                            if (!((_a = col.Children) === null || _a === void 0 ? void 0 : _a.length)) {
                                var parentsCols = Object.values(parents)
                                    .slice(0, deepth);
                                var parentsKeys = parentsCols
                                    .map(function (p) { var _a; return ("{".concat((_a = p === null || p === void 0 ? void 0 : p.Cell) === null || _a === void 0 ? void 0 : _a.Value, "}")); }).join('');
                                // in case of evolution table, we need to add the evolution column signature to the key
                                var parentsKeysEvol = parentsCols
                                    .map(function (p) {
                                    var _a, _b, _c;
                                    if (((_a = p.Indicateur) === null || _a === void 0 ? void 0 : _a.name) == Trad("year"))
                                        return ("{".concat(((_b = p === null || p === void 0 ? void 0 : p.Cell) === null || _b === void 0 ? void 0 : _b.Value) - 1, "}"));
                                    return ("{".concat((_c = p === null || p === void 0 ? void 0 : p.Cell) === null || _c === void 0 ? void 0 : _c.Value, "}"));
                                }).join('');
                                flatSignatures.push(parentsKeys + IndicateurToString(col.Indicateur));
                                flatIndicateursByKey[parentsKeys + IndicateurToString(col.Indicateur)] = {
                                    column: col,
                                    parent: parents[deepth - 2],
                                    signatureParentEvol: parentsKeysEvol + IndicateurToString(col.Indicateur)
                                };
                            }
                            else {
                                parents[deepth] = col;
                            }
                        });
                        columnPromises = new Set();
                        allPromises = [];
                        return [4 /*yield*/, EngineTools.GetCurrencies()];
                    case 2:
                        allCurrencies = _e.sent();
                        addValue = function (value, ind, signature, row, data) {
                            var cellValue = {
                                Formated: '',
                                Value: value,
                                IndicateurSignature: signature,
                                IndicateurSignatureFull: signature,
                                Type: "cell"
                            };
                            EngineTools.AddCurrencyToCells(cellValue, ind, data, allCurrencies);
                            row.ValuesTotal.push(cellValue);
                            return cellValue;
                        };
                        tableIndicateurs = __spreadArray([], flatSignatures.map(function (k) {
                            var v = flatIndicateursByKey[k];
                            return {
                                signatureParentEvol: v === null || v === void 0 ? void 0 : v.signatureParentEvol,
                                column: v === null || v === void 0 ? void 0 : v.column,
                                parent: v === null || v === void 0 ? void 0 : v.parent,
                                indicateur: v === null || v === void 0 ? void 0 : v.column.Indicateur,
                                signatureFull: k,
                                data: v === null || v === void 0 ? void 0 : v.column.Data,
                                signature: IndicateurToString(v === null || v === void 0 ? void 0 : v.column.Indicateur)
                            };
                        }), true);
                        // add signature to rows
                        recuseData(table.Rows, 'Children', function (r, deepth) {
                            var _a, _b, _c, _e;
                            var baseSignature = "{".concat((_a = r === null || r === void 0 ? void 0 : r.Value) === null || _a === void 0 ? void 0 : _a.toString(), "}");
                            var valEvolSignature = baseSignature;
                            if (((_b = r === null || r === void 0 ? void 0 : r.Dimension) === null || _b === void 0 ? void 0 : _b.name) == Trad("year"))
                                valEvolSignature = ("{".concat((_c = (Number(r.Value) - 1)) === null || _c === void 0 ? void 0 : _c.toString(), "}"));
                            if (!r.Signature) {
                                r.Signature = baseSignature;
                                r.SignatureEvol = valEvolSignature;
                            }
                            else {
                                r.Signature += baseSignature;
                                r.SignatureEvol += valEvolSignature;
                            }
                            if ((_e = r.Children) === null || _e === void 0 ? void 0 : _e.length)
                                r.Children.forEach(function (c) { c.Signature = r.Signature; c.SignatureEvol = r.SignatureEvol; });
                        });
                        allData = table.Rows.map(function (r) { return r.Data; }).flat();
                        aggregateData = function (row, data, indicateur, prefixSignature) {
                            var _a;
                            var signature = IndicateurToString(indicateur);
                            var signatureFull = prefixSignature + signature;
                            var existingValue = (_a = row.ValuesTotal) === null || _a === void 0 ? void 0 : _a.find(function (v) { return v.IndicateurSignatureFull == signatureFull; });
                            if (existingValue)
                                return existingValue;
                            var computeTotalCellRow = function () {
                                var resultOfCellRow = _this.aggregator(data, indicateur);
                                var cell = addValue(resultOfCellRow, indicateur, signature, row, data);
                                cell.IndicateurSignatureFull = signatureFull;
                                return cell;
                            };
                            // const cell = row.ValuesTotal.find(v => v.IndicateurSignatureFull == signatureFull) ?? computeTotalCellRow();
                            var cell = computeTotalCellRow();
                            return cell;
                        };
                        addRatioCell = function (indicateur, signature, signatureFull, row, dataCrossed, direction, cellCrossed, cellTotal) {
                            var signaturePercentRow = "{".concat(direction, "}").concat(signatureFull);
                            // check already exists
                            if (row.ValuesTotal.find(function (v) { return v.IndicateurSignatureFull == signaturePercentRow; }))
                                return;
                            var resRatio = cellTotal.Value ? (safeNumber(cellCrossed.Value) / cellTotal.Value) : 0;
                            if (isNaN(resRatio))
                                resRatio = 0;
                            addValue(resRatio, indicateur, signature, row, dataCrossed).IndicateurSignatureFull = signaturePercentRow;
                        };
                        evolutionFlatRows = [];
                        recuseData((_a = options === null || options === void 0 ? void 0 : options.tableEvolution) === null || _a === void 0 ? void 0 : _a.Rows, 'Children', function (row, parent) { return evolutionFlatRows.push(row); });
                        getEvolutionCellValue = function (row, indicateur, signature, signatureFull) {
                            var _a;
                            // get crossing values prefix of columns
                            // ex: "{valCol1}{valCol2}{indicateurEvolSignature}" => "{valCol1}{valCol2}"
                            var prefixInd = signatureFull.replace(signature, "");
                            // then generate the crossing values + base incateur to get it in the evolution table
                            var cloneIndicateur = clone(indicateur);
                            cloneIndicateur.optionsBase = __assign(__assign({}, cloneIndicateur.optionsBase), { direction: null });
                            // ex: "{valCol1}{valCol2}{indicateurEvolSignature}" => "{valCol1}{valCol2}{indicateurSignature}"
                            var evolIndSignature = "".concat(prefixInd).concat(IndicateurToString(cloneIndicateur));
                            // find the row in the evolution table with the same signature
                            var evolRow = evolutionFlatRows.find(function (r) { return r.Signature == row.SignatureEvol; });
                            // get the cell in the evolution table with base indicateur signature
                            var cellEvolution = (_a = evolRow === null || evolRow === void 0 ? void 0 : evolRow.ValuesTotal) === null || _a === void 0 ? void 0 : _a.find(function (v) { return v.IndicateurSignatureFull == evolIndSignature; });
                            return cellEvolution;
                        };
                        recurse = function (rows, parentRow) { return __awaiter(_this, void 0, void 0, function () {
                            var _i, rows_1, row, _loop_9, this_2, _a, tableIndicateurs_1, _b, indicateur, signature, data, signatureFull, parent, signatureParentEvol;
                            var _c, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
                            return __generator(this, function (_u) {
                                switch (_u.label) {
                                    case 0:
                                        if (!rows) return [3 /*break*/, 8];
                                        _i = 0, rows_1 = rows;
                                        _u.label = 1;
                                    case 1:
                                        if (!(_i < rows_1.length)) return [3 /*break*/, 8];
                                        row = rows_1[_i];
                                        row.ValuesTotal = [];
                                        _loop_9 = function (indicateur, signature, data, signatureFull, parent, signatureParentEvol) {
                                            var dataCrossed, resultOfCellCrossed, isColEvol, cellCrossed, uCell, crossedColDataParent, cellCrossedColParent, cellTotalRow, crossedRowDataParent, cellCrossedRowParent, cellTotalColumn, cellEvolutionU, cellEvolutionValue, diff, currencies, currencyCode, evolCell, cellEvolution, signaturePercentRow, resRatio;
                                            return __generator(this, function (_v) {
                                                switch (_v.label) {
                                                    case 0:
                                                        dataCrossed = data ? (row.Data.filter(function (d) { return data.some(function (d2) { return d2['@rid'] == d['@rid']; }); })) : row.Data;
                                                        return [4 /*yield*/, this_2.aggregator(dataCrossed, indicateur)];
                                                    case 1:
                                                        resultOfCellCrossed = _v.sent();
                                                        isColEvol = (options === null || options === void 0 ? void 0 : options.enableDirections) && ((_c = indicateur === null || indicateur === void 0 ? void 0 : indicateur.optionsBase) === null || _c === void 0 ? void 0 : _c.direction);
                                                        cellCrossed = isColEvol
                                                            ? ((_f = (_e = row.ValuesTotal) === null || _e === void 0 ? void 0 : _e.find(function (v) { return v.IndicateurSignatureFull == signatureFull; })) !== null && _f !== void 0 ? _f : addValue(resultOfCellCrossed, indicateur, signature, row, dataCrossed))
                                                            : addValue(resultOfCellCrossed, indicateur, signature, row, dataCrossed);
                                                        cellCrossed.IndicateurSignatureFull = signatureFull;
                                                        // const cellRow = row.ValuesTotal.find(v => v.IndicateurSignatureFull == signatureTotalRow) ?? computeTotalCellRow();
                                                        if (IsPromise(resultOfCellCrossed)) {
                                                            columnPromises.add(indicateur.name);
                                                            allPromises.push(resultOfCellCrossed.then(function (v) { return cellCrossed.Value = v; }));
                                                        }
                                                        else {
                                                            if (isColEvol) {
                                                                switch ((_g = indicateur === null || indicateur === void 0 ? void 0 : indicateur.optionsBase) === null || _g === void 0 ? void 0 : _g.direction) {
                                                                    case eDirection.U:
                                                                        uCell = addValue(cellCrossed.Value, indicateur, signature, row, dataCrossed);
                                                                        uCell.IndicateurSignatureFull = "{".concat(eDirection.U, "}").concat(signatureFull);
                                                                        break;
                                                                    case eDirection["%Horizontal"]:
                                                                        crossedColDataParent = (_h = (row === null || row === void 0 ? void 0 : row.Data.filter(function (d) { var _a, _b; return (_b = ((_a = parent === null || parent === void 0 ? void 0 : parent.Data) !== null && _a !== void 0 ? _a : allData)) === null || _b === void 0 ? void 0 : _b.some(function (d2) { return d2['@rid'] == d['@rid']; }); }))) !== null && _h !== void 0 ? _h : [];
                                                                        cellCrossedColParent = aggregateData(row, crossedColDataParent, indicateur, eTotalDirection.totalCrossedParentRow);
                                                                        addRatioCell(indicateur, signature, signatureFull, row, dataCrossed, eDirection["%Horizontal"], cellCrossed, cellCrossedColParent);
                                                                        break;
                                                                    case eDirection["%HorizontalTotal"]:
                                                                        cellTotalRow = aggregateData(row, (_j = row.Data) !== null && _j !== void 0 ? _j : [], indicateur, eTotalDirection.totalRow);
                                                                        addRatioCell(indicateur, signature, signatureFull, row, dataCrossed, eDirection["%HorizontalTotal"], cellCrossed, cellTotalRow);
                                                                        break;
                                                                    case eDirection["%Vertical"]:
                                                                        crossedRowDataParent = (_l = (((_k = parentRow === null || parentRow === void 0 ? void 0 : parentRow.Data) !== null && _k !== void 0 ? _k : allData).filter(function (d) { var _a; return (_a = (data !== null && data !== void 0 ? data : [])) === null || _a === void 0 ? void 0 : _a.some(function (d2) { return d2['@rid'] == d['@rid']; }); }))) !== null && _l !== void 0 ? _l : [];
                                                                        cellCrossedRowParent = aggregateData(row, crossedRowDataParent, indicateur, eTotalDirection.totalCrossedParentColumn);
                                                                        addRatioCell(indicateur, signature, signatureFull, row, dataCrossed, eDirection["%Vertical"], cellCrossed, cellCrossedRowParent);
                                                                        break;
                                                                    case eDirection["%VerticalTotal"]:
                                                                        cellTotalColumn = aggregateData(row, data !== null && data !== void 0 ? data : [], indicateur, eTotalDirection.totalColumn);
                                                                        addRatioCell(indicateur, signature, signatureFull, row, dataCrossed, eDirection["%VerticalTotal"], cellCrossed, cellTotalColumn);
                                                                        break;
                                                                    case eDirection["EvolutionU"]:
                                                                        cellEvolutionU = getEvolutionCellValue(row, indicateur, signature, signatureParentEvol);
                                                                        cellEvolutionValue = (_m = safeNumber(cellEvolutionU === null || cellEvolutionU === void 0 ? void 0 : cellEvolutionU.Value)) !== null && _m !== void 0 ? _m : 0;
                                                                        diff = safeNumber(cellCrossed.Value) - cellEvolutionValue;
                                                                        if (isNaN(diff))
                                                                            diff = 0;
                                                                        currencies = distinct(__spreadArray(__spreadArray([], safeArray((_o = cellCrossed.Options) === null || _o === void 0 ? void 0 : _o.Currencies), true), safeArray((_p = cellEvolutionU === null || cellEvolutionU === void 0 ? void 0 : cellEvolutionU.Options) === null || _p === void 0 ? void 0 : _p.Currencies), true));
                                                                        currencyCode = (currencies === null || currencies === void 0 ? void 0 : currencies.length) == 1 ? ((_r = (_q = cellCrossed.Options) === null || _q === void 0 ? void 0 : _q.CurrencyCode) !== null && _r !== void 0 ? _r : (_s = cellEvolutionU === null || cellEvolutionU === void 0 ? void 0 : cellEvolutionU.Options) === null || _s === void 0 ? void 0 : _s.CurrencyCode) : null;
                                                                        evolCell = addValue(diff, indicateur, signature, row, dataCrossed);
                                                                        evolCell.IndicateurSignatureFull = "{".concat(eDirection["EvolutionU"], "}").concat(signatureFull);
                                                                        evolCell.Options = { Currencies: currencies, CurrencyCode: currencyCode };
                                                                        break;
                                                                    case eDirection["%Evolution"]:
                                                                        cellEvolution = (_t = getEvolutionCellValue(row, indicateur, signature, signatureParentEvol)) !== null && _t !== void 0 ? _t : __assign(__assign({}, new CellValue()), { Value: 0 });
                                                                        signaturePercentRow = "{".concat(eDirection["%Evolution"], "}").concat(signatureFull);
                                                                        resRatio = 0;
                                                                        if (!safeNumber(cellEvolution.Value))
                                                                            resRatio = '-';
                                                                        else
                                                                            resRatio = (safeNumber(cellCrossed.Value) / safeNumber(cellEvolution.Value)) - 1;
                                                                        addValue(resRatio, indicateur, signature, row, dataCrossed).IndicateurSignatureFull = signaturePercentRow;
                                                                        break;
                                                                    default:
                                                                }
                                                                // then remove the cell crossed
                                                                row.ValuesTotal = row.ValuesTotal.filter(function (v) { return v.IndicateurSignatureFull != signatureFull; });
                                                                row.ValuesTotal = row.ValuesTotal.filter(function (v) { return !Object.values(eTotalDirection).some(function (d) { var _a; return (_a = v.IndicateurSignatureFull) === null || _a === void 0 ? void 0 : _a.startsWith(d); }); });
                                                            }
                                                            ;
                                                        }
                                                        return [2 /*return*/];
                                                }
                                            });
                                        };
                                        this_2 = this;
                                        _a = 0, tableIndicateurs_1 = tableIndicateurs;
                                        _u.label = 2;
                                    case 2:
                                        if (!(_a < tableIndicateurs_1.length)) return [3 /*break*/, 5];
                                        _b = tableIndicateurs_1[_a], indicateur = _b.indicateur, signature = _b.signature, data = _b.data, signatureFull = _b.signatureFull, parent = _b.parent, signatureParentEvol = _b.signatureParentEvol;
                                        return [5 /*yield**/, _loop_9(indicateur, signature, data, signatureFull, parent, signatureParentEvol)];
                                    case 3:
                                        _u.sent();
                                        _u.label = 4;
                                    case 4:
                                        _a++;
                                        return [3 /*break*/, 2];
                                    case 5: return [4 /*yield*/, recurse(row.Children, row)];
                                    case 6:
                                        _u.sent();
                                        _u.label = 7;
                                    case 7:
                                        _i++;
                                        return [3 /*break*/, 1];
                                    case 8: return [2 /*return*/];
                                }
                            });
                        }); };
                        return [4 /*yield*/, recurse(table.Rows)];
                    case 3:
                        _e.sent();
                        return [4 /*yield*/, recurse((_c = (_b = table.TimeVentilations) === null || _b === void 0 ? void 0 : _b.Ranges) === null || _c === void 0 ? void 0 : _c.map(function (r) { return r.Row; }))];
                    case 4:
                        _e.sent();
                        if (allPromises.length)
                            console.log("[PERF] [aggregate] Starting .... Promises: (".concat(allPromises.length, ") for: ").concat(Array.from(columnPromises).join(', '), "..."));
                        time6442 = new Date().getTime();
                        return [4 /*yield*/, Promise.all(allPromises)];
                    case 5:
                        _e.sent();
                        _time6442 = new Date().getTime();
                        console.log("[PERF] [aggregate] Promises: (".concat(allPromises.length, ") for: ").concat(Array.from(columnPromises).join(', '), "  ").concat(_time6442 - time6442, "ms"));
                        console.log("[Aggregate] Elapsed ", _time6442 - time7268, "ms");
                        return [4 /*yield*/, ReleaseEventLoop()];
                    case 6:
                        _e.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AEngine;
}());
export { AEngine };
var EngineManager = /** @class */ (function () {
    function EngineManager() {
        this.dico = {};
    }
    EngineManager.GetInstance = function () {
        if (!EngineManager.mgr)
            EngineManager.mgr = new EngineManager();
        return EngineManager.mgr;
    };
    EngineManager.prototype.Register = function (engine) {
        this.dico[engine.Key] = engine;
    };
    EngineManager.prototype.Get = function (type) {
        var engine = this.dico[type];
        if (!engine) {
            engine = this.dico["default"];
        }
        return engine;
    };
    return EngineManager;
}());
export { EngineManager };
