var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from 'hub-lib/client/client.bin';
import { ref_ExportConfigurations } from 'hub-lib/dto/client/ref_ExportConfigurations.bin';
import { AdvertiserHierarchyFilter, ref_FilterConfigurations } from 'hub-lib/dto/client/ref_FilterConfigurations.bin';
import { ref_ProjectConfigurations } from 'hub-lib/dto/client/ref_ProjectConfigurations.bin';
import { ref_TableConfigurations } from 'hub-lib/dto/client/ref_TableConfigurations.bin';
import { ref_DashboardConfigurations } from 'hub-lib/dto/client/ref_DashboardConfigurations.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { eBusinessCode } from 'hub-lib/models/types.bin';
import { clearEmptyValues, ClearEmptyValues, clone, duplicate, extractSub, firstOrDefault, toArray, toDictionary } from 'hub-lib/tools.bin';
import { Trad } from 'trad-lib';
import { eventEmitter } from '../components/GenericModal';
import { DashboardStorage, ExportStorage, FilterStorage, GetUser, MetadataStorage, ProjectStorage } from '../utils/localstorage.bin';
import { Favorites } from '../utils/Favorites';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
var initialState = { allCurrencies: {}, project: null, filtersModele: null,
    dashboardModele: null, dashboardBudget: 0,
    isInitialized: false, filters: null };
export var selectProject = createAsyncThunk('project/selectProject', function (projectRid, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var defaultParams, project_1, _a, table, _b, filter, _c, exportConf, _d, fullKeyTable, fullKeyFilter, fullKeyExport, params, project, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                if (!(!projectRid || projectRid === "default")) return [3 /*break*/, 5];
                defaultParams = { Active: true, Table: ref_Messages.name, Default: true };
                _a = firstOrDefault;
                return [4 /*yield*/, Client.searchVertexTyped(ref_ProjectConfigurations, defaultParams)];
            case 1:
                project_1 = _a.apply(void 0, [_f.sent()]);
                _b = firstOrDefault;
                return [4 /*yield*/, Client.searchVertexTyped(ref_TableConfigurations, defaultParams)];
            case 2:
                table = _b.apply(void 0, [_f.sent()]);
                _c = firstOrDefault;
                return [4 /*yield*/, Client.searchVertexTyped(ref_FilterConfigurations, defaultParams)];
            case 3:
                filter = _c.apply(void 0, [_f.sent()]);
                _d = firstOrDefault;
                return [4 /*yield*/, Client.searchVertexTyped(ref_ExportConfigurations, defaultParams)];
            case 4:
                exportConf = _d.apply(void 0, [_f.sent()]);
                return [2 /*return*/, { project: project_1, table: table, filter: filter, export: exportConf }];
            case 5:
                fullKeyTable = "FullConfFilter";
                fullKeyFilter = "FullConfTable";
                fullKeyExport = "FullConfExport";
                params = { "@rid": projectRid, Active: true, properties: ["*", "ConfFilter.* as ".concat(fullKeyFilter), "ConfTable.* as ".concat(fullKeyTable), "ConfExport.* as ".concat(fullKeyExport)] };
                _e = firstOrDefault;
                return [4 /*yield*/, Client.searchVertexTyped(ref_ProjectConfigurations, params)];
            case 6:
                project = _e.apply(void 0, [_f.sent()]);
                return [2 /*return*/, { project: project, table: project[fullKeyTable], filter: project[fullKeyFilter], export: project[fullKeyExport] }];
        }
    });
}); });
export var initialize = createAsyncThunk('project/initialize', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _GetUser$customer, allCurrencies, _a, defaultFilters, filters;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = toDictionary;
                return [4 /*yield*/, Client.searchVertexTyped(ref_Currencies)];
            case 1:
                allCurrencies = _a.apply(void 0, [_b.sent(), function (c) { return c['@rid']; }]);
                return [4 /*yield*/, MetadataStorage.initialize()];
            case 2:
                _b.sent();
                return [4 /*yield*/, Favorites.GetInstance().Initialize()];
            case 3:
                _b.sent();
                defaultFilters = __assign(__assign({}, new ref_FilterConfigurations()), FilterStorage.get());
                defaultFilters.Filters = ClearEmptyValues(defaultFilters.Filters);
                filters = ClearEmptyValues(__assign(__assign(__assign({}, FilterStorage.getAdvancedFilters()), new AdvertiserHierarchyFilter()), defaultFilters.Filters));
                if (!((_GetUser$customer = GetUser().customer) !== null && _GetUser$customer !== void 0 && _GetUser$customer.DisplayMAPData)) {
                    filters.Source = ["ADWONE"];
                }
                return [2 /*return*/, {
                        allCurrencies: allCurrencies,
                        filtersModele: defaultFilters, dashboardModele: DashboardStorage.get(), exportModele: ExportStorage.get(),
                        filters: filters,
                        project: duplicate(ProjectStorage.get())
                    }];
        }
    });
}); });
function _saveFilters(filters) {
    return __awaiter(this, void 0, void 0, function () {
        var cloneFilters, error_1, _error$response, handleConfirm, content;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!filters['@rid']) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Client.updateVertex(ref_FilterConfigurations.name, filters, false)];
                case 2:
                    _a.sent();
                    cloneFilters = clone(filters);
                    cloneFilters.Filters = AdvertiserHierarchyFilter.getFilters(cloneFilters.Filters);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    if ((error_1 === null || error_1 === void 0 ? void 0 : (_error$response = error_1.response) === null || _error$response === void 0 ? void 0 : _error$response.data.error.data.type) == eBusinessCode.AlreadyExist) {
                        handleConfirm = function (filters) { return __awaiter(_this, void 0, void 0, function () { var cloneFilters, configExist, configToDelete; return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    cloneFilters = clone(filters);
                                    cloneFilters.Filters = AdvertiserHierarchyFilter.getFilters(cloneFilters.Filters);
                                    return [4 /*yield*/, Client.searchVertexTyped(ref_FilterConfigurations, { 'Filters': filters.Filters, 'Active': true })];
                                case 1:
                                    configExist = _a.sent();
                                    configToDelete = configExist.filter(function (config) { return config["@rid"] !== filters["@rid"]; }).map(function (config) { return config["@rid"]; });
                                    return [4 /*yield*/, Client.deleteVertex(ref_FilterConfigurations.name, configToDelete, false)];
                                case 2:
                                    _a.sent();
                                    return [4 /*yield*/, Client.updateVertex(ref_FilterConfigurations.name, filters, false)];
                                case 3:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        }); }); };
                        content = Trad("hierarchy_exists") + filters.Name + ', ' + Trad("overwrite_element");
                        modalEvent(content, handleConfirm);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function _setFilters(state, config) { var _state$filtersModele; FilterStorage.set(config); state.filtersModele = duplicate(config); if (state.dashboardModele.filters)
    state.dashboardModele.filters = {}; if ((_state$filtersModele = state.filtersModele) !== null && _state$filtersModele !== void 0 && _state$filtersModele.Filters)
    state.filtersModele.Filters = ClearEmptyValues(state.filtersModele.Filters); state.filters = ClearEmptyValues(__assign(__assign(__assign({}, FilterStorage.getAdvancedFilters()), new AdvertiserHierarchyFilter()), state.filtersModele.Filters)); } // function _setTable(state: ProjectState, config: ref_TableConfigurations) {
//     TableStorage.set(config);
//     state.columnsModele = duplicate(config as any);
// }
// function _setExport(state: ProjectState, config: ref_ExportConfigurations) {
//     ExportStorage.set(config);
//     state.exportModele = duplicate(config);
// }
function _setDashboard(state, config) { DashboardStorage.set(config); state.dashboardModele = duplicate(config); }
function _saveExport(config) {
    return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!config['@rid']) return [3 /*break*/, 2];
                return [4 /*yield*/, Client.updateVertex(ref_ExportConfigurations.name, config, false).catch(function (e) { return console.error(e); })];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    }); });
}
function _saveDashboard(config) {
    return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!config['@rid']) return [3 /*break*/, 2];
                return [4 /*yield*/, Client.updateVertex(ref_DashboardConfigurations.name, config, false).catch(function (e) { return console.error(e); })];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    }); });
}
function _saveTable(table) {
    return __awaiter(this, void 0, void 0, function () {
        var error_2, _error$response2, configExist_1, configToDeleteName, handleConfirm, content;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('columns');
                    if (!table['@rid']) return [3 /*break*/, 6];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 6]);
                    return [4 /*yield*/, Client.updateVertex(ref_TableConfigurations.name, table, false)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 3:
                    error_2 = _a.sent();
                    if (!((error_2 === null || error_2 === void 0 ? void 0 : (_error$response2 = error_2.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.data.error.data.type) == eBusinessCode.AlreadyExist)) return [3 /*break*/, 5];
                    return [4 /*yield*/, Client.searchVertexTyped(ref_TableConfigurations, { 'Columns': table.Columns, 'Active': true })];
                case 4:
                    configExist_1 = _a.sent();
                    configToDeleteName = configExist_1.filter(function (config) { return config["@rid"] !== table["@rid"]; }).map(function (config) { return config.Name; });
                    handleConfirm = function () { return __awaiter(_this, void 0, void 0, function () { var configToDelete; return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                configToDelete = configExist_1.filter(function (config) { return config["@rid"] !== table["@rid"]; }).map(function (config) { return config["@rid"]; });
                                return [4 /*yield*/, Client.deleteVertex(ref_TableConfigurations.name, configToDelete, false)];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, Client.updateVertex(ref_TableConfigurations.name, table, false)];
                            case 2:
                                _a.sent();
                                eventEmitter.emit("close");
                                return [2 /*return*/];
                        }
                    }); }); };
                    content = Trad("the_table") + configToDeleteName + Trad("content_replacement_validation");
                    modalEvent(content, handleConfirm);
                    _a.label = 5;
                case 5: return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    });
}
var modalEvent = function (content, handleConfirm) { eventEmitter.emit("push", { title: Trad("confirmation"), content: content, buttons: [{ label: Trad("cancel"), handleButton: function () { eventEmitter.emit("close"); }, className: "custom_btn_primary_cancel" }, { label: Trad("yes"), handleButton: handleConfirm, className: "custom_btn_danger" }] }); };
export var projectSlice = createSlice({ name: 'project', initialState: initialState, extraReducers: function (builder) {
        builder.addCase(selectProject.fulfilled, function (state, action) {
            ProjectStorage.set(action.payload.project);
            state.project = duplicate(action.payload.project); //if (action.payload.table) _setTable(state, action.payload.table);
            if (action.payload.filter) {
                _setFilters(state, action.payload.filter);
            }
            if (action.payload.export)
                ExportStorage.set(action.payload.export);
        });
        builder.addCase(initialize.fulfilled, function (state, action) {
            if (action.payload.filters)
                state.filters = action.payload.filters; //if (action.payload.columnsModele) state.columnsModele = action.payload.columnsModele;
            if (action.payload.filtersModele)
                state.filtersModele = action.payload.filtersModele;
            if (action.payload.dashboardModele)
                state.dashboardModele = action.payload.dashboardModele;
            if (action.payload.project)
                state.project = action.payload.project;
            if (action.payload.allCurrencies)
                state.allCurrencies = action.payload.allCurrencies; //if (action.payload.exportModele) state.exportModele = action.payload.exportModele;
            state.isInitialized = true;
        });
    }, reducers: { setFilters: function (state, action) { var _action$payload; _setFilters(state, action.payload); state.filters = ClearEmptyValues(__assign(__assign(__assign({}, state.filters), new AdvertiserHierarchyFilter()), ((_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.Filters))); }, saveFilters: function (state, action) { _saveFilters(action.payload); },
        //     _setTable(state, action.payload);
        // },
        saveTable: function (state, action) { _saveTable(action.payload); }, setChartConfig: function (state, action) {
            var _a, _b, _c, _d, _e;
            var modele = clone(state.dashboardModele);
            var isDate = action.payload.some(function (_ref) { var field = _ref.field; return field === "Start" || field === "End"; });
            var newFilters = __assign({}, modele.filters);
            if (isDate) {
                var _action$payload$find, _action$payload$find2;
                var exist = extractSub(modele.filters, ["Start", "End"]);
                var start = (_action$payload$find = action.payload.find(function (_ref2) { var field = _ref2.field; return field === "Start"; })) === null || _action$payload$find === void 0 ? void 0 : _action$payload$find.value;
                var end = (_action$payload$find2 = action.payload.find(function (_ref3) { var field = _ref3.field; return field === "End"; })) === null || _action$payload$find2 === void 0 ? void 0 : _action$payload$find2.value;
                if (exist) { // if selected date is the same as the existing one, remove it
                    if (new Date(start).getTime() === new Date(exist["Start"]).getTime() && new Date(end).getTime() === new Date(exist["End"]).getTime()) {
                        delete newFilters["Start"];
                        delete newFilters["End"];
                    }
                    else { // if selected date is included in the existing one, remove it
                        if (new Date(start).getTime() >= new Date(exist["Start"]).getTime() && new Date(end).getTime() <= new Date(exist["End"]).getTime()) {
                            newFilters = (_a = {}, _a["Start"] = start, _a["End"] = end, _a);
                        }
                        else { // if selected date is not included in the existing one, extend them
                            newFilters = (_b = {}, _b["Start"] = new Date(start).getTime() < new Date(exist["Start"]).getTime() ? start : newFilters["Start"], _b["End"] = new Date(end).getTime() > new Date(exist["End"]).getTime() ? end : newFilters["End"], _b);
                        }
                    }
                }
                else {
                    newFilters = (_c = {}, _c["Start"] = start, _c["End"] = end, _c);
                }
            }
            else {
                for (var _i = 0, _f = action.payload; _i < _f.length; _i++) {
                    var _g = _f[_i], field = _g.field, value = _g.value;
                    var exist = extractSub(modele.filters, [field]);
                    if (exist) { // remove from array
                        var array = toArray(newFilters[field]);
                        for (var _h = 0, _j = toArray(value); _h < _j.length; _h++) {
                            var toRemove = _j[_h];
                            var index = array.indexOf(toRemove);
                            if (index > -1) {
                                array.splice(index, 1);
                            }
                            else {
                                array.push(toRemove);
                            }
                        }
                        newFilters = (_d = {}, _d[field] = array, _d);
                    }
                    else {
                        newFilters = (_e = {}, _e[field] = toArray(value), _e);
                    }
                }
            }
            modele.filters = clearEmptyValues(newFilters);
            _setDashboard(state, modele);
            _saveDashboard(modele);
        }, clearChartConfig: function (state) { var modele = clone(state.dashboardModele); modele.filters = {}; _setDashboard(state, modele); _saveDashboard(modele); },
        //     _setExport(state, action.payload);
        // },
        saveExport: function (state, action) { _saveExport(action.payload); }, setDashboard: function (state, action) { _setDashboard(state, action.payload); }, saveDashboard: function (state, action) { _saveDashboard(action.payload); }, setDashboardBudget: function (state, action) { state.dashboardBudget = action.payload; } } }); // Action creators are generated for each case reducer function
export var setFilters = (_a = projectSlice.actions, _a.setFilters), selectFilters = _a.setFilters, setChartConfig = _a.setChartConfig, clearChartConfig = _a.clearChartConfig, saveFilters = _a.saveFilters, saveTable = _a.saveTable, /*setExport,*/ saveExport = _a.saveExport, setDashboard = _a.setDashboard, saveDashboard = _a.saveDashboard, setDashboardBudget = _a.setDashboardBudget;
export var projectReducer = projectSlice.reducer;
