var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c;
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { lnk_ChangeRate } from "hub-lib/dto/client/lnk_ChangeRate.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { db } from "validation-lib/db.bin";
import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { ref_Estimates } from "hub-lib/dto/client/ref_Estimates.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { GetCurrentLocale, Trad, TradProp, TradValue } from "trad-lib";
import moment from 'moment';
import { ref_Persons } from "hub-lib/models/orientdb/ref_Persons.bin";
import { UserExtended } from "hub-lib/dto/referential/UserExtended.bin";
import { Log } from "hub-lib/dto/admin/Log.bin";
import { ref_Customers } from "hub-lib/models/orientdb/ref_Customers.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { SupportExtended } from "hub-lib/dto/referential/SupportExtended.bin";
import { lnk_AdvertisingCompanySupport } from "hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin";
import { lnk_HasBroadcastArea } from "hub-lib/models/orientdb/lnk_HasBroadcastArea.bin";
import { FormatDate, getWeekNumber } from "tools-lib";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { ref_CustomersExtended } from "hub-lib/dto/referential/ref_CustomersExtended.bin";
import { ref_Imports } from "hub-lib/dto/admin/ref_Imports.bin";
import { User } from "hub-lib/models/orientdb/User.bin";
import { lnk_HasPropertyType } from "hub-lib/models/orientdb/lnk_HasPropertyType.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { lnk_HasKPI } from "hub-lib/models/orientdb/lnk_HasKPI.bin";
import { ref_Contacts } from "hub-lib/models/orientdb/ref_Contacts.bin";
import { ContactExtended } from "hub-lib/dto/referential/ContactExtended";
export function HTMLToString(htmlStr) {
    var _a, _b, _c, _d, _e;
    return (_e = (_d = (_c = (_b = (_a = htmlStr === null || htmlStr === void 0 ? void 0 : htmlStr.replace) === null || _a === void 0 ? void 0 : _a.call(htmlStr, /<\/?[^>]+>/gi, '')) === null || _b === void 0 ? void 0 : _b.replace(/&lt;/g, '<')) === null || _c === void 0 ? void 0 : _c.replace) === null || _d === void 0 ? void 0 : _d.call(_c, /&gt;/g, '>')) !== null && _e !== void 0 ? _e : "";
}
export function BooleanToProperty(val, propertyType) {
    if (val == TradValue(ref_Property.name, "Name", "Yes"))
        return TradProp(propertyType.Type);
    if (val == TradValue(ref_Property.name, "Name", "No"))
        return "";
    return val;
}
require('moment/locale/fr.js');
export function getDecimalSeparator() {
    var numberWithDecimal = 1.1;
    var localeString = numberWithDecimal.toLocaleString(GetCurrentLocale());
    var decimalSeparator = localeString.charAt(1); // Le séparateur est entre les deux chiffres
    return decimalSeparator;
}
var dicoFormat = (_a = {},
    _a[ref_Contacts.name] = function (ctct) { return ctct.Email; },
    _a[lnk_HasKPI.name] = function (lnk) { var _a; return Trad((_a = lnk.NameLnk) !== null && _a !== void 0 ? _a : lnk.Name); },
    _a[lnk_HasPropertyType.name] = function (lnk) { return TradProp("ModelProperties.".concat(lnk.Name), ref_Messages); },
    _a[lnk_HasCurrency.name] = function (c) { return "".concat(c.Name, " (").concat(c.Code, ")"); },
    _a[ref_Currencies.name] = function (c) { return "".concat(c.Name, " (").concat(c.Code, ")"); },
    _a[ref_Advertisers.name] = function (a) { return "".concat(a.Name); },
    _a[User.name] = function (u) { return u.person ? Format(u.person) : "".concat(u.name); },
    _a[UserExtended.name] = function (u) { return Format(u.person); },
    _a[ref_Persons.name] = function (a) { return "".concat(a.FirstName, " ").concat(a.LastName); },
    _a);
export var propsNeededForFormat = (_b = {},
    _b[ref_Contacts.name] = ["Email"],
    _b[lnk_HasKPI.name] = ["NameLnk", "Name"],
    _b[lnk_HasCurrency.name] = ["Name", "Code"],
    _b[ref_Currencies.name] = ["Name", "Code"],
    _b[ref_Advertisers.name] = ["Name"],
    _b[User.name] = ["person", "name"],
    _b[UserExtended.name] = ["person"],
    _b[ref_Persons.name] = ["FirstName", "LastName"],
    _b);
var dicoOrder = {};
SetOrder(ContactExtended, [
    "@rid",
    "LastName",
    "FirstName",
    "CompanyName",
    "Email",
    "Phone",
    "Type",
    "Job",
    "Start",
    "End"
]);
SetOrder(lnk_ChangeRate, [
    "Company",
    "Start",
    "End",
    "in",
    "out",
    "Rate",
    "Created_by",
    "Created_at",
    "Updated_by",
    "Updated_at",
]);
SetOrder(UserExtended, [
    "person",
    "company",
    "Department",
    "job",
    "mail",
    "registrationDate",
    "qualification",
    "profile",
    "name",
    "lastConnexion",
    "isOnline",
    "lastAccess",
    "Created_by",
    "Created_at",
    "Updated_by",
    "Updated_at",
]);
SetOrder(ref_Campaigns, [
    "Name",
    "Start",
    "End",
    "Source",
    "Group",
    "AdvertiserGroup",
    "Advertiser",
    "Brand",
    "Product",
    "Departments",
    "Budget",
    "KPIs.TotalCount",
    "KPIs.NetCO",
    "EstimateNumber",
    "Currency",
    "Created_by"
]);
SetOrder(ref_Customers, [
    "Company",
    "Country",
    "Licences",
    "Type",
    "Activity",
    "Start",
    "End",
]);
SetOrder(ref_Estimates, [
    "Campaign",
    "Status",
    "Code",
    "Lib",
    "Type",
    "Start",
    "End",
    "Created_at",
    "AdvertiserGroup",
    "Advertiser",
    "Brand",
    "Product",
    "Media",
    "MessagesCount",
    "Department",
    "Agency"
]);
SetOrder(ref_Media, [
    "Name",
    "Start",
    "End"
]);
SetOrder(ref_AdvertisingCompanies, [
    "Name",
    "Start",
    "End"
]);
SetOrder(ref_Currencies, [
    "Label",
    "Name",
    "Code",
    "Start",
    "End"
]);
SetOrder(ref_BroadcastAreas, [
    "Name",
    "Start",
    "End"
]);
SetOrder(ref_Supports, [
    "Name",
    "Medias",
    "Start",
    "End",
    "Periodicity"
]);
SetOrder(SupportExtended, [
    "Name",
    "Medias",
    "Start",
    "End"
]);
SetOrder(ref_Property, [
    "Name",
    "Medias",
    "Start",
    "End"
]);
SetOrder(lnk_AdvertisingCompanySupport, [
    "in",
    "DefaultBroadcastArea",
    "Start",
    "End",
    "Default"
]);
SetOrder(lnk_HasBroadcastArea, [
    "out",
    "Start",
    "End",
    "Default"
]);
SetOrder(lnk_HasCurrency, [
    "out",
    "Start",
    "End",
    "Default"
]);
SetOrder(ref_Imports, [
    "Report",
    "Date",
    "SourceType",
    "Customer",
]);
SetOrder(ref_Agreements, [
    "Name",
    "Group",
    "AdvertiserGroup",
    "Advertisers",
    "Brands",
    "Products",
    "Support",
    "BroadcastAreas",
    "Formats",
    "Placements",
    "Start",
    "End",
    "Discounts"
]);
export function Format(data, defaultValue, className) {
    if (!data)
        return "";
    var formater = dicoFormat[className !== null && className !== void 0 ? className : data === null || data === void 0 ? void 0 : data["@class"]];
    if (formater)
        return formater(data);
    var name = data === null || data === void 0 ? void 0 : data.Name;
    if (name) {
        var trad = TradValue(className !== null && className !== void 0 ? className : data === null || data === void 0 ? void 0 : data["@class"], "Name", name);
        return trad !== null && trad !== void 0 ? trad : name;
    }
    if (defaultValue)
        return defaultValue;
    return data === null || data === void 0 ? void 0 : data.toString();
}
var dicoCell = (_c = {},
    _c[ePropType.Date] = function (val) {
        var _a;
        var res = val;
        if (res) {
            try {
                //console.log(``)
                var d = new Date(val);
                if (isNaN(d.getTime()))
                    return val;
                // conversion to UTC
                if (typeof window === "undefined")
                    d = new Date(d.getTime() + d.getTimezoneOffset() * 60000);
                if (typeof res === "string" && ((_a = res.split("/")) === null || _a === void 0 ? void 0 : _a.length) === 3) {
                    var sp = res.split("/");
                    d = new Date(Number(sp[2]), Number(sp[1]) - 1, Number(sp[0]));
                }
                //res = val && moment(d).locale(GetCurrentLocale()).format('L')
                var nd = d.getDate() < 10 ? "0".concat(d.getDate()) : d.getDate();
                var nm = (d.getMonth() + 1) < 10 ? "0".concat(d.getMonth() + 1) : d.getMonth() + 1;
                var ny = d.getFullYear();
                switch (GetCurrentLocale()) {
                    case "fr-FR":
                        res = "".concat(nd, "/").concat(nm, "/").concat(ny);
                        break;
                    case "en-US":
                        res = "".concat(nm, "/").concat(nd, "/").concat(ny);
                        break;
                    case "en-GB":
                        res = "".concat(nd, "/").concat(nm, "/").concat(ny);
                        break;
                    default:
                        res = val && moment(d).locale(GetCurrentLocale()).format('L');
                        break;
                }
            }
            catch (error) {
                console.error("bad date format: ".concat(val));
            }
            return res;
        }
        else {
            return '';
        }
    },
    _c[ePropType.Datetime] = function (val) {
        if (!val)
            return null;
        val = new Date(val);
        if (isNaN(val.getTime()))
            return val;
        return FormatDate(val, true);
    },
    _c[ePropType.Double] = function (value) { return value === null || value === void 0 ? void 0 : value.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 }); },
    _c[ePropType.Integer] = function (value) { return value === null || value === void 0 ? void 0 : value.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 0, maximumFractionDigits: 0 }); },
    _c[ePropType.Decimal] = function (value) { return value === null || value === void 0 ? void 0 : value.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 8, maximumFractionDigits: 8 }); },
    _c[ePropType.Embedded] = function (val) { return Format(val); },
    _c);
export var GetCellClassName = function (type) {
    var _a;
    var _b;
    var dico = (_a = {},
        _a[ePropType.Double] = "number-cell-data",
        _a[ePropType.Integer] = "number-cell-data",
        _a[ePropType.Decimal] = "number-cell-data",
        _a[eKPIType.Price] = "number-cell-data",
        _a[eKPIType.PriceReturned] = "number-cell-data",
        _a[eKPIType.PriceBound] = "number-cell-data",
        _a);
    return (_b = dico[type]) !== null && _b !== void 0 ? _b : "";
};
export var GetCellFieldClassName = function (field, documentType) {
    var _a;
    var _b, _c;
    var dico = (_a = {},
        _a[ref_Messages.name] = {},
        _a);
    return (_c = (_b = dico[documentType]) === null || _b === void 0 ? void 0 : _b[field]) !== null && _c !== void 0 ? _c : "";
};
export function capitalizeWords(str) {
    return str
        .split(/\s+/) // Sépare la chaîne par les espaces
        .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); }) // Capitalise la première lettre
        .join(' '); // Recompose la chaîne
}
export var GetNumericFormat = function (type) {
    var _a;
    var _b;
    var dico = (_a = {},
        _a[ePropType.Double] = "n2",
        _a[ePropType.Integer] = "n0",
        _a[ePropType.Decimal] = "n8",
        _a[eKPIType.Price] = "n2",
        _a[eKPIType.PriceReturned] = "n2",
        _a[eKPIType.PriceBound] = "n2",
        _a[eKPIType.Percent] = "p2",
        _a[eKPIType.Number] = "n0",
        _a);
    return (_b = dico[type]) !== null && _b !== void 0 ? _b : "n2";
};
export var GetNumericStep = function (type) {
    var _a;
    var _b;
    var dico = (_a = {},
        _a[ePropType.Double] = 0.01,
        _a[ePropType.Integer] = 1,
        _a[ePropType.Decimal] = 0.001,
        _a[eKPIType.Price] = 0.01,
        _a[eKPIType.PriceReturned] = 0.01,
        _a[eKPIType.PriceBound] = 0.01,
        _a[eKPIType.Number] = 1,
        _a);
    return (_b = dico[type]) !== null && _b !== void 0 ? _b : 0.01;
};
var dicoPropName = {
    Status: function (val) { return Trad(val); },
    DiscountMode: function (val) { return Trad(val); },
    State: function (val) { return Trad(val); }
};
export function GetPropTemplate(propName) {
    return dicoPropName[propName];
}
var dicoPropertyTypeTag = {
    TextEditor: function (val, propertyType) { return HTMLToString(val); },
    Boolean: function (val, propertyType) { return BooleanToProperty(val, propertyType); },
    Prefix: function (val, propertyType) {
        var _a, _b, _c, _d;
        if (!val)
            return val;
        var valPrefix = ((_c = (_b = (_a = propertyType.Tags.find(function (x) { return x.startsWith("Prefix"); })) === null || _a === void 0 ? void 0 : _a.split(":")) === null || _b === void 0 ? void 0 : _b[1]) !== null && _c !== void 0 ? _c : "") + ' ';
        return (_d = (valPrefix + val)) === null || _d === void 0 ? void 0 : _d.trim();
    },
};
export function GetPropertyTypeTemplate(propertyType) {
    var _a, _b, _c, _d;
    var formatPropertyCell = (_d = (_c = (_b = (_a = propertyType === null || propertyType === void 0 ? void 0 : propertyType.Tags) === null || _a === void 0 ? void 0 : _a.map(function (x) { return dicoPropertyTypeTag[x.split(':')[0]]; })) === null || _b === void 0 ? void 0 : _b.filter(function (x) { return x; })) === null || _c === void 0 ? void 0 : _c[0]) !== null && _d !== void 0 ? _d : null;
    return formatPropertyCell ? function (val) { return formatPropertyCell(val, propertyType); } : null;
}
export var GetCellTemplate = function (type, propName) {
    var _a;
    if (propName) {
        var temp = dicoPropName[propName];
        if (temp)
            return temp;
    }
    return (_a = dicoCell[type]) !== null && _a !== void 0 ? _a : (function (val) {
        var _a, _b, _c;
        if (val && typeof val === "object") {
            if (val["@class"])
                return Format(val);
            return JSON.stringify(val);
        }
        return (_c = (_b = (_a = (val !== null && val !== void 0 ? val : "")).toString) === null || _b === void 0 ? void 0 : _b.call(_a)) !== null && _c !== void 0 ? _c : val;
    });
};
export var GetKPITemplate = function (type) {
    var _a;
    var _b;
    var dico = (_a = {},
        _a[eKPIType.Number] = function (value) { return GetCellTemplate(ePropType.Integer)(value); },
        _a[eKPIType.Decimal] = function (value) { return GetCellTemplate(ePropType.Double)(value); },
        _a[eKPIType.Rate] = function (value) { return GetCellTemplate(ePropType.Double)(value); },
        _a[eKPIType.Price] = function (value) { return GetCellTemplate(ePropType.Double)(value); },
        _a[eKPIType.PriceReturned] = function (value) { return GetCellTemplate(ePropType.Double)(value); },
        _a[eKPIType.PriceBound] = function (value) { return GetCellTemplate(ePropType.Double)(value); },
        _a[eKPIType.Percent] = function (value) {
            if (value === undefined || value === "")
                return "";
            return (value * 100).toLocaleString(GetCurrentLocale(), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) + " %";
        },
        _a[eKPIType.FullDecimal] = function (value) { return (value === undefined || value === "") ? "" : value === null || value === void 0 ? void 0 : value.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 6, maximumFractionDigits: 6 }); },
        _a[eKPIType.Date] = function (value) { return GetCellTemplate(ePropType.Date)(value); },
        _a[eKPIType.DateTime] = function (value) { return GetCellTemplate(ePropType.Datetime)(value); },
        _a[eKPIType.Rid] = function (value) { return GetCellTemplate(ePropType.String)(value); },
        _a[eKPIType.String] = function (value) { return GetCellTemplate(ePropType.String)(value); },
        _a);
    return (_b = dico[type]) !== null && _b !== void 0 ? _b : (function (val) { return val; });
};
export function GetOrder(prototype) {
    var _a;
    return ((_a = dicoOrder[prototype.name]) !== null && _a !== void 0 ? _a : []);
}
export function SetOrder(prototype, props) {
    dicoOrder[prototype.name] = props;
}
export var NameSort = function (fakePosition) {
    if (fakePosition === void 0) { fakePosition = "first"; }
    return function (a, b) {
        var _a;
        if (a["@rid"] == "#-1:-1")
            return (fakePosition == "first") ? -1 : 1;
        if (b["@rid"] == "#-1:-1")
            return (fakePosition == "first") ? 1 : -1;
        return (_a = a.Name) === null || _a === void 0 ? void 0 : _a.localeCompare(b.Name);
    };
};
var dicoSort = {};
SetSort(ref_Campaigns, [{
        field: "Start",
        dir: "desc",
    }]);
SetSort(ref_Messages, [{
        field: "Start",
        dir: "desc"
    }]);
SetSort(Log, [{
        field: "Date",
        dir: "desc",
        cmp: function (a, b) { return new Date(b.Date).getTime() - new Date(a.Date).getTime(); }
    }]);
SetSort(ref_Imports, [{
        field: "Date",
        dir: "desc",
        cmp: function (a, b) { return new Date(b.Date).getTime() - new Date(a.Date).getTime(); }
    }]);
var NameSortDescriptor = {
    field: "Name",
    dir: "asc",
    cmp: NameSort()
};
SetSort(ref_TableConfigurations, [NameSortDescriptor, {
        field: "Default",
        dir: "desc",
        cmp: function (a, b) {
            if (a.Default && !b.Default)
                return -1;
            if (!a.Default && b.Default)
                return 1;
            return 0;
        }
    }]);
SetSort(lnk_ChangeRate, [{
        field: "Company",
        dir: "asc"
    },
    {
        field: "Start",
        dir: "desc"
    },
    {
        field: "in",
        dir: "asc"
    }]);
SetSort(ref_CustomersExtended, [{
        field: "Company",
        dir: "asc"
    }]);
SetSort(UserExtended, [{
        field: "person",
        dir: "asc",
        cmp: function (a, b) {
            if (a.person && !b.person)
                return 1;
            if (!a.person && b.person)
                return -1;
            if (!a.person && !b.person)
                return 0;
            return a.person.LastName.localeCompare(b.person.LastName);
        }
    }]);
SetSort(ref_AdvertiserGroups, [NameSortDescriptor]);
SetSort(lnk_HasBroadcastArea, [NameSortDescriptor]);
SetSort(ref_Advertisers, [NameSortDescriptor]);
SetSort(ref_Brands, [NameSortDescriptor]);
SetSort(ref_Products, [NameSortDescriptor]);
SetSort(ref_Supports, [NameSortDescriptor]);
SetSort(SupportExtended, [NameSortDescriptor]);
SetSort(ref_Contacts, [{
        field: "Email",
        dir: "asc",
        cmp: function (a, b) { var _a; return (_a = a.Email) === null || _a === void 0 ? void 0 : _a.localeCompare(b.Email); }
    }]);
export function SetSort(prototype, sorts) {
    dicoSort[prototype.name] = sorts;
}
export function GetSort(prototype) {
    return dicoSort[prototype.name];
}
export function Sort(name, data) {
    var _a, _b, _c, _d;
    if (!data || (data === null || data === void 0 ? void 0 : data.length) == 1)
        return data;
    var sorter = dicoSort[name];
    sorter === null || sorter === void 0 ? void 0 : sorter.forEach(function (s) {
        if (s.cmp)
            data = __spreadArray([], data, true).sort(s.cmp);
        else {
            data = __spreadArray([], data, true).sort(function (a, b) {
                var _a, _b, _c, _d, _e;
                return (_c = (_b = (_a = a[s.field]) === null || _a === void 0 ? void 0 : _a.toString) === null || _b === void 0 ? void 0 : _b.call(_a)) === null || _c === void 0 ? void 0 : _c.localeCompare((_e = (_d = b[s.field]) === null || _d === void 0 ? void 0 : _d.toString) === null || _e === void 0 ? void 0 : _e.call(_d));
            });
            if (s.dir == "desc")
                data.reverse();
        }
    });
    if (!sorter) {
        var nameExists = ((_b = (_a = db[name]) === null || _a === void 0 ? void 0 : _a.mandatory) === null || _b === void 0 ? void 0 : _b.some(function (p) { return p == "Name"; })) || ((_d = (_c = db[name]) === null || _c === void 0 ? void 0 : _c.optional) === null || _d === void 0 ? void 0 : _d.some(function (p) { return p == "Name"; }));
        if (nameExists) {
            data = __spreadArray([], data, true).sort(NameSortDescriptor.cmp);
        }
    }
    return data;
}
// function that get first day of the week
export function getFirstDayOfWeek(date) {
    var day = date.getDay();
    var diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(date.setDate(diff));
}
export var FormatMonth = function (d) { return Trad("month_".concat(d.getMonth())); };
export var FormatDay = function (d) { return Trad("day_".concat(d.getDay()))[0] + (d.getDate() < 10 ? "0".concat(d.getDate()) : d.getDate()); };
export var FormatWeek = function (d) { return "".concat(Trad('week_short'), " ").concat(getWeekNumber(d)); };
export var FormatWeekShort = function (d) { return "".concat(Trad('week_very_short')).concat(getWeekNumber(d)); };
export var FormatDDMMDate = function (date, locale) {
    if (locale === void 0) { locale = "fr-FR"; }
    var options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    };
    // get 5 first characters of the date
    return date.toLocaleDateString(locale, options).substring(0, 5);
};
// format week with dd/MM
export var FormatWeekDatedLong = function (d) { return "".concat(Trad('week_short_dated'), " ").concat(FormatDDMMDate(getFirstDayOfWeek(d))); };
export var FormatNumber = function (n) {
    return n === null || n === void 0 ? void 0 : n.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 0, maximumFractionDigits: 2 });
};
