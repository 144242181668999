var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b;
import { ref_Media } from "./orientdb/ref_Media.bin";
import { lnk_HasKPI } from "./orientdb/lnk_HasKPI.bin";
import { ref_KPIs } from "./orientdb/ref_KPIs.bin";
import { ePropType } from "./VertexProperty.bin";
import { eCompare } from "../operators.bin";
import { lnk_HasPropertyType } from "./orientdb/lnk_HasPropertyType.bin";
import { ref_Property } from "./orientdb/ref_Property.bin";
import { DataProvider } from "../provider";
import { TradValue } from "trad-lib";
import { ref_Campaigns } from "../dto/client/ref_Campaigns.bin";
import { ref_Messages } from "../dto/client/ref_Messages.bin";
import { eFunctions, eRights, RightManager } from "./types/rights.bin";
import { ref_Currencies } from "./orientdb/ref_Currencies.bin";
import BigNumber from "bignumber.js";
export var eKPI;
(function (eKPI) {
    eKPI["Total"] = "Nombre total";
    eKPI["Paid"] = "Nombre payant";
    eKPI["Free"] = "Nombre gracieux";
    eKPI["GrossFormat"] = "Brut format d\u00E9faut";
    eKPI["GrossFormatRate"] = "Taux format d\u00E9faut";
    eKPI["GrossFormatValRate"] = "Taux valoris\u00E9 format d\u00E9faut";
    //Device = "Device",
    //TypeBuy = "Type d'achat",
    //TypePack = "Type de package",
    eKPI["SOV"] = "SOV";
    //TypeActivity = "Type d'activité",
    eKPI["ViewRate"] = "Viewability rate";
    eKPI["Position"] = "Position";
    //TypeNav = "Type de navigation",
    eKPI["Net"] = "Net";
    eKPI["PaidCirculation"] = "Diffusion pay\u00E9e";
    eKPI["TotalCirculation"] = "Diffusion totale";
    eKPI["Subscriptions"] = "Abonnements";
    eKPI["Release"] = "Tirage";
    eKPI["ToDeliver"] = "Nb ex \u00E0 livrer (passe incluse)";
    eKPI["Pagination"] = "Pagination";
    eKPI["Budget"] = "Budget";
    eKPI["GRP"] = "GRP";
    eKPI["Contacts"] = "contacts";
    eKPI["CPM"] = "CPM";
    eKPI["DeliveredCPM"] = "DeliveredCPM";
    eKPI["DeliveredImpression"] = "DeliveredImpression";
    eKPI["TargetingImpression"] = "TargetingImpression";
    eKPI["VisibilityImpression"] = "VisibilityImpression";
    eKPI["TotalClic"] = "TotalClic";
    eKPI["DeliveredClic"] = "DeliveredClic";
    eKPI["CPC"] = "CPC";
    eKPI["DeliveredCPC"] = "DeliveredCPC";
    eKPI["ClicRate"] = "ClicRate";
    eKPI["CPV"] = "CPV";
    eKPI["CompletionRate"] = "CompletionRate";
    eKPI["DeliveringRate"] = "DeliveringRate";
    eKPI["Video25PercentViews"] = "Video25PercentViews";
    eKPI["Video50PercentViews"] = "Video50PercentViews";
    eKPI["Video75PercentViews"] = "Video75PercentViews";
    eKPI["Video100PercentViews"] = "Video100PercentViews";
    eKPI["QualityScore"] = "QualityScore";
    eKPI["ConvertionRate"] = "ConvertionRate";
    eKPI["ROAS"] = "ROAS";
    eKPI["CPVideo"] = "CPVideo";
    eKPI["CTR"] = "CTR";
    eKPI["CAC"] = "CAC";
    eKPI["Conversions"] = "Conversions";
    eKPI["CPA"] = "CPA";
    eKPI["Acquisitions"] = "Acquisitions";
    eKPI["CPF"] = "CPF";
    eKPI["Fans"] = "Fans";
    eKPI["CPE"] = "CPE";
    eKPI["Engagements"] = "Engagements";
    eKPI["CPI"] = "CPI";
    eKPI["Installations"] = "Installations";
    eKPI["CPLSample"] = "CPL (Samples Leads)";
    eKPI["SampleLeads"] = "Samples Leads";
    eKPI["CPLSuscription"] = "CPL (Suscriptions Leads)";
    eKPI["SuscriptionLeads"] = "Suscriptions Leads";
    eKPI["CPLSale"] = "CPL (Sales Leads)";
    eKPI["SaleLeads"] = "Sales Leads";
    eKPI["CPVisite"] = "CPVisite";
    eKPI["Visites"] = "Visites";
    eKPI["Views"] = "Views";
    eKPI["vCPM"] = "vCPM";
    eKPI["Enchere"] = "Ench\u00E8re";
    eKPI["Forfait"] = "Forfait";
    eKPI["PaidPrint"] = "Impressions payantes";
    eKPI["FreePrint"] = "Impressions gracieuses";
    eKPI["TotalPrint"] = "Impressions totale";
    eKPI["CGRP"] = "CGRP";
    eKPI["Coverage"] = "coverage";
    eKPI["CoverageThousands"] = "coverage_thousands";
    eKPI["Repetition"] = "repetition";
})(eKPI || (eKPI = {}));
export var eKPIType;
(function (eKPIType) {
    eKPIType["Number"] = "Number";
    eKPIType["Decimal"] = "Decimal";
    eKPIType["Percent"] = "Percent";
    eKPIType["Rid"] = "@rid";
    eKPIType["String"] = "String";
    eKPIType["Price"] = "Price";
    eKPIType["PriceReturned"] = "PriceReturned";
    eKPIType["PriceBound"] = "PriceBound";
    eKPIType["Date"] = "Date";
    eKPIType["DateTime"] = "DateTime";
    eKPIType["FullDecimal"] = "FullDecimal";
    eKPIType["Rate"] = "Rate";
})(eKPIType || (eKPIType = {}));
export var PerformanceKPIs = [eKPI.PaidCirculation, eKPI.TotalCirculation, eKPI.Release, eKPI.Subscriptions, eKPI.ToDeliver];
export var PerformanceDIGKPIs = [eKPI.TotalPrint,
    eKPI.TotalClic,
    eKPI.Conversions,
    eKPI.SuscriptionLeads,
    eKPI.Visites,
    eKPI.Acquisitions,
    eKPI.Engagements,
    eKPI.SaleLeads,
    eKPI.Installations,
    eKPI.Views,
    eKPI.VisibilityImpression,
    eKPI.Fans,
    eKPI.SampleLeads,
    eKPI.Video100PercentViews];
export var PerformanceTV_RADIOKPIs = [eKPI.Contacts];
export var eTags;
(function (eTags) {
    eTags["FullWidth"] = "FullWidth";
    eTags["TextEditor"] = "TextEditor";
    eTags["Website"] = "Website";
    eTags["Upload"] = "Upload";
})(eTags || (eTags = {}));
export var eHandleTypes = (_a = {},
    _a[ePropType.Decimal] = eKPIType.Number,
    _a[ePropType.Integer] = eKPIType.Number,
    _a[ePropType.Short] = eKPIType.Number,
    _a[ePropType.Long] = eKPIType.Number,
    _a[ePropType.Float] = eKPIType.Number,
    _a[ePropType.Double] = eKPIType.Number,
    _a[ePropType.Binary] = eKPIType.Number,
    _a[ePropType.Byte] = eKPIType.Number,
    _a[ePropType.Datetime] = eKPIType.Date,
    _a[ePropType.Date] = eKPIType.Date,
    _a[ePropType.String] = eKPIType.String,
    _a[ePropType.Link] = eKPIType.Rid,
    _a[ePropType.LinkSet] = eKPIType.Rid,
    _a);
export var eHandleTypesReverse = (_b = {},
    _b[eKPIType.Decimal] = ePropType.Double,
    _b[eKPIType.Number] = ePropType.Integer,
    _b[eKPIType.Date] = ePropType.Date,
    _b[eKPIType.String] = ePropType.String,
    _b[eKPIType.Rid] = ePropType.Link,
    _b[eKPIType.Price] = ePropType.Double,
    _b);
export function ToEKPIType(e) {
    return eHandleTypes[e] || eKPIType.String;
}
export function ToEPropType(e) {
    var _a;
    // ce serait plus logique de mettre string par défaut mais ça a des répercussions dans les colonnes de KPIs
    return (_a = eHandleTypesReverse[e]) !== null && _a !== void 0 ? _a : ePropType.Integer;
}
export function IsNumber(type) {
    return [eKPIType.Decimal, eKPIType.Rate, eKPIType.FullDecimal, eKPIType.Number, eKPIType.Percent, eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(type);
}
export function IsDate(type) {
    return [eKPIType.Date, eKPIType.DateTime].includes(type);
}
export function IsPrice(type) {
    return [eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(type);
}
export function KpiToFormat(kpi) {
    switch (kpi) {
        case "coverage":
            return "P2";
        case "Fans":
        case "Conversions":
        case "Acquisitions":
        case "Engagements":
        case "Installations":
        case "Samples Leads":
        case "Suscriptions Leads":
        case "Sales Leads":
        case "Visites":
        case "Views":
        case "TotalClic":
        case "VisibilityImpression":
        case "Impressions totale":
        case "Video100PercentViews":
        case "contacts":
        case "coverage_thousands":
            return "n0";
        case "repetition":
            return "n1";
        default:
            return "n2";
    }
}
var lnk_HasKPIExtended = /** @class */ (function (_super) {
    __extends(lnk_HasKPIExtended, _super);
    function lnk_HasKPIExtended() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return lnk_HasKPIExtended;
}(lnk_HasKPI));
export { lnk_HasKPIExtended };
var lnk_HasPropertyTypeExtended = /** @class */ (function (_super) {
    __extends(lnk_HasPropertyTypeExtended, _super);
    function lnk_HasPropertyTypeExtended() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return lnk_HasPropertyTypeExtended;
}(lnk_HasPropertyType));
export { lnk_HasPropertyTypeExtended };
export var ValueChoicesTypeSort = function (a, b) {
    if (a.value == "Autres")
        return 1;
    if (b.value == "Autres")
        return -1;
    if (a["@class"] && b["@class"])
        return TradValue(a["@class"], "Name", a.value).localeCompare(TradValue(b["@class"], "Name", b.value));
    return a.value.localeCompare(b.value);
};
var KPIsManager = /** @class */ (function () {
    function KPIsManager(objectType) {
        this.lnk_HasProperties = {};
        this.lnk_HasKPIs = null;
        this._objectType = objectType;
    }
    KPIsManager.prototype.AdvertiserMode = function () {
        var _a, _b;
        return (_b = (_a = RightManager.GetUser()) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.AdvertiserMode;
    };
    KPIsManager.prototype.SupportMode = function () {
        var _a, _b;
        return (_b = (_a = RightManager.GetUser()) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.SupportMode;
    };
    KPIsManager.prototype.GetLnkHasKPIs = function (media) {
        return __awaiter(this, void 0, Promise, function () {
            var results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.lnk_HasKPIs) return [3 /*break*/, 2];
                        return [4 /*yield*/, DataProvider.search(lnk_HasKPI.name, {
                                "out.DocumentType": this._objectType,
                                "in.Name": "AdwOne",
                                properties: ["*", "in.Name as SourceType", "KPI.Name as Name", "KPI.Tags as Tags", "Name as NameLnk", "first(out.MediaTypes) as Media", "KPI.Aggregate as Aggregate"]
                            })];
                    case 1:
                        results = _a.sent();
                        this.lnk_HasKPIs = results.filter(function (r) { return r.Id; });
                        _a.label = 2;
                    case 2:
                        if (media)
                            return [2 /*return*/, this.lnk_HasKPIs.filter(function (l) { return l.Media == media; })];
                        return [2 /*return*/, this.lnk_HasKPIs];
                }
            });
        });
    };
    KPIsManager.prototype.GetLnkHasProperties = function (media) {
        return __awaiter(this, void 0, Promise, function () {
            var lnkHasProperties, propertyNames_1, ridPropertyTypes, RidType, operator, properties, _loop_1, _i, _a, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.lnk_HasProperties[media]) return [3 /*break*/, 5];
                        return [4 /*yield*/, DataProvider.search(lnk_HasPropertyType.name, {
                                "out.DocumentType": this._objectType,
                                "first(out.MediaTypes)": media,
                                properties: ["*", "in.Type as Name", "in.Type as Id", "in.ValueType as ValueType", "in.Tags as Tags", "in.Rank as Rank"]
                            })];
                    case 1:
                        lnkHasProperties = _b.sent();
                        return [4 /*yield*/, this.GetUserProperties()];
                    case 2:
                        propertyNames_1 = (_b.sent()).map(function (p) {
                            var a = p.name.split(".");
                            return a[a.length - 1];
                        });
                        lnkHasProperties = lnkHasProperties.filter(function (lnk) { return propertyNames_1.includes(lnk.Name); });
                        ridPropertyTypes = lnkHasProperties.filter(function (r) { return r.ValueType === eKPIType.Rid && r.Id != "BroadcastPlacement"; });
                        RidType = ridPropertyTypes.map(function (r) { return r.in; });
                        if (!RidType.length) return [3 /*break*/, 4];
                        operator = { property: "PropertyType", value: RidType, compare: eCompare.ContainsAny };
                        return [4 /*yield*/, DataProvider.search(ref_Property.name, { Active: true }, [operator])];
                    case 3:
                        properties = _b.sent();
                        _loop_1 = function (result) {
                            result.ValueChoices = properties.filter(function (p) { return p.PropertyType.includes(result.in); }).map(function (p) { return ({ rid: p["@rid"], value: TradValue(ref_Property.name, "Name", p.Name), "@class": p["@class"], default: p.Default }); });
                        };
                        for (_i = 0, _a = lnkHasProperties.filter(function (r) { return r.ValueType === eKPIType.Rid; }); _i < _a.length; _i++) {
                            result = _a[_i];
                            _loop_1(result);
                        }
                        _b.label = 4;
                    case 4:
                        this.lnk_HasProperties[media] = lnkHasProperties;
                        _b.label = 5;
                    case 5: return [2 /*return*/, this.lnk_HasProperties[media]];
                }
            });
        });
    };
    KPIsManager.prototype.GetUserKPIs = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, KPIsManagerCache.GetKPIs()];
            });
        });
    };
    KPIsManager.prototype.GetUserProperties = function () {
        return __awaiter(this, void 0, Promise, function () {
            var metadata, properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.metadata) return [3 /*break*/, 2];
                        return [4 /*yield*/, DataProvider.getMetadata(this._objectType, true)];
                    case 1:
                        metadata = (_a.sent());
                        // console.log("[GetUserProperties] Metadata", metadata);
                        this.metadata = metadata;
                        _a.label = 2;
                    case 2:
                        properties = this.metadata.filter(function (m) { return m.name !== 'Active'
                            && m.name !== 'Group'
                            && RightManager.hasRight(m.linkedClass, eRights.read)
                            && (!KPIsManager.EstimateProperties.includes(m.name) || RightManager.hasRight(eFunctions.ref_Estimates, eRights.read))
                            && (m.name !== 'ReturnedCurrency' || RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read)); });
                        return [2 /*return*/, properties];
                }
            });
        });
    };
    KPIsManager.prototype.eKPIByCategory = function (category) {
        return null;
    };
    KPIsManager.prototype.GetCategoryLnkHasKPIs = function (category, media) {
        return __awaiter(this, void 0, Promise, function () {
            var eKpis, kpis, lnkHasKpis;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        eKpis = this.eKPIByCategory(category);
                        if (!eKpis)
                            return [2 /*return*/, []];
                        return [4 /*yield*/, this.GetUserKPIs()];
                    case 1:
                        kpis = (_a.sent()).filter(function (k) { return eKpis.includes(k.Name); });
                        if (!kpis.length)
                            return [2 /*return*/, []];
                        return [4 /*yield*/, this.GetLnkHasKPIs(media)];
                    case 2:
                        lnkHasKpis = _a.sent();
                        return [2 /*return*/, lnkHasKpis.filter(function (e) { return kpis.some(function (kpi) { return kpi.Name == e.Name; }); })
                                .sort(function (e1, e2) { return eKpis.indexOf(e1.Name) - eKpis.indexOf(e2.Name); })];
                }
            });
        });
    };
    KPIsManager.prototype.SelectorProperties = function () {
        if (this.SupportMode() == "ref_Campaigns")
            return __spreadArray(['Campaign', 'Start', 'End'], KPIsManager.SupportProperties, true);
        return __spreadArray(['Campaign'], KPIsManager.AdvertiserProperties, true);
    };
    KPIsManager.prototype.SetMessagePropertiesFromCampaign = function (campaign, message) {
        var properties = this.SelectorProperties();
        properties.forEach(function (p) {
            if (p == "Campaign")
                message[p] = campaign === null || campaign === void 0 ? void 0 : campaign["@rid"];
            else
                message[p] = campaign === null || campaign === void 0 ? void 0 : campaign[p];
        });
        if (this.SupportMode() == "ref_Campaigns") {
            KPIsManager.TechConfCancelProperties.forEach(function (p) { return message[p] = campaign === null || campaign === void 0 ? void 0 : campaign[p]; });
            KPIsManager.SupportPressProperties.forEach(function (p) {
                var _a;
                if (!message.ModelProperties)
                    message.ModelProperties = {};
                message.ModelProperties[p] = (_a = campaign === null || campaign === void 0 ? void 0 : campaign.ModelProperties) === null || _a === void 0 ? void 0 : _a[p];
            });
        }
    };
    KPIsManager.prototype.HasPerformances = function (media) {
        return __awaiter(this, void 0, Promise, function () {
            var performances, userKpis;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.PerformanceFromMedia(media)];
                    case 1:
                        performances = _a.sent();
                        return [4 /*yield*/, this.GetUserKPIs()];
                    case 2:
                        userKpis = _a.sent();
                        return [2 /*return*/, userKpis.filter(function (k) { return performances.some(function (pk) { return pk == k.Name; }); }).length > 0];
                }
            });
        });
    };
    KPIsManager.prototype.PerformanceFromMedia = function (media) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var _medias;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, KPIsManagerCache.GetMedias()];
                    case 1:
                        _medias = _d.sent();
                        if (media && media == ((_a = _medias.find(function (m) { return m.Code == "DIG"; })) === null || _a === void 0 ? void 0 : _a["@rid"]))
                            return [2 /*return*/, PerformanceDIGKPIs];
                        if (media && (media == ((_b = _medias.find(function (m) { return m.Code == "RD"; })) === null || _b === void 0 ? void 0 : _b["@rid"]) ||
                            media == ((_c = _medias.find(function (m) { return m.Code == "TV"; })) === null || _c === void 0 ? void 0 : _c["@rid"])))
                            return [2 /*return*/, PerformanceTV_RADIOKPIs];
                        return [2 /*return*/, PerformanceKPIs];
                }
            });
        });
    };
    KPIsManager.prototype.GetKPIsFromBuyingType = function (media, buyingTypeProperty) {
        return __awaiter(this, void 0, Promise, function () {
            var _priceKPI, _baseKPI, lnkKPI, linkedKPIIds_1, linkedKPIs, performances_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _priceKPI = null;
                        _baseKPI = null;
                        return [4 /*yield*/, this.GetLnkHasKPIs(media)];
                    case 1:
                        lnkKPI = _a.sent();
                        if (!(buyingTypeProperty && buyingTypeProperty.LinkedKPIs)) return [3 /*break*/, 2];
                        linkedKPIIds_1 = buyingTypeProperty.LinkedKPIs.map(function (l) { return l.toString(); });
                        linkedKPIs = lnkKPI.filter(function (lnk) { return linkedKPIIds_1.includes(lnk.KPI); });
                        _priceKPI = linkedKPIs.find(function (l) { return l.ValueType == "Price"; });
                        if (_priceKPI === null || _priceKPI === void 0 ? void 0 : _priceKPI.Base)
                            _baseKPI = linkedKPIs.find(function (l) { return l.KPI == (_priceKPI === null || _priceKPI === void 0 ? void 0 : _priceKPI.Base); }); // PerformanceDIGKPIs.includes(l.Name as eKPI));
                        return [3 /*break*/, 5];
                    case 2: return [4 /*yield*/, this.PerformanceFromMedia(media)];
                    case 3:
                        performances_1 = (_a.sent());
                        _priceKPI = lnkKPI.find(function (l) { return l.Id == "GrossCPM"; });
                        return [4 /*yield*/, this.GetLnkHasKPIs(media)];
                    case 4:
                        _baseKPI = (_a.sent()).find(function (kpi) { return kpi.Name == performances_1[0]; });
                        _a.label = 5;
                    case 5: return [2 /*return*/, { PriceKPI: _priceKPI, BaseKPI: _baseKPI }];
                }
            });
        });
    };
    KPIsManager.prototype.GetTimePerformanceKPIs = function (media) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var kpis, lnkKPIs, medias, digitalMedia, _loop_2, _i, _b, lnk;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        kpis = {};
                        return [4 /*yield*/, this.GetLnkHasKPIs(media === null || media === void 0 ? void 0 : media["@rid"])];
                    case 1:
                        lnkKPIs = (_c.sent()).filter(function (lnk) { var _a; return (_a = lnk.Tags) === null || _a === void 0 ? void 0 : _a.includes("TimePerformance"); });
                        return [4 /*yield*/, KPIsManagerCache.GetMedias()];
                    case 2:
                        medias = _c.sent();
                        digitalMedia = medias.find(function (m) { return m.Code == "DIG"; });
                        _loop_2 = function (lnk) {
                            if (!kpis[lnk.Name]) {
                                var base = null;
                                if (lnk.Base)
                                    base = (_a = lnkKPIs.find(function (l) { return l.KPI == lnk.Base; })) === null || _a === void 0 ? void 0 : _a.Name;
                                kpis[lnk.Name] = { default: lnk.Tags.includes("Default"), medias: [lnk.Media], valueType: lnk.ValueType, base: base, aggregate: lnk.Aggregate };
                            }
                            else
                                kpis[lnk.Name].medias.push(lnk.Media);
                        };
                        for (_i = 0, _b = lnkKPIs.filter(function (l) { return (l.Media == (digitalMedia === null || digitalMedia === void 0 ? void 0 : digitalMedia["@rid"]) && l.ValueType == "Price") || (l.Media != (digitalMedia === null || digitalMedia === void 0 ? void 0 : digitalMedia["@rid"])); }); _i < _b.length; _i++) {
                            lnk = _b[_i];
                            _loop_2(lnk);
                        }
                        /*_priceKPI = linkedKPIs.find(l => l.ValueType == "Price");
                        if (_priceKPI?.Base)
                            _baseKPI = linkedKPIs.find(l => l.KPI == _priceKPI?.Base);*/
                        /*let medias = media ? [media] : null;
                                if (!medias)
                                    medias = await KPIsManagerCache.GetMedias();
                                const digitalMedia = medias.find(m => m.Code == "DIG");
                                const kpis: { [key: string]: { base?: string, default: boolean, medias: string[], valueType: eKPIType } } = {};
                        
                                if (digitalMedia) {
                                    const _buyingTypes = await DataProvider.search<ref_Property>(ref_Property.name, { "PropertyType.Type": "BuyingType" });
                                    for (const buyingType of _buyingTypes) {
                                        const buyingConfig = await KPIsManagerCache.GetInstance(ref_Messages.name).GetKPIsFromBuyingType(digitalMedia["@rid"], buyingType);
                                        kpis[buyingConfig.PriceKPI.Name] = { base: buyingConfig.BaseKPI?.Name, default: buyingType.Default ?? false, medias: [digitalMedia["@rid"]], valueType: eKPIType.Price };
                                    }
                                }
                                const otherMedias = medias.filter(m => m.Code != "DIG").map(m => m["@rid"]);
                        
                                if (otherMedias.length) {
                                    const lnks = (await this.GetLnkHasKPIs()).filter(lnk => otherMedias.includes(lnk.Media) &&
                                        
                                    for (const lnk of lnks) {
                                        if (!kpis[lnk.Name])
                                            kpis[lnk.Name] = { default: lnk.Tags.includes("Default"), medias: [lnk.Media], valueType: lnk.ValueType };
                                        else
                                            kpis[lnk.Name].medias.push(lnk.Media);
                                    }
                                    console.log("kpis (other Medias", kpis);
                                    
                                }
                                */
                        /*
                             kpis[eKPI.GRP] = { default: true, medias: otherMedias, valueType: eKPIType.Decimal };
                             kpis[eKPI.Coverage] = { default: true, medias: otherMedias, valueType: eKPIType.Percent };
                             kpis[eKPI.CGRP] = { default: true, medias: otherMedias, valueType: eKPIType.Price };
                             kpis[eKPI.Contacts] = { default: false, medias: otherMedias, valueType: eKPIType.Number };
                             kpis[eKPI.CoverageThousands] = { default: false, medias: otherMedias, valueType: eKPIType.Number };
                             kpis[eKPI.Repetition] = { default: false, medias: otherMedias, valueType: eKPIType.Decimal };*/
                        return [2 /*return*/, kpis];
                }
            });
        });
    };
    KPIsManager.GetFactorFromKPI = function (kpi) {
        return [eKPI.CPM, eKPI.vCPM, eKPI.Enchere].includes(kpi) ? 1000 : 1;
    };
    KPIsManager.prototype.ComputeGross = function (message, buyingConfig) {
        var _a, _b;
        var base = 1;
        if (buyingConfig.BaseKPI)
            base = (_b = (_a = message.KPIs) === null || _a === void 0 ? void 0 : _a[buyingConfig.BaseKPI.Id]) !== null && _b !== void 0 ? _b : 0;
        var factor = KPIsManager.GetFactorFromKPI(buyingConfig.PriceKPI.Name);
        message.KPIs.Gross = new BigNumber(base).multipliedBy(message.KPIs[buyingConfig.PriceKPI.Id]).dividedBy(factor).dp(2, 4).toNumber();
    };
    KPIsManager.prototype.InitializeBuyingConfig = function (msg, kpis, buyingConfig) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var base, factor;
            return __generator(this, function (_c) {
                if (msg.IsGrossCPM) {
                    msg.KpiCPM = (_a = buyingConfig.BaseKPI) === null || _a === void 0 ? void 0 : _a.KPI;
                    base = buyingConfig.BaseKPI ? ((_b = kpis === null || kpis === void 0 ? void 0 : kpis[buyingConfig.BaseKPI.Id]) !== null && _b !== void 0 ? _b : 0) : 1;
                    factor = KPIsManager.GetFactorFromKPI(buyingConfig.PriceKPI.Name);
                    if (base != 0)
                        msg.KPIs[buyingConfig.PriceKPI.Id] = msg.KPIs.Gross * factor / base;
                    else
                        msg.KPIs[buyingConfig.PriceKPI.Id] = 0;
                }
                else {
                    msg.KpiCPM = null;
                    delete msg.KPIs[buyingConfig.PriceKPI.Id];
                }
                return [2 /*return*/];
            });
        });
    };
    KPIsManager.AdvertiserProperties = ["Group", "AdvertiserGroup", "Advertiser", "Brand", "Product"];
    KPIsManager.AgencyProperties = ["Agency_Res", "Agency_Fact"];
    KPIsManager.AdvCompanyProperties = ["AdvCompany_Com", "AdvCompany_Fin"];
    KPIsManager.EstimateProperties = ["Estimates",
        "EstimateNumber",
        "Deversement.Estimate",
        "Deversement.InfoCO.LastUpdate",
        "Deversement.InfoFO.LastUpdate",
        "Deversement.InfoCO.Diff",
        "Deversement.InfoFO.Diff",
        "Deversement.Id"];
    KPIsManager.SupportProperties = ["Support", "Media", "BroadcastArea", "Currency"];
    KPIsManager.SupportPressProperties = ["EditorialTheme", "BroadcastAreaDetail"];
    KPIsManager.SupportDetailProperties = ["ModelProperties.Dated",
        "ModelProperties.Reference",
        "ModelProperties.MediaFamily",
        "ModelProperties.MediaFamilyOther",
        "ModelProperties.Periodicity",
        "ModelProperties.PeriodicityComment",
        "ModelProperties.SupportDetails",
        "ModelProperties.OjdYear"];
    KPIsManager.TechConfCancelProperties = ["TechDeliveryTimeLimit",
        "TechDeliveryComments",
        "ConfirmationTimeLimit",
        "ConfirmationComments",
        "CancellationTimeLimit",
        "CancellationComments"];
    return KPIsManager;
}());
export { KPIsManager };
var CampaignKPIsManager = /** @class */ (function (_super) {
    __extends(CampaignKPIsManager, _super);
    /**
     *
     */
    function CampaignKPIsManager() {
        return _super.call(this, ref_Campaigns.name) || this;
    }
    CampaignKPIsManager.prototype.GetUserKPIs = function () {
        return __awaiter(this, void 0, Promise, function () {
            var userKPIs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.GetUserKPIs.call(this)];
                    case 1:
                        userKPIs = _a.sent();
                        if (this.SupportMode() == ref_Messages.name)
                            userKPIs = userKPIs.filter(function (kpi) {
                                return kpi.Name != eKPI.Pagination
                                    && !PerformanceKPIs.some(function (p) { return kpi.Name == p; });
                            });
                        return [2 /*return*/, userKPIs.filter(function (kpi) { var _a; return !((_a = kpi === null || kpi === void 0 ? void 0 : kpi.Tags) === null || _a === void 0 ? void 0 : _a.includes("TimePerformance")); })];
                }
            });
        });
    };
    CampaignKPIsManager.prototype.GetUserProperties = function () {
        return __awaiter(this, void 0, Promise, function () {
            var properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.GetUserProperties.call(this)];
                    case 1:
                        properties = _a.sent();
                        if (this.SupportMode() == ref_Campaigns.name)
                            properties = properties.filter(function (p) {
                                return !KPIsManager.AdvertiserProperties.includes(p.name) &&
                                    p.name != "Visuals";
                            });
                        else
                            properties = properties.filter(function (p) {
                                return !KPIsManager.SupportProperties.includes(p.name) &&
                                    !KPIsManager.SupportDetailProperties.includes(p.name) &&
                                    !KPIsManager.SupportPressProperties.includes(p.name) &&
                                    !KPIsManager.TechConfCancelProperties.includes(p.name);
                            });
                        properties = properties.filter(function (p) {
                            return !KPIsManager.AgencyProperties.includes(p.name) &&
                                !KPIsManager.AdvCompanyProperties.includes(p.name);
                        });
                        //console.log("Campaign Properties", properties);
                        return [2 /*return*/, properties];
                }
            });
        });
    };
    CampaignKPIsManager.prototype.eKPIByCategory = function (category) {
        switch (category) {
            case "Pricing":
                return [eKPI.Budget, eKPI.Pagination];
            case "Performance":
                return PerformanceKPIs;
        }
        return _super.prototype.eKPIByCategory.call(this, category);
    };
    return CampaignKPIsManager;
}(KPIsManager));
var MessageKPIsManager = /** @class */ (function (_super) {
    __extends(MessageKPIsManager, _super);
    /**
     *
     */
    function MessageKPIsManager() {
        return _super.call(this, ref_Messages.name) || this;
    }
    MessageKPIsManager.prototype.GetUserKPIs = function () {
        return __awaiter(this, void 0, Promise, function () {
            var userKPIs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.GetUserKPIs.call(this)];
                    case 1:
                        userKPIs = _a.sent();
                        if (this.SupportMode() == ref_Campaigns.name)
                            userKPIs = userKPIs.filter(function (kpi) {
                                return !PerformanceKPIs.some(function (p) { return kpi.Name == p; })
                                    && kpi.Name != eKPI.ViewRate
                                    && kpi.Name != eKPI.SOV;
                            });
                        else
                            userKPIs = userKPIs.filter(function (kpi) { return kpi.Name != eKPI.Position; });
                        return [2 /*return*/, userKPIs];
                }
            });
        });
    };
    MessageKPIsManager.prototype.GetUserProperties = function () {
        return __awaiter(this, void 0, Promise, function () {
            var properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.GetUserProperties.call(this)];
                    case 1:
                        properties = _a.sent();
                        if (this.SupportMode() == ref_Messages.name)
                            properties = properties.filter(function (p) { return p.name != "Agency_Fact"; });
                        else
                            properties = properties.filter(function (p) {
                                return !KPIsManager.AdvCompanyProperties.includes(p.name) &&
                                    !KPIsManager.SupportDetailProperties.includes(p.name) &&
                                    p.name != "Agency_Fact" && p.name != "ModelProperties.AdCreation";
                            });
                        //console.log("Message Properties", properties);
                        return [2 /*return*/, properties];
                }
            });
        });
    };
    MessageKPIsManager.prototype.eKPIByCategory = function (category) {
        switch (category) {
            case "Format":
                return [eKPI.Position];
            case "Pricing":
                return [eKPI.GrossFormat, eKPI.GrossFormatRate, eKPI.GrossFormatValRate];
            case "volumes":
                return [eKPI.Total, eKPI.Paid, eKPI.Free];
            case "Performance":
                return __spreadArray([eKPI.ViewRate, eKPI.SOV], PerformanceKPIs, true);
            case "Audience":
                return [eKPI.GRP, eKPI.Contacts];
            case "Impressions":
                return [
                    eKPI.CPM,
                    eKPI.PaidPrint,
                    eKPI.FreePrint,
                    eKPI.TotalPrint,
                    eKPI.DeliveredCPM,
                    eKPI.DeliveredImpression,
                    eKPI.TargetingImpression
                ];
            case "Clics":
                return [
                    eKPI.CPC,
                    eKPI.TotalClic,
                    eKPI.DeliveredCPC,
                    eKPI.DeliveredClic,
                    eKPI.ClicRate
                ];
            case "Videos":
                return [
                    eKPI.CPVideo,
                    eKPI.CompletionRate,
                    eKPI.DeliveringRate,
                    eKPI.Video25PercentViews,
                    eKPI.Video50PercentViews,
                    eKPI.Video75PercentViews,
                    eKPI.Video100PercentViews
                ];
            case "Other":
                return [
                    eKPI.QualityScore,
                    eKPI.ConvertionRate,
                    eKPI.ROAS,
                    eKPI.CTR,
                    eKPI.CAC,
                    eKPI.Conversions,
                    eKPI.CPA,
                    eKPI.Acquisitions,
                    eKPI.CPF,
                    eKPI.Fans,
                    eKPI.CPE,
                    eKPI.Engagements,
                    eKPI.CPI,
                    eKPI.Installations,
                    eKPI.CPLSample,
                    eKPI.SampleLeads,
                    eKPI.CPLSuscription,
                    eKPI.SuscriptionLeads,
                    eKPI.CPLSale,
                    eKPI.SaleLeads,
                    eKPI.CPVisite,
                    eKPI.Visites,
                    eKPI.CPV,
                    eKPI.Views,
                    eKPI.vCPM,
                    eKPI.VisibilityImpression,
                    eKPI.Enchere,
                    eKPI.Forfait
                ];
        }
        return _super.prototype.eKPIByCategory.call(this, category);
    };
    return MessageKPIsManager;
}(KPIsManager));
var KPIsManagerCache = /** @class */ (function () {
    function KPIsManagerCache() {
    }
    KPIsManagerCache.GetKPIs = function () {
        return __awaiter(this, void 0, Promise, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!KPIsManagerCache.kpis) return [3 /*break*/, 2];
                        _a = KPIsManagerCache;
                        return [4 /*yield*/, DataProvider.search(ref_KPIs.name)];
                    case 1:
                        _a.kpis = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, KPIsManagerCache.kpis];
                }
            });
        });
    };
    KPIsManagerCache.GetCurrencies = function () {
        return __awaiter(this, void 0, Promise, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!KPIsManagerCache.currencies) return [3 /*break*/, 2];
                        _a = KPIsManagerCache;
                        return [4 /*yield*/, DataProvider.search(ref_Currencies.name)];
                    case 1:
                        _a.currencies = (_b.sent());
                        _b.label = 2;
                    case 2: return [2 /*return*/, KPIsManagerCache.currencies];
                }
            });
        });
    };
    KPIsManagerCache.GetMedias = function () {
        return __awaiter(this, void 0, Promise, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!KPIsManagerCache.medias) return [3 /*break*/, 2];
                        _a = KPIsManagerCache;
                        return [4 /*yield*/, DataProvider.search(ref_Media.name)];
                    case 1:
                        _a.medias = (_b.sent());
                        _b.label = 2;
                    case 2: return [2 /*return*/, KPIsManagerCache.medias];
                }
            });
        });
    };
    KPIsManagerCache.GetInstance = function (objectType) {
        if (!KPIsManagerCache.instances[objectType]) {
            switch (objectType) {
                case "ref_Campaigns":
                    KPIsManagerCache.instances[objectType] = new CampaignKPIsManager();
                    break;
                case "ref_Messages":
                    KPIsManagerCache.instances[objectType] = new MessageKPIsManager();
                    break;
                default:
                    KPIsManagerCache.instances[objectType] = new KPIsManager(objectType);
                    break;
            }
        }
        return KPIsManagerCache.instances[objectType];
    };
    KPIsManagerCache.kpis = null;
    KPIsManagerCache.medias = null;
    KPIsManagerCache.currencies = null;
    KPIsManagerCache.instances = {};
    return KPIsManagerCache;
}());
export { KPIsManagerCache };
