import { toArray } from '../../tools.bin';
export var eRights;
(function (eRights) {
    eRights["create"] = "CREATE";
    eRights["read"] = "READ";
    eRights["readDetails"] = "READ_DETAILS";
    eRights["update"] = "UPDATE";
    eRights["delete"] = "DELETE";
    eRights["viewScheduler"] = "VIEW_SCHEDULER";
    eRights["viewCrossedTable"] = "VIEW_CROSSED_TABLE";
    eRights["viewDashboard"] = "VIEW_DASHBOARD";
    eRights["viewReporting"] = "VIEW_REPORTING";
    eRights["viewDashboardExtended"] = "VIEW_DASHBOARD_EXTENDED";
    eRights["export"] = "EXPORT";
    eRights["formatedExport"] = "FORMATED_EXPORT";
})(eRights || (eRights = {}));
export var isCreate = function (action) { return action === eRights.create; };
export var isUpdate = function (action) { return action === eRights.update; };
export var defaultRightCrud = [eRights.create, eRights.read, eRights.update, eRights.delete];
export var eFunctions;
(function (eFunctions) {
    eFunctions["ref_Campaigns"] = "ref_Campaigns";
    eFunctions["ref_Messages"] = "ref_Messages";
    eFunctions["cache"] = "cache";
    eFunctions["ref_Agreements"] = "ref_Agreements";
    eFunctions["ref_GlobalAgreements"] = "ref_GlobalAgreements";
    eFunctions["ref_Supports"] = "ref_Supports";
    eFunctions["ref_AdvertisingCompanies"] = "ref_AdvertisingCompanies";
    eFunctions["ref_BroadcastAreas"] = "ref_BroadcastAreas";
    eFunctions["ref_Estimates"] = "ref_Estimates";
    // Sellsy = 'Sellsy',
    eFunctions["ref_Currencies"] = "ref_Currencies";
    eFunctions["ref_Media"] = "ref_Media";
    eFunctions["ref_Property"] = "ref_Property";
    eFunctions["ref_AdvertiserGroups"] = "ref_AdvertiserGroups";
    eFunctions["ref_Advertisers"] = "ref_Advertisers";
    eFunctions["ref_Brands"] = "ref_Brands";
    eFunctions["ref_Products"] = "ref_Products";
    eFunctions["ref_Agencies"] = "ref_Agencies";
    eFunctions["ref_Imports"] = "ref_Imports";
    eFunctions["ref_Crons"] = "ref_Crons";
    eFunctions["ref_Groups"] = "ref_Groups";
    eFunctions["ref_SubAgencies"] = "ref_SubAgencies";
    eFunctions["ref_ProjectConfigurations"] = "ref_ProjectConfigurations";
    eFunctions["User"] = "User";
    eFunctions["Logs"] = "Logs";
    eFunctions["Trad"] = "Trad";
    eFunctions["Contacts"] = "Contacts";
    eFunctions["ref_CalendarEvent"] = "ref_CalendarEvent";
    eFunctions["ExcelTemplate"] = "ExcelTemplate";
    eFunctions["UserRights"] = "UserRights";
    eFunctions["ref_Customers"] = "ref_Customers";
    eFunctions["lnk_ChangeRate"] = "lnk_ChangeRate";
    eFunctions["ReferentialHasViews"] = "ReferentialHasViews";
    eFunctions["ref_ReleaseVersion"] = "ref_ReleaseVersion";
})(eFunctions || (eFunctions = {}));
var RightManager = /** @class */ (function () {
    function RightManager() {
    }
    RightManager.hasRight = function (key, action, roles) {
        if (!RightManager.rightsProvider)
            throw new Error('RightProvider is not defined');
        if (roles && !RightManager.roleProvider)
            throw new Error('RightProvider is not defined');
        if (roles && !toArray(roles).find(function (r) { return r === RightManager.roleProvider(); }))
            return false;
        return toArray(action).every(function (a) { var _a, _b; return !((_a = RightManager.rightsProvider()) === null || _a === void 0 ? void 0 : _a[key]) || ((_b = RightManager.rightsProvider()) === null || _b === void 0 ? void 0 : _b[key].includes(a)); });
    };
    return RightManager;
}());
export { RightManager };
