var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Agreements } from 'hub-lib/dto/client/ref_Agreements.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { ref_Estimates } from 'hub-lib/dto/client/ref_Estimates.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin'; // export class mongoRequestsParams {
//     findParams: {
//         collections?: string | string[];
//         _id?: string | string[];
//         documentType: any;
//         mongoParams: {
//             [prop: string]: { compare: eCompare, value: any };
//         };
//     };
//     newSetParams: { [prop: string]: any };
// }
var initialState = { collections: null, documentType: null, documentTypesAvailable: [ref_Messages.name, ref_Campaigns.name, ref_Estimates.name, ref_Agreements.name], documentProperties: null, findParams: [], newSetParams: [], countReports: null };
export var setState = createAsyncThunk('databaseRequest/setState', function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, newState, _await$Client$getMeta, _await$Client$getMeta2, _await$Client$getMeta3, _a; return __generator(this, function (_b) {
    switch (_b.label) {
        case 0:
            console.log("[setState]", data);
            state = thunkAPI.getState();
            newState = __assign(__assign({}, state.db), data);
            if (!(data.documentType && data.documentType != state.db.documentType)) return [3 /*break*/, 2];
            _a = newState;
            return [4 /*yield*/, Client.getMetadata(data.documentType, true)];
        case 1:
            _a.documentProperties = (_await$Client$getMeta = _b.sent()) === null || _await$Client$getMeta === void 0 ? void 0 : (_await$Client$getMeta2 = _await$Client$getMeta.data) === null || _await$Client$getMeta2 === void 0 ? void 0 : (_await$Client$getMeta3 = _await$Client$getMeta2.results) === null || _await$Client$getMeta3 === void 0 ? void 0 : _await$Client$getMeta3.map(function (p) { return p.name; });
            _b.label = 2;
        case 2:
            newState.findParams = newState.findParams.filter(function (p) { return p.property; });
            newState.newSetParams = newState.newSetParams.filter(function (p) { return p.property; });
            try { }
            catch (err) {
                return [2 /*return*/, thunkAPI.rejectWithValue(err)];
            }
            console.log("[setState] return", newState);
            return [2 /*return*/, newState];
    }
}); }); });
export var databaseRequestSlice = createSlice({ name: 'databaseRequest', initialState: initialState, reducers: { setDataSync: function (state, action) { console.log("[setDataSync]", action.payload); Object.assign(state, action.payload); } }, extraReducers: function (builder) {
        var fulfilledCallback = function (state, action) {
            Object.assign(state, action.payload); // if (action.payload?.data)
            //     state.data = action.payload.data;
            // if (action.payload?.offers)
            //     state.offers = action.payload.offers;
        };
        builder.addCase(setState.fulfilled, function (state, action) {
            fulfilledCallback(state, action); // state.lockNext = false;
        });
    } });
export var setDataSync = databaseRequestSlice.actions.setDataSync;
export var databaseRequestReducer = databaseRequestSlice.reducer;
