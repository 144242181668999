var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { eIndicateurType } from "adwone-engine/index.bin";
import { Sort } from "format-lib/index.bin";
import { eDiscountOptionType, eDiscountOptionValue } from "hub-lib/models/external.bin";
import { eKPI, eKPIType, KPIsManager, KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { distinct, ePropertyOptionFilter, Typed } from "hub-lib/tools.bin";
import { FormatPropertyName, GetCurrentLocale, SetCurrentLocale, Trad, TradProp } from "trad-lib";
import { eColumnType } from "../models/types.bin";
import { AggregatorBase } from "./AggregatorBase";
import { GetCOFOIndicateurDiscounts, GetComputedKpiPriceIO, GetComputedIO, GetAdCreationII, GetPlacementCategoryII, GetEstimateIIs, GetDuplicatesIO, GetNumberElementsIO, GetAdvCompanyGroupIOs, GetTimeIOs, GetCampaignKPI_IOs, GetFiscalMonthIO } from "./IndicateurListing";
import { ref_Campaigns } from "../dto/client/ref_Campaigns.bin";
import { RightManager } from "../models/types/rights.bin";
/**
 * Indicateurs possibles à mettre dans un tableau de ref_Messages
 */
var MessageAggregator = /** @class */ (function (_super) {
    __extends(MessageAggregator, _super);
    function MessageAggregator() {
        var _this = _super.call(this, ref_Messages) || this;
        _this.Provide = function () { return __awaiter(_this, void 0, void 0, function () {
            var kpis, lnk_kpis, discounts, _a, _b, propertyIOs, kpiIOs, discountIOs, kpiBarterIO, barterIOs, totalTaxes, totalHonoCO, totalHonoFO, totalFrais, totalFraisFO, computedIOs, kpiGrossCPMIO, kpiGrossIO, totalCOKpi, totalCOLnkKpi, totalFOKpi, totalFOLnkKpi, netKpi, netLnkKpi, netKpiFo, netLnkKpiFO, honoIOs, honoFTIOs, tvaIOs, fpIOs, fcIOs, numberTotal, cpmIOs, _c, _d, boundIOs, _e, returnedIOs, _f, optionsRet, _g, _h, _j, _k, _l, _m, _o, ratioIOs, _p, numberCampaigns, contents;
            var _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
            return __generator(this, function (_1) {
                switch (_1.label) {
                    case 0: return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetUserKPIs()];
                    case 1:
                        kpis = _1.sent();
                        return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetLnkHasKPIs()];
                    case 2:
                        lnk_kpis = _1.sent();
                        _a = Sort;
                        _b = [ref_DiscountClasses.name];
                        return [4 /*yield*/, this.getDiscountClasses()];
                    case 3:
                        discounts = _a.apply(void 0, _b.concat([_1.sent()]));
                        return [4 /*yield*/, this.MetadataToIndicateurOptions()];
                    case 4:
                        propertyIOs = _1.sent();
                        return [4 /*yield*/, this.KPIToIndicateurOptions()];
                    case 5:
                        kpiIOs = (_1.sent())
                            .filter(function (io) { return io.indicateur.field != "TotalCount"; });
                        return [4 /*yield*/, this.DiscountToIndicateurOptions()];
                    case 6:
                        discountIOs = _1.sent();
                        kpiBarterIO = kpiIOs.find(function (io) { return io.indicateur.field == "Barter"; });
                        return [4 /*yield*/, this.BarterToIndicateurOptions(kpiBarterIO.indicateur)];
                    case 7:
                        barterIOs = _1.sent();
                        return [4 /*yield*/, this.DiscountToCumulIndicateurOption("Taxes", Trad("total_taxes"))];
                    case 8:
                        totalTaxes = _1.sent();
                        return [4 /*yield*/, this.DiscountToCumulIndicateurOption("Honoraires", Trad("total_honoraires"))];
                    case 9:
                        totalHonoCO = _1.sent();
                        return [4 /*yield*/, this.DiscountToCumulIndicateurOption("Honoraires", Trad("total_honoraires"), { discountOptions: { type: eDiscountOptionType.FO } })];
                    case 10:
                        totalHonoFO = _1.sent();
                        return [4 /*yield*/, this.DiscountToCumulIndicateurOption("Frais", Trad("total_frais"))];
                    case 11:
                        totalFrais = _1.sent();
                        return [4 /*yield*/, this.DiscountToCumulIndicateurOption("Frais", Trad("total_frais"), { discountOptions: { type: eDiscountOptionType.FO } })];
                    case 12:
                        totalFraisFO = _1.sent();
                        computedIOs = [/*cpmTotalIO,*/ totalTaxes, totalHonoCO, totalFrais];
                        kpiGrossCPMIO = kpiIOs.find(function (io) { return io.indicateur.field == "GrossCPM"; });
                        kpiGrossIO = kpiIOs.find(function (io) { return io.indicateur.field == "Gross"; });
                        totalCOKpi = kpis.find(function (k) { return k.Name === "Total"; });
                        totalCOLnkKpi = lnk_kpis.find(function (lnk) { return lnk.KPI === totalCOKpi["@rid"]; });
                        totalFOKpi = kpis.find(function (k) { return k.Name === "Total FO"; });
                        totalFOLnkKpi = lnk_kpis.find(function (lnk) { return lnk.KPI === totalFOKpi["@rid"]; });
                        netKpi = kpis.find(function (k) { return k.Name === "Net"; });
                        netLnkKpi = lnk_kpis.find(function (lnk) { return lnk.KPI === netKpi["@rid"]; });
                        netKpiFo = kpis.find(function (k) { return k.Name === "Net FO"; });
                        netLnkKpiFO = lnk_kpis.find(function (lnk) { return lnk.KPI === netKpiFo["@rid"]; });
                        honoIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "Honoraires");
                        honoFTIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "Honoraires sur frais techniques");
                        tvaIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "TVA");
                        fpIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "Frais de production");
                        fcIOs = GetCOFOIndicateurDiscounts(discounts, discountIOs, "Frais de création");
                        // Total CO Hors Honos, Hors Frais de Production, Hors Frais de Création
                        computedIOs.push(GetComputedKpiPriceIO("total", totalCOLnkKpi, "totalCOHonoFPFC", "-", [totalHonoCO.indicateur, fpIOs.CO, fcIOs.CO, tvaIOs.CO]));
                        // Total FO Hors Honos, Hors Frais de Production, Hors Frais de Création
                        computedIOs.push(GetComputedKpiPriceIO("totalFo", totalFOLnkKpi, "totalFOHonoFPFC", "-", [totalHonoFO.indicateur, fpIOs.FO, fcIOs.FO, tvaIOs.FO]));
                        // Net CO + FT + hono
                        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpi, "netCoFTHono", "+", [totalFrais.indicateur, honoIOs.CO, honoFTIOs.CO]));
                        // Net FO + FT hors hono
                        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpiFO, "netFoFT", "+", [totalFraisFO.indicateur]));
                        // Net CO + FT hors hono
                        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpi, "netCoFT", "+", [totalFrais.indicateur]));
                        // Net CO + hono
                        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpi, "netCoHono", "+", [honoIOs.CO]));
                        // Net FO + hono
                        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpiFO, "netFoHono", "+", [honoIOs.FO]));
                        // Net FO + FT Hono inclus
                        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpiFO, "netFoFTHono", "+", [totalFraisFO.indicateur, honoIOs.FO, honoFTIOs.FO]));
                        // Net FO TTC hono inclus
                        computedIOs.push(GetComputedKpiPriceIO("net", netLnkKpiFO, "netFoTTC", "+", [totalHonoFO.indicateur, tvaIOs.FO]));
                        // Total Frais techniques Hono inclus
                        computedIOs.push(GetComputedIO(eKPIType.Price, "ftHono", "+", [totalFrais.indicateur, honoIOs.CO, honoFTIOs.CO]));
                        numberTotal = GetComputedIO(eKPIType.Decimal, "Nombre total", "+", [
                            kpiIOs.find(function (p) { return p.indicateur.name == Trad("Nombre gracieux"); }).indicateur,
                            kpiIOs.find(function (p) { return p.indicateur.name == Trad("Nombre payant"); }).indicateur
                        ]);
                        computedIOs.push(numberTotal);
                        computedIOs.push(GetComputedIO(eKPIType.Decimal, "PMP CO", "/", [
                            kpiIOs.find(function (io) { return io.indicateur.field == "NetCO"; }).indicateur,
                            numberTotal.indicateur
                        ]));
                        computedIOs.push(GetComputedIO(eKPIType.Decimal, "PMP FO", "/", [
                            kpiIOs.find(function (io) { return io.indicateur.field == "NetFO"; }).indicateur,
                            numberTotal.indicateur
                        ]));
                        return [4 /*yield*/, this.GetCPMIndicateurOptions()];
                    case 13:
                        cpmIOs = _1.sent();
                        _d = (_c = computedIOs).push;
                        _q = {};
                        return [4 /*yield*/, this.CreateCompareIndicateur(TradProp("compliance_placement_pige", ref_Messages), "ModelProperties.BroadcastPlacement", "Placement")];
                    case 14:
                        _d.apply(_c, [(_q.indicateur = _1.sent(),
                                _q.columnType = eColumnType.KPI,
                                _q)]);
                        boundIOs = [];
                        if (!propertyIOs.some(function (p) { return p.indicateur.field == "Deversement.Estimate"; })) return [3 /*break*/, 16];
                        _e = [[]];
                        return [4 /*yield*/, this.KPIToIndicateurOptions(eKPIType.PriceBound)];
                    case 15:
                        boundIOs = __spreadArray.apply(void 0, [__spreadArray.apply(void 0, _e.concat([_1.sent(), true])), this.GetDiscountIO_Typed(discountIOs, eKPIType.PriceBound), true]);
                        _1.label = 16;
                    case 16:
                        returnedIOs = [];
                        if (!propertyIOs.some(function (p) { return p.indicateur.field == "ReturnedCurrency"; })) return [3 /*break*/, 22];
                        _f = [[]];
                        return [4 /*yield*/, this.KPIToIndicateurOptions(eKPIType.PriceReturned)];
                    case 17:
                        returnedIOs = __spreadArray.apply(void 0, [__spreadArray.apply(void 0, _f.concat([_1.sent(), true])), this.GetDiscountIO_Typed(discountIOs, eKPIType.PriceReturned), true]);
                        optionsRet = {
                            computedOptions: { isPriceReturned: true },
                            discountOptions: { isPriceReturned: true }
                        };
                        _h = (_g = returnedIOs).push;
                        return [4 /*yield*/, this.DiscountToCumulIndicateurOption("Taxes", "".concat(Trad("total_taxes"), " ").concat(Trad("returned")), optionsRet)];
                    case 18:
                        _h.apply(_g, [_1.sent()]);
                        _k = (_j = returnedIOs).push;
                        return [4 /*yield*/, this.DiscountToCumulIndicateurOption("Honoraires", "".concat(Trad("total_honoraires"), " ").concat(Trad("returned")), optionsRet)];
                    case 19:
                        _k.apply(_j, [_1.sent()]);
                        _m = (_l = returnedIOs).push;
                        return [4 /*yield*/, this.DiscountToCumulIndicateurOption("Frais", "".concat(Trad("total_frais"), " ").concat(Trad("returned")), optionsRet)];
                    case 20:
                        _m.apply(_l, [_1.sent()]);
                        _o = [__spreadArray([], cpmIOs, true)];
                        return [4 /*yield*/, this.GetCPMIndicateurOptions(eKPIType.PriceReturned)];
                    case 21:
                        cpmIOs = __spreadArray.apply(void 0, _o.concat([_1.sent(), true]));
                        returnedIOs.push({
                            columnType: eColumnType.Property,
                            indicateur: Typed({
                                name: Trad("returnedCurrency_rate"),
                                valueType: eKPIType.FullDecimal,
                                type: eIndicateurType.kpi,
                                field: netLnkKpi.Id,
                                options: { rid: undefined, isPriceReturned: true, forceValue: 1 }
                            })
                        });
                        _1.label = 22;
                    case 22:
                        _r = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_net-b"), "Net", "Brut")];
                    case 23:
                        _p = [
                            (_r.indicateur = _1.sent(), _r.columnType = eColumnType.KPI, _r)
                        ];
                        _s = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_bba-bv"), "Brut Base Achat", "Brut Valorisé")];
                    case 24:
                        _p = _p.concat([
                            (_s.indicateur = _1.sent(), _s.columnType = eColumnType.KPI, _s)
                        ]);
                        _t = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_net-bv"), "Net", "Brut Valorisé")];
                    case 25:
                        _p = _p.concat([
                            (_t.indicateur = _1.sent(), _t.columnType = eColumnType.KPI, _t)
                        ]);
                        _u = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_net-bv_fo"), "Net FO", "Brut Valorisé")];
                    case 26:
                        _p = _p.concat([
                            (_u.indicateur = _1.sent(), _u.columnType = eColumnType.KPI, _u)
                        ]);
                        _v = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_net-bba"), "Net", "Brut Base Achat")];
                    case 27:
                        _p = _p.concat([
                            (_v.indicateur = _1.sent(), _v.columnType = eColumnType.KPI, _v)
                        ]);
                        _w = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_net-bba_fo"), "Net FO", "Brut Base Achat")];
                    case 28:
                        _p = _p.concat([
                            (_w.indicateur = _1.sent(), _w.columnType = eColumnType.KPI, _w)
                        ]);
                        _x = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_total-bba"), "Total", "Brut Base Achat")];
                    case 29:
                        _p = _p.concat([
                            (_x.indicateur = _1.sent(), _x.columnType = eColumnType.KPI, _x)
                        ]);
                        _y = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_total-bba_fo"), "Total FO", "Brut Base Achat")];
                    case 30:
                        _p = _p.concat([
                            (_y.indicateur = _1.sent(), _y.columnType = eColumnType.KPI, _y)
                        ]);
                        _z = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_total-bv"), "Total", "Brut Valorisé")];
                    case 31:
                        _p = _p.concat([
                            (_z.indicateur = _1.sent(), _z.columnType = eColumnType.KPI, _z)
                        ]);
                        _0 = {};
                        return [4 /*yield*/, this.CreateRatioIndicateur(Trad("rate_total-bv_fo"), "Total FO", "Brut Valorisé")];
                    case 32:
                        ratioIOs = _p.concat([
                            (_0.indicateur = _1.sent(), _0.columnType = eColumnType.KPI, _0)
                        ]);
                        numberCampaigns = {
                            indicateur: {
                                type: eIndicateurType.kpi,
                                name: Trad("Nombre de campagnes"),
                                valueType: eKPIType.Number,
                                field: "Campaign",
                                options: { groupBy: true, forceValue: 1 }
                            },
                            columnType: eColumnType.KPI
                        };
                        contents = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], propertyIOs, true), kpiIOs, true), discountIOs, true), barterIOs, true), computedIOs, true), cpmIOs, true), boundIOs, true), returnedIOs, true), ratioIOs, true), [
                            numberCampaigns,
                            GetDuplicatesIO(),
                            GetNumberElementsIO()
                        ], false);
                        return [2 /*return*/, this.Sort_IO(contents)];
                }
            });
        }); };
        _this.ProvideLEGOIndicateurs = function () { return __awaiter(_this, void 0, void 0, function () {
            var localeSaved, filterKpis, indicateurs, indicateurIds, indicateurId, columns;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        localeSaved = GetCurrentLocale();
                        return [4 /*yield*/, SetCurrentLocale("engine")];
                    case 1:
                        _a.sent();
                        filterKpis = [eColumnType.KPI, eColumnType.Barter, eColumnType.PriceReturned, eColumnType.Property];
                        return [4 /*yield*/, this.Provide()
                                .then(function (inds) { return inds.filter(function (i) { var _a; return filterKpis.includes(i.columnType) && !((_a = i.indicateur.options) === null || _a === void 0 ? void 0 : _a["formater"]); }); })];
                    case 2:
                        indicateurs = (_a.sent())
                            .map(function (i) { return i.indicateur; });
                        // pour les indicateurs de type KPI on prend l'id en name
                        indicateurs.forEach(function (i) {
                            var _a, _b;
                            if (i.field) {
                                var fullField = i.field + (((_a = i.options) === null || _a === void 0 ? void 0 : _a['subProperty']) ? ('.' + ((_b = i.options) === null || _b === void 0 ? void 0 : _b['subProperty'])) : '');
                                i.name = FormatPropertyName(fullField, fullField.includes("ModelProperties"));
                            }
                        });
                        indicateurIds = distinct(indicateurs
                            .filter(function (i) { return i.valueType == eKPIType.Rid; })
                            .map(function (i) { return (__assign(__assign({}, i), { valueType: eKPIType.String, name: (i.field ? FormatPropertyName(i.field, i.field.includes("ModelProperties")) : i.name) + '_id', options: null })); }), function (i) { return i.name; });
                        indicateurId = {
                            type: eIndicateurType.info,
                            name: 'ID',
                            field: '@rid',
                            valueType: eKPIType.String
                        };
                        return [4 /*yield*/, SetCurrentLocale(localeSaved)];
                    case 3:
                        _a.sent();
                        columns = __spreadArray(__spreadArray([indicateurId], indicateurIds, true), indicateurs, true);
                        return [2 /*return*/, columns];
                }
            });
        }); };
        return _this;
    }
    MessageAggregator.prototype.GetEditableColumnsFullObject = function () {
        return [
            TradProp("Start", this._objectType),
            TradProp("End", this._objectType),
            TradProp("ModelProperties.Periodicity", this._objectType),
            TradProp("Format", this._objectType)
        ];
    };
    MessageAggregator.prototype.GetEditableColumns = function () {
        return __spreadArray(__spreadArray([], this.GetEditableColumnsFullObject(), true), [
            Trad("Abonnements"),
            TradProp("ModelProperties.PurchaseOrder", this._objectType),
            Trad("Brut Diffusé"),
            TradProp("CancellationComments", this._objectType),
            TradProp("ConfirmationComments", this._objectType),
            TradProp("TechDeliveryComments", this._objectType),
            TradProp("ModelProperties.Comment", this._objectType),
            TradProp("ModelProperties.InternalComment", this._objectType),
            TradProp("ModelProperties.BroadcastComments", this._objectType),
            TradProp("AdditionalFormat", this._objectType),
            TradProp("AdditionalPlacement", this._objectType),
            TradProp("ModelProperties.TechnicalContact", this._objectType),
            TradProp("ModelProperties.Dated", this._objectType),
            TradProp("ModelProperties.OIDate", this._objectType),
            // TradProp("Start", this._objectType),
            TradProp("CancellationTimeLimit", this._objectType),
            TradProp("ConfirmationTimeLimit", this._objectType),
            TradProp("TechDeliveryTimeLimit", this._objectType),
            TradProp("ModelProperties.SupportDetails", this._objectType),
            TradProp("ModelProperties.BroadcastAreaDetail", this._objectType),
            Trad("Diffusion payée"),
            Trad("Diffusion totale"),
            TradProp("ModelProperties.Device", this._objectType),
            TradProp("ModelProperties.Position", this._objectType),
            TradProp("ModelProperties.BudgetCommitment", this._objectType),
            // TradProp("End", this._objectType),
            TradProp("ModelProperties.ActivityType", this._objectType),
            TradProp("PricingLocked", this._objectType),
            TradProp("Agency_Res", this._objectType),
            TradProp("ModelProperties.BuyingType", this._objectType),
            TradProp("ModelProperties.ActivationMode", this._objectType),
            Trad("Nb ex à livrer (passe incluse)"),
            TradProp("ModelProperties.Reference", this._objectType),
            TradProp("ModelProperties.CommunicationObjective", this._objectType),
            TradProp("ModelProperties.Patrimony", this._objectType),
            TradProp("ModelProperties.Productivity", this._objectType),
            TradProp("ModelProperties.TechnicalSpecifications", this._objectType),
            TradProp("Status", this._objectType),
            TradProp("ModelProperties.EditorialTheme", this._objectType),
            Trad("Tirage"),
            TradProp("ModelProperties.NavigationType", this._objectType),
            TradProp("ModelProperties.PackageType", this._objectType),
            TradProp("ModelProperties.AdCreation", this._objectType)
        ], false);
    };
    MessageAggregator.prototype.GetDefaultColumnNames = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var isSeller, confs, defaultPluriMediaBuyer, defaultPrintBuyer, defaultPrintSeller;
            return __generator(this, function (_c) {
                isSeller = ((_b = (_a = RightManager.GetUser()) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.Activity) == 'Selling';
                confs = [{
                        name: Trad('default'),
                        colNames: [
                            TradProp("Start", this._objectType),
                            TradProp("End", this._objectType),
                            TradProp("Status", this._objectType),
                            TradProp("Media", this._objectType),
                            TradProp("Support", this._objectType),
                            TradProp("BroadcastArea", this._objectType),
                            TradProp("AdvCompany_Com", this._objectType),
                            TradProp("Campaign", this._objectType),
                            TradProp("AdvertiserGroup", this._objectType),
                            TradProp("Advertiser", this._objectType),
                            TradProp("Brand", this._objectType),
                            TradProp("Product", this._objectType),
                            TradProp("Format", this._objectType),
                            TradProp("Placement", this._objectType),
                            TradProp("Currency", this._objectType),
                            Trad("Brut"),
                            Trad("Net"),
                            Trad("netCoFTHono"),
                            Trad("rate_net-bv"),
                            TradProp("Created_by", this._objectType)
                        ]
                    }];
                defaultPluriMediaBuyer = {
                    name: Trad('default_pluri'),
                    colNames: [
                        TradProp("Media", this._objectType),
                        TradProp("AdvCompany_Com", this._objectType),
                        TradProp("Support", this._objectType),
                        TradProp("Start", this._objectType),
                        TradProp("End", this._objectType),
                        TradProp("Status", this._objectType),
                        TradProp("Format", this._objectType),
                        Trad("Net"),
                        Trad("Nombre total")
                    ]
                };
                defaultPrintBuyer = {
                    name: Trad('default_print'),
                    colNames: [
                        TradProp("PricingLocked", this._objectType),
                        TradProp("Support", this._objectType),
                        TradProp("Start", this._objectType),
                        TradProp("Status", this._objectType),
                        TradProp("Format", this._objectType),
                        TradProp("Placement", this._objectType),
                        Trad("Net"),
                        Trad("total_frais"),
                        Trad('taux') + ' ' + Trad('Honoraires'),
                        Trad('netCoFTHono'),
                        Trad("Nombre total"),
                        TradProp("AdvCompany_Com", this._objectType),
                        Trad('Diffusion payée')
                    ]
                };
                defaultPrintSeller = {
                    name: Trad('default_print'),
                    colNames: [
                        TradProp("Start", this._objectType),
                        TradProp("Support", this._objectType),
                        TradProp("BroadcastArea", this._objectType),
                        TradProp("ConfirmationTimeLimit", this._objectType),
                        TradProp("TechDeliveryTimeLimit", this._objectType),
                        Trad("Pagination"),
                        Trad("Nombre total"),
                        Trad("Position"),
                        TradProp("Advertiser", this._objectType),
                        TradProp("Format", this._objectType),
                        TradProp("Placement", this._objectType),
                        Trad("Budget"),
                        Trad("Net")
                    ]
                };
                if (!isSeller)
                    confs.push(defaultPluriMediaBuyer);
                if (!isSeller)
                    confs.push(defaultPrintBuyer);
                if (isSeller)
                    confs.push(defaultPrintSeller);
                return [2 /*return*/, confs];
            });
        });
    };
    MessageAggregator.prototype.GetDefaultVentilationNames = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, [
                        TradProp("Campaign", this._objectType)
                    ]];
            });
        });
    };
    MessageAggregator.prototype.GetForcedIndicateurs = function () {
        return {
            'ModelProperties.MediaFamily': this.GetMediaFamilyII(),
            'ModelProperties.AdCreation': GetAdCreationII(),
            'ModelProperties.PlacementCategory': GetPlacementCategoryII(),
            'ModelProperties.Periodicity': this.GetPeriodicityII(),
            'Deversement.Estimate': GetEstimateIIs(),
            'Agency_Res': this.GetAgencyResII()
        };
    };
    MessageAggregator.prototype.MetadataToIndicateurOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dTypes, _a, _b, freeDiscountType, propertyIOs;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = Sort;
                        _b = [ref_DiscountTypes.name];
                        return [4 /*yield*/, this.getDiscountTypes()];
                    case 1:
                        dTypes = _a.apply(void 0, _b.concat([_c.sent()]));
                        freeDiscountType = dTypes.find(function (t) { return t.Name == "Gracieux"; });
                        return [4 /*yield*/, _super.prototype.MetadataToIndicateurOptions.call(this)];
                    case 2:
                        propertyIOs = _c.sent();
                        // Indicateur @rid
                        propertyIOs.push(this.GetModelRidIO());
                        // Indicateur: Type de gracieux
                        propertyIOs.push({
                            indicateur: {
                                type: eIndicateurType.info,
                                name: Trad("Type de gracieux"),
                                valueType: eKPIType.Rid,
                                field: "Discounts.DiscountClass",
                                options: {
                                    match: [{
                                            subProperty: "Discounts",
                                            value: {
                                                DiscountType: freeDiscountType["@rid"]
                                            }
                                        }, {
                                            subProperty: "Discounts",
                                            filter: ePropertyOptionFilter.DiscountNotEmpty
                                        }]
                                }
                            },
                            columnType: eColumnType.Property
                        });
                        // Format Couleur
                        propertyIOs.push(Typed({
                            indicateur: Typed({
                                name: "".concat(Trad("Format"), " ").concat(Trad("Couleur")),
                                valueType: eKPIType.String,
                                type: eIndicateurType.join,
                                indicateurs: propertyIOs.map(function (p) { return p.indicateur; }).filter(function (i) { return i.field == "Format" || i.field == "ModelProperties.Couleur"; })
                            }),
                            columnType: eColumnType.Property
                        }));
                        //Mois fiscal
                        propertyIOs.push(GetFiscalMonthIO());
                        // Groupes Régies
                        if (propertyIOs.some(function (p) { return KPIsManager.AdvCompanyProperties.includes(p.indicateur.field); }))
                            propertyIOs = __spreadArray(__spreadArray([], propertyIOs, true), GetAdvCompanyGroupIOs(), true);
                        return [2 /*return*/, __spreadArray(__spreadArray([], propertyIOs, true), GetTimeIOs(), true)];
                }
            });
        });
    };
    MessageAggregator.prototype.KPIToIndicateurOptions = function (kpiType) {
        return __awaiter(this, void 0, Promise, function () {
            var kpiIOs, campaignKpis;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.KPIToIndicateurOptions.call(this, kpiType)];
                    case 1:
                        kpiIOs = _a.sent();
                        if (!!kpiType) return [3 /*break*/, 3];
                        return [4 /*yield*/, KPIsManagerCache.GetInstance(ref_Campaigns.name).GetUserKPIs()];
                    case 2:
                        campaignKpis = _a.sent();
                        if (campaignKpis.some(function (k) { return k.Name == eKPI.Budget; }))
                            kpiIOs.push(GetCampaignKPI_IOs(eKPI.Budget, { valueType: eKPIType.Price, options: { aggregate: true, groupBy: true } }));
                        if (campaignKpis.some(function (k) { return k.Name == eKPI.Pagination; }))
                            kpiIOs.push(GetCampaignKPI_IOs(eKPI.Pagination, { options: { groupBy: true } }));
                        _a.label = 3;
                    case 3: return [2 /*return*/, kpiIOs];
                }
            });
        });
    };
    MessageAggregator.prototype.BarterToIndicateurOptions = function (barterIndicateur) {
        return __awaiter(this, void 0, Promise, function () {
            var dtBarter, discountsBarter, _a, _b, barter_IOs;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.getDiscountTypes(function (t) { return t.Name == "Barter"; })];
                    case 1:
                        dtBarter = (_c.sent())[0];
                        _a = Sort;
                        _b = [ref_DiscountClasses.name];
                        return [4 /*yield*/, this.getDiscountClasses(function (c) { return c.DiscountType == dtBarter["@rid"]; })];
                    case 2:
                        discountsBarter = _a.apply(void 0, _b.concat([_c.sent()]));
                        barter_IOs = discountsBarter.flatMap(function (d) {
                            var rate_IO = {
                                indicateur: {
                                    type: eIndicateurType.discount,
                                    valueType: eKPIType.Percent,
                                    name: FormatPropertyName("".concat(Trad("Barter"), " ").concat(Trad("rate"), " ").concat(Trad(d.Name))),
                                    options: { rid: d["@rid"], barter: true, value: eDiscountOptionValue.Rate, type: eDiscountOptionType.CO }
                                },
                                columnType: eColumnType.Barter
                            };
                            var amount_IO = {
                                indicateur: Typed({
                                    type: eIndicateurType.computed,
                                    valueType: eKPIType.Price,
                                    name: FormatPropertyName("".concat(Trad("Barter"), " ").concat(Trad("amount"), " ").concat(Trad(d.Name))),
                                    operator: "*",
                                    indicateurs: [
                                        rate_IO.indicateur,
                                        barterIndicateur
                                    ]
                                }),
                                columnType: eColumnType.Barter
                            };
                            return [rate_IO, amount_IO];
                        });
                        return [2 /*return*/, barter_IOs];
                }
            });
        });
    };
    MessageAggregator.prototype.GrossCPMToIndicateurOptions = function (grossCPM_I, gross_I) {
        return __awaiter(this, void 0, Promise, function () {
            var mgrMessage, mgrCampaign, hasPerformances, lnks, indicateurKpi_field, indicateurKpi_subProperty;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        mgrMessage = KPIsManagerCache.GetInstance(ref_Messages.name);
                        mgrCampaign = KPIsManagerCache.GetInstance(ref_Campaigns.name);
                        return [4 /*yield*/, mgrMessage.HasPerformances(null)];
                    case 1:
                        hasPerformances = _a.sent();
                        lnks = [];
                        indicateurKpi_field = "KPIs";
                        indicateurKpi_subProperty = undefined;
                        if (!hasPerformances) return [3 /*break*/, 3];
                        return [4 /*yield*/, mgrMessage.GetCategoryLnkHasKPIs("Performance")];
                    case 2:
                        lnks = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, mgrCampaign.GetCategoryLnkHasKPIs("Performance")];
                    case 4:
                        lnks = _a.sent();
                        indicateurKpi_field = "Campaign";
                        indicateurKpi_subProperty = "KPIs";
                        _a.label = 5;
                    case 5: return [2 /*return*/, Typed({
                            name: grossCPM_I.name,
                            valueType: grossCPM_I.valueType,
                            operator: "/",
                            type: eIndicateurType.computed,
                            options: {
                                rate: 1000,
                                match: [
                                    { subProperty: "IsGrossCPM", value: true },
                                    { subProperty: "KpiCPM", filter: ePropertyOptionFilter.DistinctIsUnique }
                                ]
                            },
                            indicateurs: [
                                gross_I,
                                Typed({
                                    type: eIndicateurType.kpi,
                                    valueType: eKPIType.Number,
                                    name: "",
                                    field: indicateurKpi_field,
                                    options: {
                                        subProperty: indicateurKpi_subProperty,
                                        subPropertyValueAsKey: "KpiCPM",
                                        mapValueTo: lnks.map(function (l) { return ({ key: l.KPI, value: l.Id }); }).reduce(function (acc, cur) {
                                            var _a;
                                            return (__assign(__assign({}, acc), (_a = {}, _a[cur.key] = cur.value, _a)));
                                        }, {})
                                    }
                                })
                            ]
                        })];
                }
            });
        });
    };
    MessageAggregator.prototype.CreateRatioIndicateur = function (title, kpi1, kpi2) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var kpis, lnk_kpis, Kpi1, LnkKpi1, Kpi2, LnkKpi2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetUserKPIs()];
                    case 1:
                        kpis = _c.sent();
                        return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetLnkHasKPIs()];
                    case 2:
                        lnk_kpis = _c.sent();
                        Kpi1 = kpis.find(function (k) { return k.Name === kpi1; });
                        LnkKpi1 = Kpi1 && lnk_kpis.find(function (lnk) { return lnk.KPI === Kpi1["@rid"]; });
                        Kpi2 = kpis.find(function (k) { return k.Name === kpi2; });
                        LnkKpi2 = Kpi2 && lnk_kpis.find(function (lnk) { return lnk.KPI === Kpi2["@rid"]; });
                        return [2 /*return*/, Typed({
                                name: title,
                                valueType: eKPIType.Percent,
                                operator: "%",
                                type: eIndicateurType.computed,
                                indicateurs: [
                                    Typed({
                                        name: "net",
                                        valueType: eKPIType.Price,
                                        type: eIndicateurType.kpi,
                                        field: (_a = LnkKpi1 === null || LnkKpi1 === void 0 ? void 0 : LnkKpi1.Id) !== null && _a !== void 0 ? _a : kpi1,
                                        options: { rid: Kpi1 === null || Kpi1 === void 0 ? void 0 : Kpi1["@rid"] }
                                    }),
                                    Typed({
                                        name: "net",
                                        valueType: eKPIType.Price,
                                        type: eIndicateurType.kpi,
                                        field: (_b = LnkKpi2 === null || LnkKpi2 === void 0 ? void 0 : LnkKpi2.Id) !== null && _b !== void 0 ? _b : kpi2,
                                        options: { rid: Kpi2 === null || Kpi2 === void 0 ? void 0 : Kpi2["@rid"] }
                                    }),
                                ]
                            })];
                }
            });
        });
    };
    return MessageAggregator;
}(AggregatorBase));
export { MessageAggregator };
