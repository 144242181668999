export var propertyDescriptor = function (field, type, isEditable) {
    if (isEditable === void 0) { isEditable = true; }
    return ({
        field: field,
        type: type,
        isEditable: isEditable
    });
};
export var campaignPerformancesProperties = [
    propertyDescriptor("wave", "string"),
    propertyDescriptor("target", "string"),
    /*propertyDescriptor("GRP", "number"),
    propertyDescriptor("contacts", "number"),
    propertyDescriptor("coverage", "number"),
    propertyDescriptor("coverage_thousands", "number"),
    propertyDescriptor("repetition", "number")*/
];
var ref_Campaigns = /** @class */ (function () {
    function ref_Campaigns() {
        this.Attachments = {}; // not in DB !!!
        this.ModelProperties = {};
        this.Performances = {};
    }
    return ref_Campaigns;
}());
export { ref_Campaigns };
