var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ADWGrid, ADWColumn, eRowEvent } from "adwone-lib/index";
import { Client } from "hub-lib/client/client.bin";
import { Trad } from "trad-lib";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { ToEPropType } from "hub-lib/models/KPIsManager.bin";
import { Notify } from "../../../../utils/Notify.bin";
import { IndicateurToString } from "adwone-engine/index.bin";
import { store } from "../../../../redux/store";
import { AddDevModeColumns } from "../AddDevModeColumns";
import { clone, GetHashCode, GetSubElement, groupBy } from "hub-lib/tools.bin";
import { ConsoleDebug } from "../../../../utils/localstorage.bin";
import { setTable } from "../../../../redux/gridConfigurationsSlice";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory";
var GridBase = /** @class */ (function (_super) {
    __extends(GridBase, _super);
    function GridBase(props) {
        var _this = _super.call(this) || this;
        _this.hiddenProperties = ["Active"];
        _this.objectPrototype = void 0;
        _this.props = void 0;
        _this.InitializeColumns = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this$props, _this$props$configura, _this$props$configura2, columns, _this$props2, _this$props2$configur, _await$AggregatorFact, _AggregatorFactory$Ge, indicateursConf, allIndicateurs, _loop_1, this_1, _this$props$columns, _i, indicateursConf_1, indiConf, configuration, _configuration$Frozen, tpsCols;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        columns = [];
                        if (!(((_this$props = this.props) === null || _this$props === void 0 ? void 0 : (_this$props$configura = _this$props.configuration) === null || _this$props$configura === void 0 ? void 0 : (_this$props$configura2 = _this$props$configura.Columns) === null || _this$props$configura2 === void 0 ? void 0 : _this$props$configura2.length) > 0)) return [3 /*break*/, 2];
                        indicateursConf = (_this$props2 = this.props) === null || _this$props2 === void 0 ? void 0 : (_this$props2$configur = _this$props2.configuration) === null || _this$props2$configur === void 0 ? void 0 : _this$props2$configur.Columns;
                        return [4 /*yield*/, ((_AggregatorFactory$Ge = AggregatorFactory.GetInstance().Get(this.objectPrototype)) === null || _AggregatorFactory$Ge === void 0 ? void 0 : _AggregatorFactory$Ge.Provide())];
                    case 1:
                        allIndicateurs = (_await$AggregatorFact = _a.sent()) === null || _await$AggregatorFact === void 0 ? void 0 : _await$AggregatorFact.map(function (i) { return i.indicateur; });
                        _loop_1 = function (indiConf) {
                            var indicateur = allIndicateurs === null || allIndicateurs === void 0 ? void 0 : allIndicateurs.find(function (i) { return IndicateurToString(i) == IndicateurToString(indiConf); });
                            if (indicateur) {
                                indiConf.name = indicateur.name;
                            }
                            var overridenCol = (_this$props$columns = this_1.props.columns) === null || _this$props$columns === void 0 ? void 0 : _this$props$columns.find(function (c) { return c.title == indiConf.name; });
                            if (overridenCol) {
                                var overrideColClone = clone(overridenCol);
                                overrideColClone.baseColumn = indiConf;
                                columns.push(overrideColClone);
                                return "continue";
                            }
                            var colKPI = new ADWColumn(indiConf.name, IndicateurToString(indiConf), ToEPropType(indiConf.valueType));
                            colKPI.baseColumn = indiConf;
                            colKPI.isKPI = true;
                            columns.push(colKPI);
                            this_1.ApplyWidth(colKPI);
                        };
                        this_1 = this;
                        for (_i = 0, indicateursConf_1 = indicateursConf; _i < indicateursConf_1.length; _i++) {
                            indiConf = indicateursConf_1[_i];
                            _loop_1(indiConf);
                        }
                        _a.label = 2;
                    case 2:
                        AddDevModeColumns(columns); // if confuguration is defined, we use it to add frozen columns
                        configuration = this.props.configuration;
                        if (configuration) {
                            columns.forEach(function (c) { return c.frozen = undefined; });
                            columns.slice(0, (_configuration$Frozen = configuration.FrozenPosition) !== null && _configuration$Frozen !== void 0 ? _configuration$Frozen : 0).forEach(function (c) { return c.frozen = "left"; });
                        }
                        columns.forEach(function (c) { var _GetHashCode; var id = c.baseColumn ? (_GetHashCode = GetHashCode(IndicateurToString(c.baseColumn))) === null || _GetHashCode === void 0 ? void 0 : _GetHashCode.toString() : undefined; var field = "_computed.".concat(id); c.field = field; c.indicateurHash = id; });
                        tpsCols = groupBy(columns.filter(function (c) { var _c$baseColumn; return (_c$baseColumn = c.baseColumn) === null || _c$baseColumn === void 0 ? void 0 : _c$baseColumn.headerGroup; }), function (c) { return c.baseColumn.headerGroup; });
                        Object.entries(tpsCols).forEach(function (_ref) { var key = _ref[0], value = _ref[1]; var parentTp = new ADWColumn(key, ""); parentTp.Children = clone(value); parentTp.Children.forEach(function (c) { return c.title = c.title.replace(key, "").trim(); }); columns.push(parentTp); columns = columns.filter(function (c) { return !value.includes(c); }); });
                        console.log("[columns]", columns);
                        this.Columns = columns;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.UpdateRowsFromServer = function () { return __awaiter(_this, void 0, void 0, function () { var _this$props3, _res$data, time1379, res, rows, _time1379; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    time1379 = new Date().getTime();
                    return [4 /*yield*/, Client.searchVertex(this.objectPrototype.name, (_this$props3 = this.props) === null || _this$props3 === void 0 ? void 0 : _this$props3.vertexParams)];
                case 1:
                    res = _a.sent();
                    rows = res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.results;
                    _time1379 = new Date().getTime();
                    console.log("[PERF] searchVertex Elapsed ".concat(_time1379 - time1379, "ms"));
                    return [2 /*return*/, { rows: rows, totalRow: null }];
            }
        }); }); };
        _this.lastRequestId = void 0;
        _this.UpdateRows = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this$props$adapt, _this$props4, requestId, rows, totalRow, res, time9883, _time9883, error_1, time2512, _time2512, time9789, _time9789;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onRowsChanged.emit(eRowEvent.loading);
                        if (this.props.rows) {
                            this.Rows = this.props.rows;
                            return [2 /*return*/];
                        }
                        requestId = Date.now();
                        this.lastRequestId = requestId;
                        rows = [];
                        totalRow = null;
                        if (!!this.props.rows) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.UpdateRowsFromServer()];
                    case 2:
                        res = _a.sent();
                        rows = res.rows;
                        totalRow = res.totalRow;
                        time9883 = new Date().getTime();
                        if (!this.props.afterSearch) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.props.afterSearch(rows)];
                    case 3:
                        rows = _a.sent();
                        _a.label = 4;
                    case 4:
                        _time9883 = new Date().getTime();
                        ConsoleDebug("[PERF] afterSearch Elapsed ".concat(_time9883 - time9883, "ms"));
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        if ((error_1 === null || error_1 === void 0 ? void 0 : error_1['message']) != "Cancel")
                            Notify(Trad("cannot_get_elements"), "error");
                        console.error(error_1);
                        return [3 /*break*/, 6];
                    case 6:
                        if (this.lastRequestId != requestId)
                            return [2 /*return*/];
                        time2512 = new Date().getTime();
                        if (this.props.filterRows)
                            rows = rows.filter(this.props.filterRows);
                        _time2512 = new Date().getTime();
                        ConsoleDebug("[PERF] filterRows Elapsed ".concat(_time2512 - time2512, "ms"));
                        time9789 = new Date().getTime();
                        return [4 /*yield*/, this.adapt(rows, this.Columns)];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, Promise.resolve((_this$props$adapt = (_this$props4 = this.props).adapt) === null || _this$props$adapt === void 0 ? void 0 : _this$props$adapt.call(_this$props4, rows, this.Columns))];
                    case 8:
                        _a.sent();
                        _time9789 = new Date().getTime();
                        ConsoleDebug("[PERF] adapt Elapsed ".concat(_time9789 - time9789, "ms"));
                        this.TotalRow = totalRow;
                        if (!this.props.emptyGrid)
                            this.Rows = rows;
                        else
                            this.Rows = [];
                        return [2 /*return*/];
                }
            });
        }); };
        _this.adapt = function (rows, columns) {
            if (!(rows !== null && rows !== void 0 && rows.length) || !(columns !== null && columns !== void 0 && columns.length))
                return; // check les colonnes de dates
            var dateColumns = columns.filter(function (c) { return c.dataType === ePropType.Date || c.dataType === ePropType.Datetime; });
            if ((dateColumns === null || dateColumns === void 0 ? void 0 : dateColumns.length) > 0) {
                rows.forEach(function (m) { dateColumns.forEach(function (c) { var data = GetSubElement(m, c.field); if (data)
                    data.Value = new Date(data.Value); }); });
            }
        };
        _this.exportExcel = function (exportType, args) {
            var exportColumns = _this.Columns.flatMap(function (c) { var _c$Children; if ((_c$Children = c.Children) !== null && _c$Children !== void 0 && _c$Children.length)
                return c.Children.map(function (cc) { return cc.baseColumn; }).filter(function (c) { return c; }); return [c.baseColumn]; }).filter(function (c) { return c; });
            var arg = __assign(__assign({}, args), { type: "table", document: _this.objectPrototype.name, filter: __assign(__assign({}, _this.props.vertexParams), (args && { Start: args.Start, End: args.End })), columnsGeneration: (exportColumns === null || exportColumns === void 0 ? void 0 : exportColumns.length) > 0 ? "fromData" : "fromMetadata", columns: exportColumns });
            return Client.downloadExport(exportType, arg, Trad(_this.objectPrototype.name));
        };
        _this.objectPrototype = props.objectPrototype;
        _this.props = props;
        if (props.hiddenProperties)
            _this.hiddenProperties = _this.hiddenProperties.concat(props.hiddenProperties); //this.Initialize();
        return _this;
        //this.InitializeColumns();
    }
    GridBase.prototype.create = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, true];
            });
        });
    };
    GridBase.prototype.update = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, true];
            });
        });
    };
    GridBase.prototype.delete = function (rows) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, true];
            });
        });
    };
    GridBase.prototype.validator = function (vertex, errors, row) {
        return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!this.props.validator) return [3 /*break*/, 2];
                    return [4 /*yield*/, this.props.validator(vertex, errors, row)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        }); });
    };
    GridBase.prototype.rowToObjectAfter = function (object, row) {
    };
    GridBase.prototype.Initialize = function () {
        return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, this.InitializeColumns()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, this.UpdateRows()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        }); });
    };
    GridBase.prototype.ApplyWidth = function (column) { var _this$props$width, _column$baseColumn$fi, _column$baseColumn; var overrideWidth = (_this$props$width = this.props.width) === null || _this$props$width === void 0 ? void 0 : _this$props$width[(_column$baseColumn$fi = (_column$baseColumn = column.baseColumn) === null || _column$baseColumn === void 0 ? void 0 : _column$baseColumn.field) !== null && _column$baseColumn$fi !== void 0 ? _column$baseColumn$fi : column.bindingPath]; if (overrideWidth != undefined)
        column.width = overrideWidth; };
    GridBase.prototype.createData = function () { var object = new this.objectPrototype(); if (this.props.initializePrototype) {
        this.props.initializePrototype(object);
    } return object; };
    GridBase.prototype.rowToObject = function (row) { var _this$props5, _this$props5$rowToVer; var newObj = {}; this.rowToObjectAfter(newObj, row); (_this$props5 = this.props) === null || _this$props5 === void 0 ? void 0 : (_this$props5$rowToVer = _this$props5.rowToVertex) === null || _this$props5$rowToVer === void 0 ? void 0 : _this$props5$rowToVer.call(_this$props5, row, newObj); return newObj; }; /**
         * Reorder columns depending on UI changes
         * @param columnIds
         */
    GridBase.prototype.onReorder = function (columnIds) {
        return __awaiter(this, void 0, void 0, function () {
            var _this$props6, conf;
            return __generator(this, function (_a) {
                conf = (_this$props6 = this.props) === null || _this$props6 === void 0 ? void 0 : _this$props6.configuration;
                if (conf && !conf.Default) {
                    conf.Columns = __spreadArray([], conf.Columns, true).sort(function (a, b) { var i1 = columnIds.indexOf(IndicateurToString(a)); var i2 = columnIds.indexOf(IndicateurToString(b)); if (i1 > -1 && i2 > -1)
                        return i1 - i2; return 0; });
                    store.dispatch(setTable(conf));
                } //await this.InitializeColumns();
                return [2 /*return*/];
            });
        });
    };
    return GridBase;
}(ADWGrid));
export { GridBase };
