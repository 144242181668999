var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AggregatorFactory } from 'hub-lib/aggregator/AggregatorFactory';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { clone, toArray, toDictionary, toDictionaryList } from 'hub-lib/tools.bin';
import { ToRefMessage, updateMessage } from './messageEditorSlice';
import { store } from './store';
var initialState = { messagesEditing: [], cellsEditingDico: {}, cellsEditing: [] };
export var setMessageEditing = createAsyncThunk('inline-editor/setMessageEditing', function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _state$inlineEditor$m, state, current, newData, _i, newData_1, d, willUpdateMessage, message, newDataIds;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkAPI.getState();
                current = (_state$inlineEditor$m = state.inlineEditor.messagesEditing) !== null && _state$inlineEditor$m !== void 0 ? _state$inlineEditor$m : [];
                newData = toArray(data);
                _i = 0, newData_1 = newData;
                _a.label = 1;
            case 1:
                if (!(_i < newData_1.length)) return [3 /*break*/, 4];
                d = newData_1[_i];
                willUpdateMessage = d.indicateurName && AggregatorFactory.GetInstance().Get(ref_Messages).GetEditableColumnsFullObject().includes(d.indicateurName);
                console.log("[setMessageEditing] willUpdateMessage", d, willUpdateMessage);
                if (!willUpdateMessage) return [3 /*break*/, 3];
                return [4 /*yield*/, updateMessage(d.previous, d.current)];
            case 2:
                message = (_a.sent()).message;
                d.current = ToRefMessage(message);
                _a.label = 3;
            case 3:
                _i++;
                return [3 /*break*/, 1];
            case 4:
                newDataIds = newData.map(function (m) { return m.current['@rid']; });
                try {
                    return [2 /*return*/, { messagesEditing: __spreadArray(__spreadArray([], current.filter(function (m) { return !newDataIds.includes(m['@rid']); }), true), newData.map(function (m) { return m.current; }), true) }];
                }
                catch (err) {
                    return [2 /*return*/, thunkAPI.rejectWithValue(err)];
                }
                return [2 /*return*/];
        }
    });
}); });
export var flush = createAsyncThunk('inline-editor/flush', function (selectedItems, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, _a, messagesEditing, cellsEditing, cellsEditingDico; return __generator(this, function (_b) {
    state = thunkAPI.getState();
    _a = state.inlineEditor, messagesEditing = _a.messagesEditing, cellsEditing = _a.cellsEditing, cellsEditingDico = _a.cellsEditingDico;
    messagesEditing = messagesEditing.filter(function (m) { return cellsEditingDico[m['@rid']] || selectedItems.includes(m['@rid']); });
    return [2 /*return*/, { messagesEditing: messagesEditing, cellsEditing: cellsEditing, cellsEditingDico: cellsEditingDico }];
}); }); });
export var reset = createAsyncThunk('inline-editor/reset', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var _store$getState$grid$, _store$getState$grid, _store$getState$grid$2, messagesEditing, selectedRids; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            messagesEditing = [];
            selectedRids = (_store$getState$grid$ = (_store$getState$grid = store.getState().grid) === null || _store$getState$grid === void 0 ? void 0 : (_store$getState$grid$2 = _store$getState$grid.selectedItems) === null || _store$getState$grid$2 === void 0 ? void 0 : _store$getState$grid$2.map(function (i) { var _i$dataItem; return i === null || i === void 0 ? void 0 : (_i$dataItem = i.dataItem) === null || _i$dataItem === void 0 ? void 0 : _i$dataItem["@rid"]; })) !== null && _store$getState$grid$ !== void 0 ? _store$getState$grid$ : [];
            if (!(selectedRids.length > 0)) return [3 /*break*/, 2];
            return [4 /*yield*/, Client.searchVertexTyped(ref_Messages, { "@rid": selectedRids })];
        case 1:
            messagesEditing = _a.sent();
            _a.label = 2;
        case 2: return [2 /*return*/, { messagesEditing: messagesEditing, cellsEditing: [], cellsEditingDico: {} }];
    }
}); }); });
export var saveMessageEditing = createAsyncThunk('inline-editor/saveMessageEditing', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _state$inlineEditor$m2, state, current, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkAPI.getState();
                current = (_state$inlineEditor$m2 = state.inlineEditor.messagesEditing) !== null && _state$inlineEditor$m2 !== void 0 ? _state$inlineEditor$m2 : [];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, Client.updateVertex(ref_Messages.name, toArray(current))];
            case 2:
                _a.sent();
                Client.log({ Category: ref_Messages.name, Action: 'update from inline editor', Informations: { count: toArray(current).length, data: toArray(current) } });
                return [2 /*return*/, { messagesEditing: [], cellsEditing: [], cellsEditingDico: {} }];
            case 3:
                err_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(err_1)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var inlineEditorSlice = createSlice({ name: 'inline-editor', initialState: initialState, reducers: { resetActions: function (state) { state.cellsEditing.forEach(function (c) { return c.action = undefined; }); var dico = {}; var dicoByRidMessage = toDictionaryList(clone(state.cellsEditing), function (e) { return e['@rid']; }); Object.entries(dicoByRidMessage).forEach(function (_ref) { var rid = _ref[0], cells = _ref[1]; dico[rid] = toDictionary(cells, function (e) { return e.indicateurString; }); }); state.cellsEditingDico = dico; },
        //     state.messagesEditing = [];
        //     state.cellsEditing = [];
        //     state.cellsEditingDico = {};
        // },
        setCellEditing: function (state, action) { var arrayPayload = toArray(action.payload); var _loop_1 = function (p) {
            if (!state.cellsEditing.some(function (c) { return c['@rid'] == p['@rid'] && c.indicateurString == p.indicateurString; }))
                state.cellsEditing.push(p);
        }; for (var _i = 0, arrayPayload_1 = arrayPayload; _i < arrayPayload_1.length; _i++) {
            var p = arrayPayload_1[_i];
            _loop_1(p);
        } var dico = {}; var dicoByRidMessage = toDictionaryList(clone(state.cellsEditing), function (e) { return e['@rid']; }); Object.entries(dicoByRidMessage).forEach(function (_ref2) { var rid = _ref2[0], cells = _ref2[1]; dico[rid] = toDictionary(cells, function (e) { return e.indicateurString; }); }); state.cellsEditingDico = dico; } // setDataSync: (state, action: PayloadAction<Partial<OfferState['data']>>) => {
        //     console.log("[setDataSync]", action.payload);
        //     const currentData = clone(state.data ?? {}) as any;
        //     Object.assign(currentData, action.payload);
        //     state.data = currentData;
        // }
    }, extraReducers: function (builder) { var fulfilledCallback = function (state, action) { var _action$payload, _action$payload2, _action$payload3; if ((_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.messagesEditing)
        state.messagesEditing = action.payload.messagesEditing; if ((_action$payload2 = action.payload) !== null && _action$payload2 !== void 0 && _action$payload2.cellsEditing)
        state.cellsEditing = action.payload.cellsEditing; if ((_action$payload3 = action.payload) !== null && _action$payload3 !== void 0 && _action$payload3.cellsEditingDico)
        state.cellsEditingDico = action.payload.cellsEditingDico; }; builder.addCase(setMessageEditing.fulfilled, fulfilledCallback); builder.addCase(saveMessageEditing.fulfilled, fulfilledCallback); builder.addCase(reset.fulfilled, fulfilledCallback); builder.addCase(flush.fulfilled, fulfilledCallback); } });
export var setCellEditing = (_a = inlineEditorSlice.actions, _a.setCellEditing), resetActions = _a.resetActions;
export var inlineEditorReducer = inlineEditorSlice.reducer;
