var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { ADWGrid, ADWColumn, eRowEvent, eComputeEvent } from "adwone-lib/index";
import { Client } from "hub-lib/client/client.bin";
import { Trad, TradProvider, TradValue } from "trad-lib";
import { Format, GetCellTemplate, GetKPITemplate } from "format-lib/index.bin";
import { ePropType, IsLink } from "hub-lib/models/VertexProperty.bin";
import { PropertiesProvider } from "./PropertiesPovider.bin";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { ConsoleDebug, IsDebugMode } from "../../../utils/localstorage.bin";
import { eKPIType, ToEPropType } from "hub-lib/models/KPIsManager.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { Notify } from "../../../utils/Notify.bin";
import { CreateIndicateur, eIndicateurType, IndicateurToString } from "adwone-engine/index.bin";
import { AggregatorFactory } from 'hub-lib/aggregator/AggregatorFactory';
import { IsIDDB, clone, GetSubElement } from "hub-lib/tools.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { EngineTools, FormatCellsMemoized } from "adwone-engine/EngineTools";
import { User } from "hub-lib/models/orientdb/User.bin";
import { store } from "../../../redux/store";
import { AddDevModeColumns } from "./AddDevModeColumns";
import { setTable } from "../../../redux/gridConfigurationsSlice";
var widths = (_a = {}, _a[ePropType.Date] = 155, _a[ePropType.Datetime] = 155, _a);
var dicoNotifColumnChanged = {};
var GridBase = /** @class */ (function (_super) {
    __extends(GridBase, _super);
    function GridBase(props) {
        var _this = _super.call(this) || this;
        _this.metadata = void 0;
        _this.hiddenProperties = ["Active"];
        _this.objectPrototype = void 0;
        _this.properties = void 0;
        _this.props = void 0; //vertexParamsSaved?: any;
        _this.devMode = false;
        _this.additionnalColumns = void 0;
        _this.InitializeComponent = function (_callback) { return __awaiter(_this, void 0, void 0, function () { var finalConf, error_1; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!this.props.configuration) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, this.GetDefaultColumnConfiguration()];
                case 2:
                    finalConf = _a.sent();
                    if (this.props.customConfCol)
                        this.props.configuration = finalConf;
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    Notify(Trad('cannot_get_defaut_configuration'), "error");
                    console.log('Cannot get default configuration.', error_1);
                    return [3 /*break*/, 4];
                case 4: return [4 /*yield*/, this.Initialize()];
                case 5:
                    _a.sent();
                    _callback === null || _callback === void 0 ? void 0 : _callback();
                    return [2 /*return*/];
            }
        }); }); };
        _this.getTitle = function (propname) { var _this$props$titles$pr, _this$props, _this$props$titles; return (_this$props$titles$pr = (_this$props = _this.props) === null || _this$props === void 0 ? void 0 : (_this$props$titles = _this$props.titles) === null || _this$props$titles === void 0 ? void 0 : _this$props$titles[propname]) !== null && _this$props$titles$pr !== void 0 ? _this$props$titles$pr : TradProvider.GetInstance().Trad({ Type: "static", Class: _this.objectPrototype.name, Property: propname }); };
        _this.InitPropertiesProvdier = function () { if (!_this.props.propertiesProvider)
            _this.props.propertiesProvider = new PropertiesProvider(function () { return _this.props.configuration; }); };
        _this.CreateColumnFromProperty = function (p) {
            var _this$props$readOnlyP, _this$props$frozenLef, _this$props$columnPar;
            var column = new ADWColumn(_this.getTitle(p.name), p.name, p.type, !((_this$props$readOnlyP = _this.props.readOnlyProperties) !== null && _this$props$readOnlyP !== void 0 && _this$props$readOnlyP.includes(p.name)));
            column.baseColumn.options = p.options;
            column.width = widths[p.type];
            if ((_this$props$frozenLef = _this.props.frozenLeft) !== null && _this$props$frozenLef !== void 0 && _this$props$frozenLef.includes(p.name))
                column.frozen = "left";
            _this.ApplyWidth(column);
            if (IsLink(p.type)) {
                var _this$props2, _this$props2$customCe;
                var customCell = (_this$props2 = _this.props) === null || _this$props2 === void 0 ? void 0 : (_this$props2$customCe = _this$props2.customCellValue) === null || _this$props2$customCe === void 0 ? void 0 : _this$props2$customCe[p.name];
                column.cellValue = customCell !== null && customCell !== void 0 ? customCell : function (value, row) {
                    var _p$options, _p$options2;
                    if (!(row !== null && row !== void 0 && row.dataItem))
                        return value;
                    var propInfo = EngineTools.BuildLinkPropertyInfo(p.name, p.options);
                    var formated = row.dataItem[propInfo.alias];
                    if ((_p$options = p.options) !== null && _p$options !== void 0 && _p$options.match)
                        value = GetSubElement(row.dataItem, p.name, (_p$options2 = p.options) === null || _p$options2 === void 0 ? void 0 : _p$options2.match);
                    if (!Array.isArray(value))
                        value = [value];
                    return value.map(function (val) {
                        var _p$options3, _p$options4, _row$dataItem$propInf; // TRICK
                        if (typeof val == 'string' && (_p$options3 = p.options) !== null && _p$options3 !== void 0 && _p$options3.priorityToField && (!IsIDDB(val) || val == "#-1:-1"))
                            return GetSubElement(row.dataItem, p.options.priorityToField);
                        if (formated && !((_p$options4 = p.options) !== null && _p$options4 !== void 0 && _p$options4.match))
                            return typeof formated == "string" ? TradValue(p.linkedClass, propInfo.property, formated) : formated;
                        if (propInfo.fallbackAlias)
                            return (_row$dataItem$propInf = row.dataItem[propInfo.fallbackAlias]) !== null && _row$dataItem$propInf !== void 0 ? _row$dataItem$propInf : formated;
                        var storedElements = _this.store[p.name];
                        if (storedElements === undefined && val) {
                            _this.InitStore(p.name).then(function (values) { var _row$event, _row$event$emit; if (values !== null && values !== void 0 && values.find(function (v) { return v["@rid"] === val; }))
                                row === null || row === void 0 ? void 0 : (_row$event = row.event) === null || _row$event === void 0 ? void 0 : (_row$event$emit = _row$event.emit) === null || _row$event$emit === void 0 ? void 0 : _row$event$emit.call(_row$event, eComputeEvent.forceUpdate, { field: p.name }); });
                            return "...";
                        }
                        var storedElement = storedElements === null || storedElements === void 0 ? void 0 : storedElements.find(function (v) { return v["@rid"] === val; });
                        var ret = storedElement ? Format(storedElement) : val;
                        return ret;
                    }).join("|");
                };
            }
            var override = (_this$props$columnPar = _this.props.columnParams) === null || _this$props$columnPar === void 0 ? void 0 : _this$props$columnPar[p.name];
            if (override) {
                Object.entries(override).forEach(function (e) { column[e[0]] = e[1]; });
            }
            return column;
        };
        _this.GetAdditionnalColumns = function () { var toAdd = []; if (_this.additionnalColumns) {
            toAdd = __spreadArray([], _this.additionnalColumns, true);
            if (_this.props.configuration) {
                toAdd = _this.props.configuration.Columns.map(function (c) { return toAdd.find(function (t) { return t.title === c.name || t.bindingPath === c.field; }); }).filter(function (t) { return t; });
                toAdd.forEach(function (column) { var _this$props$frozenLef2, _this$props$width; if ((_this$props$frozenLef2 = _this.props.frozenLeft) !== null && _this$props$frozenLef2 !== void 0 && _this$props$frozenLef2.includes(column.bindingPath))
                    column.frozen = "left"; var overrideWidth = (_this$props$width = _this.props.width) === null || _this$props$width === void 0 ? void 0 : _this$props$width[column.bindingPath]; if (overrideWidth != undefined)
                    column.width = overrideWidth; });
            }
        } return toAdd; };
        _this.EnableDevMode = function () { if (!_this.devMode)
            _this.devMode = IsDebugMode(); };
        _this.currencies = void 0;
        _this.InitializeColumns = function () { return __awaiter(_this, void 0, void 0, function () {
            var _await$Client$getMeta, _await$Client$getMeta2, _this$properties, _this$props3, _this$props3$vertexPa, _this$props3$vertexPa2, _this$props4, _this$props4$configur, _this$props4$configur2, _a, _b, defaultcolumns, disableStore, _this$properties2, _this$properties3, propsLinks, _i, propsLinks_1, p, _res$data, res, error_2, columns, _this$props5, _this$props5$configur, allIndicateurs, _c, indicateursConf, tmpCols, _loop_1, this_1, _indiConf$options, _columnKpiOverride$bi, _columnKpiOverride$da, _indiConf$options$Met, _indiConf$options2, _indiConf$options2$Me, _this$props$columnDec, _d, indicateursConf_1, indiConf, configuration, _configuration$Frozen;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        //this.props.frozenLeft = configuration ? configuration.Columns.slice(0, configuration.FrozenPosition ?? 0).map(c => c.field as any) : [];
                        this.InitPropertiesProvdier();
                        _a = this;
                        return [4 /*yield*/, Client.getMetadata(this.objectPrototype.name, true)];
                    case 1:
                        _a.metadata = (_await$Client$getMeta = _e.sent()) === null || _await$Client$getMeta === void 0 ? void 0 : (_await$Client$getMeta2 = _await$Client$getMeta.data) === null || _await$Client$getMeta2 === void 0 ? void 0 : _await$Client$getMeta2.results;
                        _b = this;
                        return [4 /*yield*/, this.props.propertiesProvider.Provide(this.objectPrototype.name)];
                    case 2:
                        _b.properties = _e.sent();
                        defaultcolumns = (_this$properties = this.properties) === null || _this$properties === void 0 ? void 0 : _this$properties.filter(function (p) { return !_this.hiddenProperties.includes(p.name) && !p.name.startsWith('ModelProperties.'); }).map(this.CreateColumnFromProperty).filter(function (c) { return c; });
                        disableStore = [];
                        if ((_this$props3 = this.props) !== null && _this$props3 !== void 0 && (_this$props3$vertexPa = _this$props3.vertexParams) !== null && _this$props3$vertexPa !== void 0 && (_this$props3$vertexPa2 = _this$props3$vertexPa.properties) !== null && _this$props3$vertexPa2 !== void 0 && _this$props3$vertexPa2.length) {
                            (_this$properties2 = this.properties) === null || _this$properties2 === void 0 ? void 0 : _this$properties2.filter(function (p) { return IsLink(p.type) && !_this.hiddenProperties.includes(p.name); }).forEach(function (p) { disableStore.push(p.name); EngineTools.BuildLinkPropertyParams(p.name, p.options).forEach(function (p) { if (!_this.props.vertexParams.properties.includes(p))
                                _this.props.vertexParams.properties.push(p); }); });
                        }
                        if (!!this.props.disableStore) return [3 /*break*/, 8];
                        propsLinks = (_this$properties3 = this.properties) === null || _this$properties3 === void 0 ? void 0 : _this$properties3.filter(function (p) { return IsLink(p.type) && !_this.hiddenProperties.includes(p.name); }).filter(function (p) { return !disableStore.includes(p.name); });
                        _i = 0, propsLinks_1 = propsLinks;
                        _e.label = 3;
                    case 3:
                        if (!(_i < propsLinks_1.length)) return [3 /*break*/, 8];
                        p = propsLinks_1[_i];
                        _e.label = 4;
                    case 4:
                        _e.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, Client.searchVertex(p.linkedClass)];
                    case 5:
                        res = _e.sent();
                        this.store[p.name] = res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.results;
                        return [3 /*break*/, 7];
                    case 6:
                        error_2 = _e.sent();
                        console.error(error_2);
                        return [3 /*break*/, 7];
                    case 7:
                        _i++;
                        return [3 /*break*/, 3];
                    case 8:
                        columns = defaultcolumns.concat(this.GetAdditionnalColumns());
                        if (this.props.order) {
                            columns.forEach(function (c) { return c.indexOrder = _this.props.order.indexOf(c.bindingPath); });
                            columns = columns.sort(function (a, b) { return a.indexOrder - b.indexOrder; });
                        }
                        this.EnableDevMode();
                        if (!(((_this$props4 = this.props) === null || _this$props4 === void 0 ? void 0 : (_this$props4$configur = _this$props4.configuration) === null || _this$props4$configur === void 0 ? void 0 : (_this$props4$configur2 = _this$props4$configur.Columns) === null || _this$props4$configur2 === void 0 ? void 0 : _this$props4$configur2.length) > 0)) return [3 /*break*/, 15];
                        return [4 /*yield*/, AggregatorFactory.GetInstance().Get(ref_Messages).Provide()];
                    case 9:
                        allIndicateurs = _e.sent();
                        _c = this;
                        return [4 /*yield*/, Client.get(ref_Currencies)];
                    case 10:
                        _c.currencies = (_e.sent()).data.results;
                        indicateursConf = (_this$props5 = this.props) === null || _this$props5 === void 0 ? void 0 : (_this$props5$configur = _this$props5.configuration) === null || _this$props5$configur === void 0 ? void 0 : _this$props5$configur.Columns;
                        tmpCols = [];
                        _loop_1 = function (indiConf) {
                            var indiInstance, property, columnRid, columnBase, cloneColunm, columnKpiOverride, colKPI, found, decorator;
                            return __generator(this, function (_f) {
                                switch (_f.label) {
                                    case 0:
                                        indiInstance = CreateIndicateur(indiConf);
                                        if (indiConf.field && !((_indiConf$options = indiConf.options) !== null && _indiConf$options !== void 0 && _indiConf$options['formater'])) {
                                            if (indiConf.valueType === eKPIType.Rid) {
                                                property = this_1.metadata.find(function (p) { return p.name == indiConf.field; });
                                                if ((property === null || property === void 0 ? void 0 : property.linkedClass) != User.name) {
                                                    columnRid = this_1.CreateColumnFromProperty({ name: (_indiConf$options$Met = indiConf === null || indiConf === void 0 ? void 0 : (_indiConf$options2 = indiConf.options) === null || _indiConf$options2 === void 0 ? void 0 : (_indiConf$options2$Me = _indiConf$options2["MetaData"]) === null || _indiConf$options2$Me === void 0 ? void 0 : _indiConf$options2$Me["name"]) !== null && _indiConf$options$Met !== void 0 ? _indiConf$options$Met : indiConf.field, type: ePropType.Link, linkedClass: property === null || property === void 0 ? void 0 : property.linkedClass, options: indiConf.options });
                                                    columnRid.title = indiConf.name;
                                                    this_1.ApplyWidth(columnRid);
                                                    tmpCols.push(columnRid);
                                                    return [2 /*return*/, "continue"];
                                                }
                                            }
                                            columnBase = columns.find(function (col) { return col.bindingPath === indiConf.field; });
                                            if (columnBase) {
                                                cloneColunm = clone(columnBase);
                                                cloneColunm.title = indiConf.name;
                                                cloneColunm.baseColumn = indiConf;
                                                this_1.ApplyWidth(cloneColunm);
                                                tmpCols.push(cloneColunm);
                                                return [2 /*return*/, "continue"];
                                            }
                                        }
                                        columnKpiOverride = columns.find(function (col) { return col.title === indiConf.name; });
                                        colKPI = new ADWColumn(indiConf.name, (_columnKpiOverride$bi = columnKpiOverride === null || columnKpiOverride === void 0 ? void 0 : columnKpiOverride.bindingPath) !== null && _columnKpiOverride$bi !== void 0 ? _columnKpiOverride$bi : IndicateurToString(indiConf), (_columnKpiOverride$da = columnKpiOverride === null || columnKpiOverride === void 0 ? void 0 : columnKpiOverride.dataType) !== null && _columnKpiOverride$da !== void 0 ? _columnKpiOverride$da : ToEPropType(indiConf.valueType));
                                        colKPI.baseColumn = indiConf;
                                        colKPI.isKPI = true;
                                        colKPI.getKPIValue = function (rowData) { return __awaiter(_this, void 0, void 0, function () {
                                            var value;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, indiInstance.Compute([rowData])];
                                                    case 1:
                                                        value = _a.sent();
                                                        if (indiInstance.valueType === eKPIType.Percent && (indiInstance.type === eIndicateurType.computed || indiInstance.type === eIndicateurType.discount))
                                                            value *= 100;
                                                        return [2 /*return*/, value]; // old way return rowData.KPIs[colKPI.bindingPath];
                                                }
                                            });
                                        }); };
                                        colKPI.cellValue = function (cellValue, dataItem) { return __awaiter(_this, void 0, void 0, function () {
                                            var _formatedCell$Formate, ind, res, cellVal, formatedCell, value;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!colKPI.baseColumn) return [3 /*break*/, 3];
                                                        ind = CreateIndicateur(colKPI.baseColumn);
                                                        return [4 /*yield*/, ind.Compute([dataItem.dataItem])];
                                                    case 1:
                                                        res = _a.sent();
                                                        cellVal = [{ Value: res, IndicateurSignature: IndicateurToString(ind), Type: 'cell', Formated: '' }];
                                                        return [4 /*yield*/, FormatCellsMemoized(ref_Messages.name, ind, cellVal)];
                                                    case 2:
                                                        formatedCell = (_a.sent())[0];
                                                        return [2 /*return*/, (_formatedCell$Formate = formatedCell === null || formatedCell === void 0 ? void 0 : formatedCell.Formated) !== null && _formatedCell$Formate !== void 0 ? _formatedCell$Formate : ''];
                                                    case 3:
                                                        if (columnKpiOverride)
                                                            return [2 /*return*/, columnKpiOverride.cellValue(cellValue, dataItem)];
                                                        return [4 /*yield*/, colKPI.getKPIValue(dataItem.dataItem)];
                                                    case 4:
                                                        value = _a.sent();
                                                        return [2 /*return*/, GetCellTemplate(colKPI.dataType)(value)];
                                                }
                                            });
                                        }); };
                                        colKPI.getValue = function (rowData) { return __awaiter(_this, void 0, void 0, function () { var val, template, currency; return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, colKPI.getKPIValue(rowData)];
                                                case 1:
                                                    val = _a.sent();
                                                    if (!val)
                                                        val = 0;
                                                    template = GetKPITemplate(indiConf.valueType);
                                                    if ([eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(indiConf.valueType)) {
                                                        currency = this.currencies.find(function (cur) { return cur["@rid"] === rowData.Currency; });
                                                        if (currency)
                                                            return [2 /*return*/, "".concat(template(val), " ").concat(currency.Code)];
                                                    }
                                                    return [2 /*return*/, template(val)];
                                            }
                                        }); }); };
                                        this_1.ApplyWidth(colKPI); /** Récupération du eColumnType */
                                        found = allIndicateurs.find(function (i) { return IndicateurToString(i.indicateur) === IndicateurToString(indiConf); });
                                        if (!found) return [3 /*break*/, 2];
                                        decorator = (_this$props$columnDec = this_1.props.columnDecorator) === null || _this$props$columnDec === void 0 ? void 0 : _this$props$columnDec[found.columnType];
                                        if (!decorator) return [3 /*break*/, 2];
                                        return [4 /*yield*/, decorator(colKPI)];
                                    case 1:
                                        colKPI = _f.sent();
                                        _f.label = 2;
                                    case 2:
                                        tmpCols.push(colKPI);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _d = 0, indicateursConf_1 = indicateursConf;
                        _e.label = 11;
                    case 11:
                        if (!(_d < indicateursConf_1.length)) return [3 /*break*/, 14];
                        indiConf = indicateursConf_1[_d];
                        return [5 /*yield**/, _loop_1(indiConf)];
                    case 12:
                        _e.sent();
                        _e.label = 13;
                    case 13:
                        _d++;
                        return [3 /*break*/, 11];
                    case 14:
                        columns = __spreadArray([], tmpCols, true);
                        _e.label = 15;
                    case 15:
                        AddDevModeColumns(columns);
                        this.AddForcedColumns(columns);
                        configuration = this.props.configuration;
                        if (configuration) {
                            columns.forEach(function (c) { return c.frozen = undefined; });
                            columns.slice(0, (_configuration$Frozen = configuration.FrozenPosition) !== null && _configuration$Frozen !== void 0 ? _configuration$Frozen : 0).forEach(function (c) { return c.frozen = "left"; });
                        }
                        this.Columns = columns;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.UpdateRowsFromServer = function () { return __awaiter(_this, void 0, void 0, function () { var _this$props6, _this$props$objectPro, _this$props7, _res$data2, time1379, res, rows, _time1379; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    time1379 = new Date().getTime();
                    console.log("[PERF] searchVertex Start", (_this$props6 = this.props) === null || _this$props6 === void 0 ? void 0 : _this$props6.vertexParams);
                    return [4 /*yield*/, Client.searchVertex(this.objectPrototype.name + ((_this$props$objectPro = this.props.objectPrototypeSuffixe) !== null && _this$props$objectPro !== void 0 ? _this$props$objectPro : ""), (_this$props7 = this.props) === null || _this$props7 === void 0 ? void 0 : _this$props7.vertexParams)];
                case 1:
                    res = _a.sent();
                    rows = res === null || res === void 0 ? void 0 : (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.results;
                    _time1379 = new Date().getTime();
                    console.log("[PERF] searchVertex Elapsed ".concat(_time1379 - time1379, "ms"));
                    return [2 /*return*/, rows];
            }
        }); }); };
        _this.lastRequestId = void 0;
        _this.UpdateRows = function () { return __awaiter(_this, void 0, void 0, function () { var _this$props$adapt, _this$props8, requestId, rows, time9883, _time9883, error_3, time2512, _time2512, time9789, _time9789; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    this.onRowsChanged.emit(eRowEvent.loading);
                    if (this.props.rows) {
                        this.Rows = this.props.rows;
                        return [2 /*return*/];
                    }
                    requestId = Date.now();
                    this.lastRequestId = requestId;
                    rows = [];
                    if (!!this.props.rows) return [3 /*break*/, 6];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, this.UpdateRowsFromServer()];
                case 2:
                    rows = _a.sent();
                    time9883 = new Date().getTime();
                    if (!this.props.afterSearch) return [3 /*break*/, 4];
                    return [4 /*yield*/, this.props.afterSearch(rows)];
                case 3:
                    rows = _a.sent();
                    _a.label = 4;
                case 4:
                    _time9883 = new Date().getTime();
                    ConsoleDebug("[PERF] afterSearch Elapsed ".concat(_time9883 - time9883, "ms"));
                    return [3 /*break*/, 6];
                case 5:
                    error_3 = _a.sent();
                    Notify(Trad("cannot_get_elements"), "error");
                    console.error(error_3);
                    return [3 /*break*/, 6];
                case 6:
                    if (this.lastRequestId != requestId)
                        return [2 /*return*/];
                    time2512 = new Date().getTime();
                    if (this.props.filterRows)
                        rows = rows.filter(this.props.filterRows);
                    _time2512 = new Date().getTime();
                    ConsoleDebug("[PERF] filterRows Elapsed ".concat(_time2512 - time2512, "ms"));
                    time9789 = new Date().getTime();
                    return [4 /*yield*/, Promise.resolve((_this$props$adapt = (_this$props8 = this.props).adapt) === null || _this$props$adapt === void 0 ? void 0 : _this$props$adapt.call(_this$props8, rows))];
                case 7:
                    _a.sent();
                    _time9789 = new Date().getTime();
                    ConsoleDebug("[PERF] adapt Elapsed ".concat(_time9789 - time9789, "ms"));
                    if (!this.props.emptyGrid)
                        this.Rows = rows;
                    else
                        this.Rows = [];
                    return [2 /*return*/];
            }
        }); }); };
        _this.exportExcel = function (exportType, args) { var exportColumns = _this.Columns.flatMap(function (c) { var _c$Children; if ((_c$Children = c.Children) !== null && _c$Children !== void 0 && _c$Children.length)
            return c.Children.map(function (cc) { return (__assign(__assign({}, cc.baseColumn), { name: [c.baseColumn.name, cc.baseColumn.name].join(" ") })); }); return [c.baseColumn]; }).filter(function (c) { return c; }); var arg = __assign(__assign({}, args), { type: "table", document: _this.objectPrototype.name, filter: __assign(__assign({}, _this.props.vertexParams), (args && { Start: args.Start, End: args.End })), columnsGeneration: (exportColumns === null || exportColumns === void 0 ? void 0 : exportColumns.length) > 0 ? "fromData" : "fromMetadata", columns: exportColumns }); return Client.downloadExport(exportType, arg, Trad(_this.objectPrototype.name)); };
        _this.objectPrototype = props.objectPrototype;
        _this.devMode = props.devMode;
        _this.additionnalColumns = props.columns;
        _this.props = props;
        if (props.hiddenProperties)
            _this.hiddenProperties = _this.hiddenProperties.concat(props.hiddenProperties);
        _this.InitializeComponent(function () {
        });
        return _this;
    }
    GridBase.prototype.InitStore = function (bindingPath) {
        return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, undefined];
        }); });
    };
    GridBase.prototype.AddForcedColumns = function (columns) {
        var _this = this;
        var _this$props$forcedCol;
        if ((_this$props$forcedCol = this.props.forcedColumns) !== null && _this$props$forcedCol !== void 0 && _this$props$forcedCol.length) {
            var _this$props$forcedCol2;
            (_this$props$forcedCol2 = this.props.forcedColumns) === null || _this$props$forcedCol2 === void 0 ? void 0 : _this$props$forcedCol2.forEach(function (f) { columns.unshift(f); });
            if (this.props.order) {
                columns.forEach(function (c) { return c.indexOrder = _this.props.order.indexOf(c.bindingPath); });
                columns = columns.sort(function (a, b) { return a.indexOrder - b.indexOrder; });
            }
        }
    };
    GridBase.prototype.create = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, true];
            });
        });
    };
    GridBase.prototype.update = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, true];
            });
        });
    };
    GridBase.prototype.delete = function (rows) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, true];
            });
        });
    };
    GridBase.prototype.validator = function (vertex, errors, row) {
        return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!this.props.validator) return [3 /*break*/, 2];
                    return [4 /*yield*/, this.props.validator(vertex, errors, row)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        }); });
    };
    GridBase.prototype.rowToObjectAfter = function (object, row) {
    };
    GridBase.prototype.GetDefaultColumnConfiguration = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _store$getState$colum, _store$getState$colum2, _store$getState$colum3, finalConf, userConfLocalSto, _await$Client$searchV, _await$Client$searchV2, confs, allIndicateurs_1, cpy, columnRemoved;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        finalConf = null;
                        userConfLocalSto = clone((_store$getState$colum = store.getState().columsConfigurations) === null || _store$getState$colum === void 0 ? void 0 : (_store$getState$colum2 = _store$getState$colum.configurations) === null || _store$getState$colum2 === void 0 ? void 0 : (_store$getState$colum3 = _store$getState$colum2[this.objectPrototype.name]) === null || _store$getState$colum3 === void 0 ? void 0 : _store$getState$colum3.table);
                        if (!(userConfLocalSto && this.objectPrototype.name == userConfLocalSto.Table)) return [3 /*break*/, 1];
                        finalConf = userConfLocalSto;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, Client.searchVertex(ref_TableConfigurations.name, { Table: this.objectPrototype.name, Path: window.location.pathname })];
                    case 2:
                        confs = (_await$Client$searchV = _a.sent()) === null || _await$Client$searchV === void 0 ? void 0 : (_await$Client$searchV2 = _await$Client$searchV.data) === null || _await$Client$searchV2 === void 0 ? void 0 : _await$Client$searchV2.results;
                        finalConf = confs === null || confs === void 0 ? void 0 : confs.find(function (v) { return v.Default; });
                        _a.label = 3;
                    case 3:
                        if (!finalConf) return [3 /*break*/, 6];
                        return [4 /*yield*/, AggregatorFactory.GetInstance().Get(this.objectPrototype).Provide()];
                    case 4:
                        allIndicateurs_1 = (_a.sent()).map(function (i) { return i.indicateur; });
                        cpy = __spreadArray([], finalConf.Columns, true);
                        finalConf.Columns = finalConf.Columns.filter(function (c) { return allIndicateurs_1.some(function (o) { return IndicateurToString(o) === IndicateurToString(c); }); });
                        ConsoleDebug("finalConf.Columns => filtered", cpy, finalConf.Columns); /** Colonnes ignorées */
                        if (!dicoNotifColumnChanged[finalConf.Name]) {
                            columnRemoved = cpy.filter(function (c) { return !finalConf.Columns.some(function (f) { return f === c; }) && (c === null || c === void 0 ? void 0 : c.name); });
                            if (columnRemoved !== null && columnRemoved !== void 0 && columnRemoved.length) {
                                Notify("".concat(Trad("columns_changed"), " ") + columnRemoved.map(function (c) { return c.name; }).join(", "), "warning");
                                dicoNotifColumnChanged[finalConf.Name] = true;
                            }
                        }
                        return [4 /*yield*/, AggregatorFactory.GetInstance().Get(this.objectPrototype).UpdateIndicateursNames(finalConf.Columns, allIndicateurs_1)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        if (!finalConf) {
                            finalConf = new ref_TableConfigurations();
                            finalConf.Name = Trad("empty_conf");
                            finalConf.Columns = [];
                        }
                        return [2 /*return*/, finalConf];
                }
            });
        });
    };
    GridBase.prototype.Initialize = function () { return this.InitializeColumns().then(this.UpdateRows); };
    GridBase.prototype.ApplyWidth = function (column) { var _this$props$width2, _column$baseColumn$fi, _column$baseColumn; var overrideWidth = (_this$props$width2 = this.props.width) === null || _this$props$width2 === void 0 ? void 0 : _this$props$width2[(_column$baseColumn$fi = (_column$baseColumn = column.baseColumn) === null || _column$baseColumn === void 0 ? void 0 : _column$baseColumn.field) !== null && _column$baseColumn$fi !== void 0 ? _column$baseColumn$fi : column.bindingPath]; if (overrideWidth != undefined)
        column.width = overrideWidth; };
    GridBase.prototype.FormaterStoredData = function (data) { return Format(data); };
    GridBase.prototype.createData = function () { var object = new this.objectPrototype(); if (this.props.initializePrototype) {
        this.props.initializePrototype(object);
    } return object; };
    GridBase.prototype.rowToObject = function (row) {
        var _this = this;
        var _this$properties4, _this$props9, _this$props9$rowToVer;
        var newObj = {};
        (_this$properties4 = this.properties) === null || _this$properties4 === void 0 ? void 0 : _this$properties4.forEach(function (p) {
            var _this$props$vertexPar;
            var oldValue = row.dataItem[p.name];
            var value = row[p.name];
            row.dataItem[p.name] = value; // Suppress aliases
            if (oldValue != value)
                (_this$props$vertexPar = _this.props.vertexParams.properties) === null || _this$props$vertexPar === void 0 ? void 0 : _this$props$vertexPar.forEach(function (prop) { var _chunks$; var chunks = prop.split(" "); if (chunks.length == 3 && ((_chunks$ = chunks[1]) === null || _chunks$ === void 0 ? void 0 : _chunks$.toLocaleLowerCase()) == "as" && chunks[0].split(".")[0] == p.name) {
                    console.log("delete", chunks[2], oldValue, value);
                    delete row.dataItem[chunks[2]];
                } }); // if (row.dataItem.hasOwnProperty(p.name + "Name"))
            //     delete row.dataItem[p.name + "Name"]
            newObj[p.name] = value;
        });
        this.rowToObjectAfter(newObj, row);
        (_this$props9 = this.props) === null || _this$props9 === void 0 ? void 0 : (_this$props9$rowToVer = _this$props9.rowToVertex) === null || _this$props9$rowToVer === void 0 ? void 0 : _this$props9$rowToVer.call(_this$props9, row, newObj);
        return newObj;
    }; /**
         * Reorder columns depending on UI changes
         * @param columnIds
         */
    GridBase.prototype.onReorder = function (columnIds) {
        return __awaiter(this, void 0, void 0, function () {
            var _this$props10, conf;
            return __generator(this, function (_a) {
                conf = (_this$props10 = this.props) === null || _this$props10 === void 0 ? void 0 : _this$props10.configuration;
                if (conf && !conf.Default) {
                    conf.Columns = __spreadArray([], conf.Columns, true).sort(function (a, b) { var i1 = columnIds.indexOf(IndicateurToString(a)); var i2 = columnIds.indexOf(IndicateurToString(b)); if (i1 > -1 && i2 > -1)
                        return i1 - i2; return 0; });
                    store.dispatch(setTable(conf));
                } //await this.InitializeColumns();
                return [2 /*return*/];
            });
        });
    };
    return GridBase;
}(ADWGrid));
export { GridBase };
