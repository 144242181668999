var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { loginRoute, searchRefRoute, graphRoute, externalRoute, metadataRoute, linkRoute, dimensionRoute, logsRoute, versionRoute, tokenRoute, localeRoute, domainsRoute, resetRoute, newpassRoute, fileRoute, alertRoute, externalimportRoute, sellsyRoute } from '../index.bin';
import { ref_Sources } from '../models/orientdb/ref_Sources.bin';
import { eAction, logUserArg } from '../models/requests.bin';
import { ReferentialHasViews } from '../models/orientdb/ReferentialHasViews.bin';
import { Sort } from 'format-lib/index.bin';
import { clientCache } from './clientCache.bin';
import { Trad, TradProp } from 'trad-lib';
import { EventEmitter } from 'events';
import { eSocketMsg } from '../models/socket.bin';
import { ref_Estimates } from '../dto/client/ref_Estimates.bin';
import { clone, duplicate, SerializeError, toArray } from '../tools.bin';
import { ref_Messages } from '../dto/client/ref_Messages.bin';
import { ClientOAUT2 } from './oaut2.bin';
import { AdwoneEnvironments, GetFrontUrlFromEnv } from 'adw-url-provider';
axios.defaults.withCredentials = true;
var URLServerProvider = /** @class */ (function () {
    function URLServerProvider() {
    }
    URLServerProvider.provide = function (path) {
        throw new Error("URLServerProvider provide Not implemented");
    };
    return URLServerProvider;
}());
export { URLServerProvider };
/**
 * @description Find a redirection with the splited (NO empty) path
 * @author Bastien Douib
 * @param {({ [key: string]: string | typeof dico })} dico
 * @param {string[]} path
 * @returns {*}  {(string | null)}
 */
export var redirectionFinder = function (dico, path) {
    var find = dico[path[0]];
    path.shift();
    if (find) {
        if (typeof find === "string")
            return find;
        return redirectionFinder(find, path);
    }
    return null;
};
var URLAdwoneProvider = /** @class */ (function () {
    function URLAdwoneProvider() {
    }
    URLAdwoneProvider.provide = function () {
        return GetFrontUrlFromEnv(process.env.NODE_ENV, process.env.ADWONE_ENV);
    };
    URLAdwoneProvider.provideFrontUrls = function () {
        var allUrls = AdwoneEnvironments.map(function (u) { return toArray(u.front); }).flat();
        return allUrls;
    };
    return URLAdwoneProvider;
}());
export { URLAdwoneProvider };
// let access_token = undefined;
// let refresh_token = undefined;
// /** for code sharing purposes (browser + server side) */
// if (typeof localStorage !== "undefined") {
//     access_token = localStorage.getItem('access_token');
//     refresh_token = localStorage.getItem('refresh_token');
// }
// function setHeaderAuthorization(bearer) {
//     headers.headers["Authorization"] = `Bearer ${bearer}`;
// }
// if (access_token)
//     setHeaderAuthorization(access_token);
export function GetUrlServer(path) {
    return URLServerProvider.provide(path !== null && path !== void 0 ? path : null);
}
var CachingPromise = /** @class */ (function () {
    function CachingPromise() {
    }
    CachingPromise.SetCache = function (key, promise) {
        var _this = this;
        var existing = this.dico[key];
        if (!existing) {
            var newPromise = promise().then(function (values) {
                delete _this.dico[key];
                return values;
            }).catch(function (e) {
                delete _this.dico[key];
                throw e;
            });
            this.dico[key] = newPromise;
            return newPromise;
        }
        return existing.then(function (values) {
            return values;
        });
    };
    CachingPromise.dico = {};
    return CachingPromise;
}());
var DeleteEmitter = /** @class */ (function (_super) {
    __extends(DeleteEmitter, _super);
    function DeleteEmitter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeleteEmitter.prototype.emit = function (event, type) {
        return _super.prototype.emit.call(this, event, type);
    };
    DeleteEmitter.prototype.addListener = function (event, listener) {
        return _super.prototype.addListener.call(this, event, listener);
    };
    return DeleteEmitter;
}(EventEmitter));
var CreationEmitter = /** @class */ (function (_super) {
    __extends(CreationEmitter, _super);
    function CreationEmitter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CreationEmitter.prototype.emit = function (event, arg) {
        return _super.prototype.emit.call(this, event, arg);
    };
    CreationEmitter.prototype.addListener = function (event, listener) {
        return _super.prototype.addListener.call(this, event, listener);
    };
    return CreationEmitter;
}(EventEmitter));
var UpdateEmitter = /** @class */ (function (_super) {
    __extends(UpdateEmitter, _super);
    function UpdateEmitter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UpdateEmitter.prototype.emit = function (event, params) {
        return _super.prototype.emit.call(this, event, params);
    };
    UpdateEmitter.prototype.addListener = function (event, listener) {
        return _super.prototype.addListener.call(this, event, listener);
    };
    return UpdateEmitter;
}(EventEmitter));
var CopyEmitter = /** @class */ (function (_super) {
    __extends(CopyEmitter, _super);
    function CopyEmitter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CopyEmitter.prototype.emit = function (event, arg) {
        return _super.prototype.emit.call(this, event, arg);
    };
    CopyEmitter.prototype.addListener = function (event, listener) {
        return _super.prototype.addListener.call(this, event, listener);
    };
    return CopyEmitter;
}(EventEmitter));
var PersistEmitter = /** @class */ (function (_super) {
    __extends(PersistEmitter, _super);
    function PersistEmitter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PersistEmitter.prototype.emit = function (event, arg) {
        return _super.prototype.emit.call(this, event, arg);
    };
    PersistEmitter.prototype.addListener = function (event, listener) {
        return _super.prototype.addListener.call(this, event, listener);
    };
    return PersistEmitter;
}(EventEmitter));
var CancelEmitter = /** @class */ (function (_super) {
    __extends(CancelEmitter, _super);
    function CancelEmitter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CancelEmitter.prototype.emit = function (event, arg) {
        return _super.prototype.emit.call(this, event, arg);
    };
    CancelEmitter.prototype.addListener = function (event, listener) {
        return _super.prototype.addListener.call(this, event, listener);
    };
    return CancelEmitter;
}(EventEmitter));
var ClientHUB = /** @class */ (function (_super) {
    __extends(ClientHUB, _super);
    function ClientHUB() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onErrorEvent = new EventEmitter();
        _this.notificationEvent = new EventEmitter();
        _this.onGetStatus = new EventEmitter();
        _this.onRequestDone = new EventEmitter();
        _this.onCreationVertex = new CreationEmitter();
        _this.onReadVertex = new CreationEmitter();
        _this.onCopyVertex = new CopyEmitter();
        _this.onPersistVertex = new PersistEmitter();
        _this.onCancelVertex = new CancelEmitter();
        _this.onUpdateVertex = new UpdateEmitter();
        _this.onDeleteVertex = new DeleteEmitter();
        _this.cacheMetadata = {};
        _this.cacheAllMetadata = {};
        _this.getForm = function (vertexName, params) {
            if (params === void 0) { params = {}; }
            var path = "/" + vertexName + "/search/form";
            return _this.execPost(URLServerProvider.provide(path) + path, params).then(function (res) { return res.data; });
        };
        _this.getLinkedHierchy = function (vertexName, body) {
            var path = "/" + vertexName + "/search/linkedhierchy";
            return _this.execPost(URLServerProvider.provide(path) + path, body).then(function (res) { return res.data; });
        };
        return _this;
    }
    ClientHUB.AddSearchDecorator = function (decorator) {
        ClientHUB.searchDecorator.push(decorator);
    };
    ClientHUB.SearchDecorate = function (vertex, params) {
        ClientHUB.searchDecorator
            .filter(function (d) { return d.vertex == vertex; })
            .forEach(function (d) { return params = d.decorate(duplicate(params)); });
        return params;
    };
    ClientHUB.prototype.getSellsyInfos = function () {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/infos", {});
    };
    // getSellsyLinkedDocuments(body: ref_Estimates): Promise<Resp<LinkedDocuments>> {
    //     return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/search/linked", body);
    // }
    ClientHUB.prototype.healthSellsy = function () {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/health", {});
    };
    ClientHUB.prototype.searchSellsyCompanies = function (body) {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/search/companies", body);
    };
    ClientHUB.prototype.saveToken = function (body) {
        var path = "/".concat(body.api, "/token");
        return this.execPost(URLServerProvider.provide(path) + path, body);
    };
    ClientHUB.prototype.loginSellsy = function () {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/login", {});
    };
    ClientHUB.prototype.logoutSellsy = function () {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/logout", {});
    };
    // private checkRefreshToken = (req: () => Promise<any>) => {
    //     return req().catch(err => {
    //         if (err?.response?.data?.error == "invalid_token") {
    //             return this.geRefreshtToken().then(() => req())
    //         }
    //         throw err;
    //     });
    // }
    //private execPost(url, body, _headers): Promise<any> {
    //     return this.checkRefreshToken(() => axios.post(url, body, _headers))
    //         .then((res) => {
    //             this.onRequestDone.emit('POST', url)
    //             return res;
    //         });
    // }
    // private execPut(url, body, _headers): Promise<any> {
    //     return this.checkRefreshToken(() => axios.put(url, body, _headers))
    //         .then((res) => {
    //             this.onRequestDone.emit('PUT', url)
    //             return res;
    //         });
    // }
    // private execGet(url, _headers): Promise<any> {
    //     return this.checkRefreshToken(() => axios.get(url, _headers))
    //         .then((res) => {
    //             this.onRequestDone.emit('GET', url)
    //             return res;
    //         });
    // }
    // private execDelete(url, _headers, data = undefined): Promise<any> {
    //     return this.checkRefreshToken(() => axios.delete(url, { ..._headers, data }))
    //         .then((res) => {
    //             this.onRequestDone.emit('DELETE', url)
    //             return res;
    //         });
    // }
    ClientHUB.prototype.bypassUser = function (mail) {
        var path = loginRoute + "/bypass";
        return this.execGet(URLServerProvider.provide(path) + path + "?login=".concat(mail));
    };
    ClientHUB.prototype.getUser = function (force) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(!this.user || force)) return [3 /*break*/, 2];
                        _b = this;
                        return [4 /*yield*/, this.getStatus()];
                    case 1:
                        _b.user = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data;
                        return [2 /*return*/, this.user];
                    case 2: return [2 /*return*/, this.user];
                }
            });
        });
    };
    ClientHUB.prototype.getUserSync = function () {
        return this.user;
    };
    /**
     * To log the user on the server
     * @param name
     * @param password
     */
    ClientHUB.prototype.login = function (name, password) {
        var body = { username: name, password: password };
        var path = loginRoute + "/default";
        return this.execPost(URLServerProvider.provide(path) + path, body);
    };
    ClientHUB.prototype.Post = function (route, body, config) {
        return this.execPost(URLServerProvider.provide(route) + route, body, config);
    };
    ClientHUB.prototype.GetCookie = function () {
        return this.execGet(URLServerProvider.provide("/cookie") + "/cookie");
    };
    ClientHUB.prototype.SetLocale = function (locale) {
        return this.execPost(URLServerProvider.provide(localeRoute) + localeRoute, { locale: locale });
    };
    /**
     * To logout the user on the server
     * @param none
     */
    ClientHUB.prototype.logout = function () {
        return __awaiter(this, void 0, Promise, function () {
            var r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.execDelete(URLServerProvider.provide(loginRoute) + loginRoute)];
                    case 1:
                        r = _a.sent();
                        this.onGetStatus.emit("DELETE");
                        localStorage.removeItem("user");
                        return [2 /*return*/, r];
                }
            });
        });
    };
    /**
     * To get connection status of the user on the server
     * @param none
     */
    ClientHUB.prototype.getStatus = function () {
        var _this = this;
        var path = loginRoute + "/session/info";
        return this.execGet(URLServerProvider.provide(path) + path)
            .then(function (r) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.onGetStatus.emit("GET", r.data.user);
                return [2 /*return*/, r];
            });
        }); });
    };
    ClientHUB.prototype.getElement = function (path) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.execGet(URLServerProvider.provide(path) + path)];
                    case 1: return [2 /*return*/, (_a = (_b.sent())) === null || _a === void 0 ? void 0 : _a.data];
                }
            });
        });
    };
    /**
     * Search texts in referentials
     * @param texts
     */
    ClientHUB.prototype.search = function (texts) {
        var body = { texts: texts };
        return this.execPost(URLServerProvider.provide(searchRefRoute) + searchRefRoute, body);
    };
    /**
     * Search texts in referentials
     * @param texts
     */
    ClientHUB.prototype.graph = function (id) {
        var body = { id: id };
        return this.execPost(URLServerProvider.provide(graphRoute) + graphRoute, body);
    };
    /**
     * Aggregate data
     * @param body
     */
    ClientHUB.prototype.aggregate = function (body, cancelToken) {
        var options = cancelToken ? { cancelToken: cancelToken } : {};
        return this.execPost(URLServerProvider.provide(externalRoute) + externalRoute, body, options);
    };
    /**
     * Distinct
     * @param body
     */
    ClientHUB.prototype.distinct = function (body) {
        var path = externalRoute + "/distinct";
        return this.execPost(URLServerProvider.provide(path) + path, body);
    };
    ClientHUB.prototype.downloadTcdExport = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var path, now, date, hour, res, downloadUrl, link, e_1, error, _a, _b, error_1_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        path = externalRoute + "/tcdexport";
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 8]);
                        now = new Date();
                        date = [now.getFullYear(), pad(now.getMonth() + 1), pad(now.getDate())].join('-');
                        hour = [pad(now.getHours()), pad(now.getMinutes()), pad(now.getSeconds())].join('-');
                        return [4 /*yield*/, this.execPost(URLServerProvider.provide(path) + path, args, { responseType: 'blob' })];
                    case 2:
                        res = _c.sent();
                        downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                        link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', "export_PivotGrid_".concat(date, "_").concat(hour, ".xlsx")); //any other extension
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        return [3 /*break*/, 8];
                    case 3:
                        e_1 = _c.sent();
                        _c.label = 4;
                    case 4:
                        _c.trys.push([4, 6, , 7]);
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, e_1.response.data.text()];
                    case 5:
                        error = _b.apply(_a, [_c.sent()]);
                        this.onErrorEvent.emit("fail", { message: Trad('cannot_export_excel'), error: error });
                        return [3 /*break*/, 7];
                    case 6:
                        error_1_1 = _c.sent();
                        this.notificationEvent.emit(eSocketMsg.notification, { message: Trad("cannot_export_excel"), messageType: "error" });
                        return [3 /*break*/, 7];
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Download excel csv or formated
     * @param type
     * @param body
     * @param title
     */
    ClientHUB.prototype.downloadExport = function (type, body, title) {
        return __awaiter(this, void 0, Promise, function () {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var d, date, hour, path, res, downloadUrl, link, e_2, error, _a, _b, error_1_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        d = new Date();
                        date = [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
                        hour = [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join('-');
                        //this.notificationEvent.emit(eSocketMsg.notification, { message: Trad("export_in_progress"), messageType: "info" });
                        this.log({ Category: "export_".concat(type), Action: body.document, Informations: { body: body } });
                        path = externalRoute + "/export/".concat(type);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 8]);
                        return [4 /*yield*/, this.execPost(URLServerProvider.provide(path) + path, body, { responseType: 'blob' })];
                    case 2:
                        res = _c.sent();
                        downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                        link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', "export_".concat(title, "_").concat(date, "_").concat(hour, ".").concat(type === "formated" ? "xlsx" : "csv")); //any other extension
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        return [2 /*return*/, res];
                    case 3:
                        e_2 = _c.sent();
                        _c.label = 4;
                    case 4:
                        _c.trys.push([4, 6, , 7]);
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, e_2.response.data.text()];
                    case 5:
                        error = _b.apply(_a, [_c.sent()]);
                        this.onErrorEvent.emit("fail", { message: Trad('cannot_export_excel'), error: error });
                        return [3 /*break*/, 7];
                    case 6:
                        error_1_2 = _c.sent();
                        this.notificationEvent.emit(eSocketMsg.notification, { message: Trad("cannot_export_excel"), messageType: "error" });
                        return [3 /*break*/, 7];
                    case 7: return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get available sources for the current user
     */
    ClientHUB.prototype.getSources = function () {
        var path = "/".concat(ref_Sources.name);
        return this.execGet(URLServerProvider.provide(path) + path);
    };
    /**
     * Get available type link of dimensions
     */
    ClientHUB.prototype.getDimensionLinks = function () {
        var path = "/".concat(ReferentialHasViews.name);
        return this.execGet(URLServerProvider.provide(path) + path);
    };
    /**
     * get link types
     * @param dimension
     */
    ClientHUB.prototype.getDimensionLinkTypes = function (dimension) {
        var path = metadataRoute + "/".concat(encodeURI(dimension), "/children");
        return this.execGet(URLServerProvider.provide(path) + path);
    };
    ClientHUB.prototype.getMetadata = function (dimension, all) {
        var _a, _b;
        if (all === void 0) { all = false; }
        return __awaiter(this, void 0, Promise, function () {
            var key, path, _c, _d, _e, error_2;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 3, , 4]);
                        key = dimension + all;
                        if (!!this.cacheMetadata[key]) return [3 /*break*/, 2];
                        path = metadataRoute + "/".concat(encodeURI(dimension), "?all=").concat(all);
                        _c = this.cacheMetadata;
                        _d = key;
                        _e = duplicate;
                        return [4 /*yield*/, this.execGet(URLServerProvider.provide(path) + path)];
                    case 1:
                        _c[_d] = _e.apply(void 0, [(_b = (_a = (_f.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.results]);
                        _f.label = 2;
                    case 2: return [2 /*return*/, { data: { results: duplicate(this.cacheMetadata[key]) } }];
                    case 3:
                        error_2 = _f.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, { data: { results: [] } }];
                }
            });
        });
    };
    ClientHUB.prototype.getAllMetadata = function (dimension) {
        var _a, _b;
        return __awaiter(this, void 0, Promise, function () {
            var path, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!!this.cacheAllMetadata[dimension]) return [3 /*break*/, 2];
                        path = metadataRoute + "/".concat(encodeURI(dimension), "/getall");
                        _c = this.cacheAllMetadata;
                        _d = dimension;
                        _e = duplicate;
                        return [4 /*yield*/, this.execGet(URLServerProvider.provide(path) + path)];
                    case 1:
                        _c[_d] = _e.apply(void 0, [(_b = (_a = (_f.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.results]);
                        _f.label = 2;
                    case 2: return [2 /*return*/, { data: { results: duplicate(this.cacheAllMetadata[dimension]) } }];
                }
            });
        });
    };
    ClientHUB.prototype.sort = function (vertexName) {
        return function (res) {
            var _a;
            if ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.results)
                res.data.results = Sort(vertexName, res.data.results);
            return res;
        };
    };
    ClientHUB.prototype.resetPassword = function (mail) {
        var path = "".concat(loginRoute).concat(resetRoute);
        return this.execPost(URLServerProvider.provide(path) + path, { mail: mail });
    };
    ClientHUB.prototype.setNewPassword = function (token, password) {
        var path = "".concat(loginRoute).concat(newpassRoute);
        return this.execPost(URLServerProvider.provide(path) + path, { token: token, password: password });
    };
    /*Ojd */
    ClientHUB.prototype.getWaves = function (params) {
        if (params === void 0) { params = {}; }
        return this.execPost(URLServerProvider.provide("/ojd/dates") + '/ojd/dates', params, { timeout: 2000 })
            .then(function (res) {
            var _a;
            return (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.results;
        });
    };
    ClientHUB.prototype.getOjd = function (params) {
        if (params === void 0) { params = {}; }
        return this.execPost(URLServerProvider.provide("/ojd/search") + '/ojd/search', params)
            .then(function (res) {
            return res.data.results;
        });
    };
    /*hierarchy search*/
    ClientHUB.prototype.searchVertex = function (vertexName, _params, notify, options) {
        var _this = this;
        if (_params === void 0) { _params = {}; }
        var params = ClientHUB.SearchDecorate(vertexName, _params);
        if ((options === null || options === void 0 ? void 0 : options.cache) === undefined || (options === null || options === void 0 ? void 0 : options.cache) === true) {
            var cache = clientCache.getCache(vertexName, params);
            if (cache)
                return Promise.resolve(cache).then(clone);
        }
        var path = "/" + vertexName + "/search";
        var fullPath = URLServerProvider.provide(path) + path;
        var cb = function () { return _this.execPost(fullPath, params)
            .then(_this.sort(vertexName))
            .then(function (res) {
            clientCache.setCache(vertexName, params, res);
            return res;
        }).catch(function (error) {
            var _a, _b, _c;
            if (notify) {
                try {
                    _this.onReadVertex.emit("fail", { vertex: vertexName, res: error.response.data });
                }
                catch (e) {
                }
            }
            _this.log({
                Category: vertexName, Action: eAction.Read,
                Informations: {
                    errorCode: (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status,
                    responseData: (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : 'no response data',
                    params: params,
                    error: error
                }, Status: "fail"
            });
            throw error;
        }); };
        return CachingPromise.SetCache(JSON.stringify(__assign(__assign({}, (params !== null && params !== void 0 ? params : {})), { fullPath: fullPath })), cb);
    };
    ClientHUB.prototype.onGetRefreshTokenError = function (err) {
        // this.log({
        //     Category: 'onGetRefreshTokenError',
        //     Informations: {
        //         serializedError: SerializeError(err),
        //     }
        // });
    };
    ClientHUB.prototype.onRequestError = function (err, args) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        var time = args.time;
        var category = "onRequestError ".concat((_a = args === null || args === void 0 ? void 0 : args.url) !== null && _a !== void 0 ? _a : "").trim();
        if (time)
            category += " in ".concat(time, "ms");
        var error = {
            args: args,
            Category: category,
            Action: (_w = (_u = (_r = (_o = (_k = (_g = (_d = (_b = err === null || err === void 0 ? void 0 : err.message) !== null && _b !== void 0 ? _b : (_c = err === null || err === void 0 ? void 0 : err.response) === null || _c === void 0 ? void 0 : _c.statusText) !== null && _d !== void 0 ? _d : (_f = (_e = err === null || err === void 0 ? void 0 : err.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.error) !== null && _g !== void 0 ? _g : (_j = (_h = err === null || err === void 0 ? void 0 : err.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.message) !== null && _k !== void 0 ? _k : (_m = (_l = err === null || err === void 0 ? void 0 : err.response) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.error_description) !== null && _o !== void 0 ? _o : (_q = (_p = err === null || err === void 0 ? void 0 : err.response) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.error_code) !== null && _r !== void 0 ? _r : (_t = (_s = err === null || err === void 0 ? void 0 : err.response) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.error_message) !== null && _u !== void 0 ? _u : (_v = err === null || err === void 0 ? void 0 : err.response) === null || _v === void 0 ? void 0 : _v.data) !== null && _w !== void 0 ? _w : 'no message',
            Informations: {
                serializedError: SerializeError(err),
            }
        };
        console.error('[onRequestError]', error);
        this.log(error);
    };
    ClientHUB.prototype.countVertex = function (vertexName, params) {
        if (params === void 0) { params = {}; }
        var path = "/" + vertexName + "/count";
        return this.execPost(URLServerProvider.provide(path) + path, params);
    };
    ClientHUB.prototype.searchVertexTyped = function (vertexClass, _params) {
        var _a, _b;
        if (_params === void 0) { _params = {}; }
        return __awaiter(this, void 0, Promise, function () {
            var params;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        params = ClientHUB.SearchDecorate(vertexClass.name, _params);
                        return [4 /*yield*/, this.searchVertex(vertexClass.name, params)];
                    case 1: return [2 /*return*/, (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.results];
                }
            });
        });
    };
    ClientHUB.prototype.searchFiles = function (params, admin) {
        if (params === void 0) { params = {}; }
        var adminPath = admin ? "/admin" : "";
        var path = "".concat(fileRoute, "/search").concat(adminPath);
        return this.execPost(URLServerProvider.provide(path) + path, params);
    };
    ClientHUB.prototype.deleteFiles = function (params) {
        if (params === void 0) { params = {}; }
        var path = "".concat(fileRoute, "/delete");
        return this.execPost(URLServerProvider.provide(path) + path, params);
    };
    ClientHUB.prototype.dowloadFile = function (params) {
        var path = "".concat(fileRoute, "/download");
        if (!params)
            path += '/default';
        return this.execPost(URLServerProvider.provide(path) + path, params, { responseType: 'blob' })
            .then(function (res) {
            var _a;
            var downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            var link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', (_a = params === null || params === void 0 ? void 0 : params.filename) !== null && _a !== void 0 ? _a : "default.xlsx"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return res;
        });
    };
    ClientHUB.prototype.dowloadElement = function (params) {
        var path = "".concat(params.route, "/download");
        return this.execPost(URLServerProvider.provide(path) + path, params, { responseType: 'blob' })
            .then(function (res) {
            var _a;
            var downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            var link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', (_a = params === null || params === void 0 ? void 0 : params.file) !== null && _a !== void 0 ? _a : "default.xlsx"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return res;
        });
    };
    ClientHUB.prototype.getURLAttachmentFile = function (params) {
        var path = "".concat(fileRoute, "/attachment/download");
        if (!params)
            path += '/default';
        return this.execPost(URLServerProvider.provide(path) + path, params, { responseType: 'blob' })
            .then(function (res) {
            var downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            return downloadUrl;
        });
    };
    ClientHUB.prototype.dowloadAttachmentFile = function (params) {
        var path = "".concat(fileRoute, "/attachment/download");
        if (!params)
            path += '/default';
        return this.execPost(URLServerProvider.provide(path) + path, params, { responseType: 'blob' })
            .then(function (res) {
            var _a;
            var downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            var link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', (_a = params === null || params === void 0 ? void 0 : params.filename) !== null && _a !== void 0 ? _a : "default.xlsx"); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return res;
        });
    };
    ClientHUB.prototype.uploadFile = function (file, params) {
        var formData = new FormData();
        // Update the formData object
        formData.append("file", file, file.name);
        formData.append("docType", params.docType);
        formData.append("company", params.company);
        formData.append("Customer", params.Customer);
        var path = "".concat(fileRoute);
        return axios.post(URLServerProvider.provide(path) + path, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };
    ClientHUB.prototype.databaseRequestUpdate = function (reqParams) {
        var path = "/mongorequests";
        return this.execPost(URLServerProvider.provide(path) + path, reqParams);
    };
    ClientHUB.prototype.databaseRequestSearch = function (reqParams) {
        var path = "/mongorequests/search";
        return this.execPost(URLServerProvider.provide(path) + path, reqParams).then(function (res) { return res.data; });
    };
    ClientHUB.prototype.createVertex = function (vertexName, params, notify) {
        var _a;
        if (notify === void 0) { notify = true; }
        return __awaiter(this, void 0, Promise, function () {
            var path, res, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        clientCache.clearCache(vertexName);
                        path = "/" + vertexName;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.execPost(URLServerProvider.provide(path) + path, params)];
                    case 2:
                        res = _b.sent();
                        if (notify) {
                            try {
                                this.onCreationVertex.emit("successful", { vertex: vertexName, res: (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.results });
                            }
                            catch (e) {
                                console.error(e);
                            }
                        }
                        this.log({ Category: vertexName, Action: eAction.Create, Informations: { params: params } });
                        return [2 /*return*/, res];
                    case 3:
                        error_3 = _b.sent();
                        if (notify) {
                            try {
                                this.onCreationVertex.emit("fail", { vertex: vertexName, res: error_3.response.data });
                            }
                            catch (e) {
                                console.error(e);
                            }
                        }
                        this.log({ Category: vertexName, Action: eAction.Create, Informations: { params: params, error: error_3 }, Status: "fail" });
                        throw error_3;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ClientHUB.prototype.preCreateVertex = function (vertexType, params, notify) {
        var _a, _b;
        if (notify === void 0) { notify = true; }
        return __awaiter(this, void 0, Promise, function () {
            var path, res;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        path = "/" + vertexType.name + "/precreate";
                        return [4 /*yield*/, this.execPost(URLServerProvider.provide(path) + path, params)];
                    case 1:
                        res = (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.results;
                        return [2 /*return*/, res];
                }
            });
        });
    };
    ClientHUB.prototype.faileUpdate = function (vertex, params) {
        this.onUpdateVertex.emit("fail", { vertex: vertex, params: params });
    };
    ClientHUB.prototype.broadcast = function (body, type) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var path, res;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        path = "/broadcast/".concat(type);
                        return [4 /*yield*/, this.execPost(URLServerProvider.provide(path) + path, body)];
                    case 1:
                        res = (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.results;
                        return [2 /*return*/, res];
                }
            });
        });
    };
    ClientHUB.prototype.getActiveSockets = function () {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var path, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        path = '/socket-log';
                        return [4 /*yield*/, this.execGet(URLServerProvider.provide(path) + path)];
                    case 1:
                        res = (_a = (_b.sent())) === null || _a === void 0 ? void 0 : _a.data;
                        return [2 /*return*/, res];
                }
            });
        });
    };
    ClientHUB.prototype.updateVertex = function (vertexName, params, notify, args) {
        if (notify === void 0) { notify = true; }
        return __awaiter(this, void 0, Promise, function () {
            var path, res, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clientCache.clearCache(vertexName);
                        path = "/" + vertexName;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.execPut(URLServerProvider.provide(path) + path, params)];
                    case 2:
                        res = _a.sent();
                        if (notify && (!args || args.successfullMsg)) {
                            try {
                                this.onUpdateVertex.emit("successful", { vertex: vertexName, params: params });
                            }
                            catch (e) {
                                console.error(e);
                            }
                        }
                        this.log({ Category: vertexName, Action: eAction.Update, Informations: { params: params } });
                        return [2 /*return*/, res];
                    case 3:
                        error_4 = _a.sent();
                        if (notify) {
                            try {
                                this.faileUpdate(vertexName, error_4.response.data);
                            }
                            catch (e) {
                                console.error(e);
                            }
                        }
                        this.log({ Category: vertexName, Action: eAction.Update, Informations: { params: params, error: error_4 }, Status: "fail" });
                        throw error_4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    //delete vertex
    ClientHUB.prototype.deleteVertex = function (vertexName, ridElement, notify, additionalParams) {
        var _this = this;
        if (notify === void 0) { notify = true; }
        clientCache.clearCache(vertexName);
        var deleteBody = __assign({ "@rid": ridElement }, additionalParams);
        var path = "/" + vertexName;
        return this.execDelete(URLServerProvider.provide(path) + path, {}, deleteBody)
            .then(function (res) {
            if (notify) {
                try {
                    _this.onDeleteVertex.emit("successful", { vertex: vertexName });
                }
                catch (error) {
                }
            }
            _this.log({ Category: vertexName, Action: eAction.Delete, Informations: { deleteBody: deleteBody } });
            return res;
        }).catch(function (e) {
            if (notify) {
                try {
                    _this.onDeleteVertex.emit("fail", { vertex: vertexName, res: e.response.data });
                }
                catch (error) {
                }
            }
            _this.log({ Category: vertexName, Action: eAction.Delete, Informations: { deleteBody: deleteBody, error: e }, Status: "fail" });
            throw e;
        });
    };
    //copy vertex
    ClientHUB.prototype.copyVertex = function (vertexName, params, notify) {
        var _this = this;
        if (notify === void 0) { notify = true; }
        clientCache.clearCache(vertexName);
        var path = "/" + vertexName + "/copy";
        return this.execPost(URLServerProvider.provide(path) + path, params)
            .then(function (res) {
            var _a;
            if (notify) {
                try {
                    _this.onCopyVertex.emit("successful", { vertex: vertexName, res: (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.results });
                }
                catch (error) {
                }
            }
            _this.log({ Category: vertexName, Action: eAction.Create + ' - copy', Informations: { params: params } });
            return res;
        }).catch(function (e) {
            if (notify) {
                try {
                    _this.onCopyVertex.emit("fail", { vertex: vertexName, res: e.response.data });
                }
                catch (error) {
                }
            }
            throw e;
        });
    };
    //persist vertex
    ClientHUB.prototype.persistVertex = function (vertexName, params, notify) {
        var _this = this;
        if (notify === void 0) { notify = true; }
        clientCache.clearCache(vertexName);
        var path = "/" + vertexName + "/persist";
        return this.execPut(URLServerProvider.provide(path) + path, params)
            .then(function (res) {
            var _a;
            if (notify) {
                try {
                    _this.onPersistVertex.emit("successful", { vertex: vertexName, res: (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.results });
                }
                catch (error) {
                }
            }
            return res;
        }).catch(function (e) {
            if (notify) {
                try {
                    _this.onPersistVertex.emit("fail", { vertex: vertexName, res: e.response.data });
                }
                catch (error) {
                }
            }
            throw e;
        });
    };
    //copy vertex
    ClientHUB.prototype.cancelVertex = function (vertexName, params, notify) {
        var _this = this;
        if (notify === void 0) { notify = true; }
        clientCache.clearCache(vertexName);
        var path = "/" + vertexName + "/cancel";
        return this.execPut(URLServerProvider.provide(path) + path, params)
            .then(function (res) {
            var _a;
            if (notify) {
                try {
                    _this.onCancelVertex.emit("successful", { vertex: vertexName, res: (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.results });
                }
                catch (error) {
                }
            }
            return res;
        }).catch(function (e) {
            if (notify) {
                try {
                    _this.onCancelVertex.emit("fail", { vertex: vertexName, res: e.response.data });
                }
                catch (error) {
                }
            }
            throw e;
        });
    };
    //get calendar events from Microsoft account
    ClientHUB.prototype.getCalendarEvents = function (params) {
        var path = "/calendar/api";
        return this.execPost(URLServerProvider.provide(path) + path, params);
    };
    /**
     * Get all redirection mails
     */
    ClientHUB.prototype.getRedirectDomains = function () {
        return this.execGet(URLServerProvider.provide(domainsRoute) + domainsRoute);
    };
    ClientHUB.prototype.getAlert = function () {
        return this.execGet(URLServerProvider.provide(alertRoute) + alertRoute);
    };
    /**
     * link nodes
     * @param args
     */
    ClientHUB.prototype.link = function (args) {
        return this.execPost(URLServerProvider.provide(linkRoute) + linkRoute, args);
    };
    ClientHUB.prototype.link2 = function (edge, params) {
        var path = linkRoute + "/" + edge.name;
        return this.execPost(URLServerProvider.provide(path) + path, params);
    };
    /**
     * Get modalities of the parameter dimension
     */
    ClientHUB.prototype.getDimensions = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, Promise, function () {
            var url, res;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        url = URLServerProvider.provide(dimensionRoute) + dimensionRoute;
                        return [4 /*yield*/, this.execGet(url)];
                    case 1:
                        res = (_c = (_b = (_a = (_d.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.results) !== null && _c !== void 0 ? _c : [];
                        res = res.sort(function (a, b) { return TradProp(a.field, ref_Messages).localeCompare(TradProp(b.field, ref_Messages)); });
                        return [2 /*return*/, res];
                }
            });
        });
    };
    /**
     * Get modalities of the parameter dimension
     */
    ClientHUB.prototype.get = function (dim, params, properties) {
        return this.searchVertex(dim.name, params);
    };
    ClientHUB.prototype.GetAutoLink = function () {
        var autoLinkRoute = "/autolink";
        return this.execPost(URLServerProvider.provide(autoLinkRoute) + autoLinkRoute, {});
    };
    // getObjects(objectType: string, params?: { [prop: string]: any }): Promise<Resp<TRes<any>>> {
    //     let cache = clientCache.getCache(objectType, params);
    //     if (cache) return Promise.resolve(cache);
    //     let parameters = !params ? "" : `/? ${encode(params)}`;
    //     const path = `/ ${encodeURI(objectType)}${parameters}`;
    //     return this.execGet(URLServerProvider.provide(path) + path)
    //         .then(this.sort(objectType))
    //         .then((res) => {
    //             clientCache.setCache(objectType, params, res);
    //             return res;
    //         });
    // }
    /**
     *
     * @param linktype ex: HasSupportADWView
     */
    ClientHUB.prototype.getViews = function (linktype) {
        var path = "/ link / ".concat(encodeURI(linktype));
        return this.execGet(URLServerProvider.provide(path) + path);
    };
    /**
     * Add vertices
     * @param dimension
     * @param body
     */
    ClientHUB.prototype.addModalities = function (dimension, body) {
        var path = "/ ".concat(encodeURI(dimension));
        return this.execPost(URLServerProvider.provide(path) + path, body);
    };
    /**
     * Update vertices
     * @param dimension
     * @param body
     */
    ClientHUB.prototype.updateModalities = function (dimension, body) {
        var path = "/ ".concat(encodeURI(dimension));
        return this.execPost(URLServerProvider.provide(path) + path, body);
    };
    /**
     * To log user action
     * @param Action title of action, ex: "Research"
     * @param Informations ex: { Search: "Peugeot", Category: "Advertiser" }
     * @param details verbosity detail: 0 is default, the more is detail the less is important info
     */
    ClientHUB.prototype.log = function (funcArg) {
        return __awaiter(this, void 0, void 0, function () {
            var arg, path, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        arg = __assign(__assign(__assign({}, new logUserArg()), funcArg), { url: window.location.href });
                        path = "".concat(logsRoute, "/user");
                        return [4 /*yield*/, _super.prototype.execPostBase.call(this, URLServerProvider.provide(path) + path, arg)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ClientHUB.prototype.getHubVersion = function () {
        return this.execGet(URLServerProvider.provide(versionRoute) + versionRoute);
    };
    // getToken(code: string): Promise<any> {
    //     let body = {
    //         client_id,
    //         client_secret,
    //         redirect_uri: `${window.origin}${loginRoute}`,
    //         grant_type: 'authorization_code',
    //         code: code
    //     }
    //     return axios.post(`${URLServerProvider.provide(tokenRoute)}${tokenRoute}`, qs.stringify(body), {
    //         ...headers,
    //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //     }).then(this.handleNewToken);
    // }
    // geRefreshtToken(): Promise<any> {
    //     let body = {
    //         client_id,
    //         client_secret,
    //         grant_type: 'refresh_token',
    //         refresh_token: refresh_token
    //     }
    //     return axios.post(`${URLServerProvider.provide(tokenRoute)}${tokenRoute}`, qs.stringify(body), {
    //         ...headers,
    //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //     }).then(this.handleNewToken);
    // }
    ClientHUB.prototype.signalInvalidEstimates = function (ids) {
        var path = "/".concat(ref_Estimates.name, "/notifyerror");
        return this.execPost(URLServerProvider.provide(path) + path, Array.isArray(ids) ? ids : [ids]);
    };
    // private handleNewToken = (res: AxiosResponse<any>) => {
    //     access_token = res.data.access_token;
    //     refresh_token = res.data.refresh_token;
    //     localStorage.setItem('access_token', access_token);
    //     localStorage.setItem('refresh_token', refresh_token);
    //     setHeaderAuthorization(access_token);
    //     return res;
    // }
    ClientHUB.prototype.getMMCascade = function (buySystem) {
        return this.execGet(URLServerProvider.provide('/cascade/insertion/') + "/cascade/insertion/".concat(buySystem));
    };
    ClientHUB.prototype.getMMVersion = function () {
        return this.execGet(URLServerProvider.provide('/media-ocean/version') + "/media-ocean/version");
    };
    ClientHUB.prototype.getStatusAPI = function () {
        return this.execGet(URLServerProvider.provide('/status') + "/status").then(function (v) { return v === null || v === void 0 ? void 0 : v.data; });
    };
    ClientHUB.prototype.getPm2Logs = function () {
        return this.execGet(URLServerProvider.provide('/pm2logs') + "/pm2logs").then(function (v) { return v === null || v === void 0 ? void 0 : v.data; });
    };
    ClientHUB.prototype.getGrafanaUrl = function () {
        return this.execGet(URLServerProvider.provide('/grafana') + "/grafana").then(function (v) { return v === null || v === void 0 ? void 0 : v.data; });
    };
    ClientHUB.prototype.getStatusAPIAdmin = function () {
        return this.execGet(URLServerProvider.provide('/status/admin') + "/status/admin").then(function (v) { return v === null || v === void 0 ? void 0 : v.data; });
    };
    ClientHUB.prototype.getExternalImport = function (token) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var path;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        path = externalimportRoute;
                        return [4 /*yield*/, this.execGet("".concat(URLServerProvider.provide(path)).concat(path, "?token=").concat(token))];
                    case 1: return [2 /*return*/, (_a = (_b.sent())) === null || _a === void 0 ? void 0 : _a.data];
                }
            });
        });
    };
    ClientHUB.prototype.pushExternalImport = function (body) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var path;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        path = externalimportRoute;
                        return [4 /*yield*/, this.execPost("".concat(URLServerProvider.provide(path)).concat(path), body)];
                    case 1: return [2 /*return*/, (_a = (_b.sent())) === null || _a === void 0 ? void 0 : _a.data];
                }
            });
        });
    };
    ClientHUB.prototype.getUserSessions = function (customer) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var path, url;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        path = '/rights';
                        url = customer ? "".concat(URLServerProvider.provide(path)).concat(path, "?customer=").concat(encodeURIComponent(customer)) : "".concat(URLServerProvider.provide(path)).concat(path);
                        return [4 /*yield*/, this.execGet(url)];
                    case 1: return [2 /*return*/, (_b = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.UserSessions];
                }
            });
        });
    };
    ClientHUB.searchDecorator = [];
    return ClientHUB;
}(ClientOAUT2));
export { ClientHUB };
var isConnected = undefined;
export var createSession = function (login, pass) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, cookie;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Client.login(login, pass)];
            case 1:
                resp = _a.sent();
                cookie = resp.headers["set-cookie"][0];
                axios.defaults.headers.Cookie = cookie; // attach cookie to axiosInstance for future requests
                //console.log('createSession');
                //console.log(resp);
                isConnected = resp;
                return [2 /*return*/, resp];
        }
    });
}); };
var KPIContext = /** @class */ (function () {
    function KPIContext() {
    }
    return KPIContext;
}());
export { KPIContext };
export var Client = new ClientHUB("hub-website", "5159949e3ee3b18d54cfed2d721658ff5298ccf4", function () { return URLServerProvider.provide(tokenRoute); }, function () { return "".concat(URLServerProvider.provide(tokenRoute)).concat(tokenRoute); }, function () { return "".concat(window.origin).concat(loginRoute); }, {
    client: {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    }
});
