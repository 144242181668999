var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { clone, propertyOf, SerializeError, toDictionary } from 'hub-lib/tools.bin';
import { ref_TableConfigurations } from 'hub-lib/dto/client/ref_TableConfigurations.bin';
import { ConsoleDebug, TableStorage } from '../utils/localstorage.bin';
import { ref_SchedulerConfigurations } from 'hub-lib/dto/client/ref_SchedulerConfigurations.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { Client } from 'hub-lib/client/client.bin';
import { ref_ExportConfigurations } from 'hub-lib/dto/client/ref_ExportConfigurations.bin';
import { ref_PivotGridConfigurations } from 'hub-lib/dto/client/ref_PivotGridConfigurations.bin';
import { AggregatorFactory } from 'hub-lib/aggregator/AggregatorFactory';
import { IndicateurToString } from 'adwone-engine/index.bin'; // function _setTable(state: ProjectState, config: ref_TableConfigurations) {
//     TableStorage.set(config);
//     state.columnsModele = duplicate(config as any);
// }
export var initializeConfigurations = createAsyncThunk('columnsConfigurations/initializeConfigurations', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _await$AggregatorFact, _AggregatorFactory$Ge, cloned, _a, _b, types, _i, types_1, type, _c, _d, _e, _f, allIndicateurs, _cloned$configuration, _cloned$configuration2, _cloned$configuration3, _cloned$configuration4, _cloned$configuration5, _cloned$configuration6, dicoAll, cols, _g, cols_1, col, sign, error_1;
    var _h, _j, _k, _l, _m, _o;
    return __generator(this, function (_p) {
        switch (_p.label) {
            case 0:
                _a = clone;
                _h = { configurations: TableStorage.getAll() };
                _j = {};
                _b = ref_Messages.name;
                return [4 /*yield*/, ((_AggregatorFactory$Ge = AggregatorFactory.GetInstance().Get(ref_Messages)) === null || _AggregatorFactory$Ge === void 0 ? void 0 : _AggregatorFactory$Ge.Provide())];
            case 1:
                cloned = _a.apply(void 0, [(_h.indicateurOptions = (_j[_b] = (_await$AggregatorFact = _p.sent()) !== null && _await$AggregatorFact !== void 0 ? _await$AggregatorFact : [], _j), _h)]);
                types = [ref_Messages.name, ref_Campaigns.name];
                _i = 0, types_1 = types;
                _p.label = 2;
            case 2:
                if (!(_i < types_1.length)) return [3 /*break*/, 14];
                type = types_1[_i];
                if (!cloned.configurations)
                    cloned.configurations = {};
                if (!cloned.configurations[type])
                    cloned.configurations[type] = {};
                if (!!cloned.configurations[type].table) return [3 /*break*/, 4];
                _c = cloned.configurations[type];
                return [4 /*yield*/, Client.searchVertexTyped(ref_TableConfigurations, (_k = {}, _k[propertyOf('Table')] = type, _k.Default = true, _k))];
            case 3:
                _c.table = (_p.sent())[0];
                _p.label = 4;
            case 4:
                if (!!cloned.configurations[type].scheduler) return [3 /*break*/, 6];
                _d = cloned.configurations[type];
                return [4 /*yield*/, Client.searchVertexTyped(ref_SchedulerConfigurations, (_l = {}, _l[propertyOf('Table')] = type, _l.Default = true, _l))];
            case 5:
                _d.scheduler = (_p.sent())[0];
                _p.label = 6;
            case 6:
                if (!!cloned.configurations[type].export) return [3 /*break*/, 8];
                _e = cloned.configurations[type];
                return [4 /*yield*/, Client.searchVertexTyped(ref_ExportConfigurations, (_m = {}, _m[propertyOf('Table')] = type, _m.Default = true, _m))];
            case 7:
                _e.export = (_p.sent())[0];
                _p.label = 8;
            case 8:
                if (!!cloned.configurations[type].pivotGrid) return [3 /*break*/, 10];
                _f = cloned.configurations[type];
                return [4 /*yield*/, Client.searchVertexTyped(ref_PivotGridConfigurations, (_o = {}, _o[propertyOf('Table')] = type, _o.Default = true, _o))];
            case 9:
                _f.pivotGrid = (_p.sent())[0];
                _p.label = 10;
            case 10:
                _p.trys.push([10, 12, , 13]);
                return [4 /*yield*/, AggregatorFactory.GetInstance().Get(type).Provide()];
            case 11:
                allIndicateurs = _p.sent();
                if (allIndicateurs !== null && allIndicateurs !== void 0 && allIndicateurs.length) {
                    dicoAll = toDictionary(allIndicateurs.map(function (ii) { return ii.indicateur; }), function (i) { return IndicateurToString(i); });
                    cols = __spreadArray(__spreadArray([], ((_cloned$configuration = (_cloned$configuration2 = cloned.configurations[type]) === null || _cloned$configuration2 === void 0 ? void 0 : (_cloned$configuration3 = _cloned$configuration2.table) === null || _cloned$configuration3 === void 0 ? void 0 : _cloned$configuration3.Columns) !== null && _cloned$configuration !== void 0 ? _cloned$configuration : []), true), ((_cloned$configuration4 = (_cloned$configuration5 = cloned.configurations[type]) === null || _cloned$configuration5 === void 0 ? void 0 : (_cloned$configuration6 = _cloned$configuration5.pivotGrid) === null || _cloned$configuration6 === void 0 ? void 0 : _cloned$configuration6.Columns) !== null && _cloned$configuration4 !== void 0 ? _cloned$configuration4 : []), true);
                    for (_g = 0, cols_1 = cols; _g < cols_1.length; _g++) {
                        col = cols_1[_g];
                        sign = IndicateurToString(col);
                        if (dicoAll[sign]) {
                            col.name = dicoAll[sign].name;
                        }
                    }
                }
                return [3 /*break*/, 13];
            case 12:
                error_1 = _p.sent();
                Client.log({ Category: 'error initializeConfigurations', Action: error_1 === null || error_1 === void 0 ? void 0 : error_1['message'], Informations: SerializeError(error_1) });
                return [3 /*break*/, 13];
            case 13:
                _i++;
                return [3 /*break*/, 2];
            case 14:
                TableStorage.set(cloned.configurations);
                cloned.isInitialized = true;
                return [2 /*return*/, cloned];
        }
    });
}); });
var saveModele = function (key, modele, configurations) { var configurationsName = modele.Table; if (!configurations[configurationsName])
    configurations[configurationsName] = {}; configurations[configurationsName][key] = clone(modele); ConsoleDebug("[saveModele] ".concat(key, ": "), modele, configurations); TableStorage.set(configurations); };
export var columnsConfigurationsSlice = createSlice({ name: 'columnsConfigurations', initialState: { configurations: {} }, extraReducers: function (builder) { builder.addCase(initializeConfigurations.fulfilled, function (state, action) { Object.entries(action.payload).forEach(function (_ref) { var key = _ref[0], value = _ref[1]; state[key] = value; }); }); }, reducers: { setTable: function (state, action) { saveModele('table', action.payload, state.configurations); }, setScheduler: function (state, action) { saveModele('scheduler', action.payload, state.configurations); }, setExport: function (state, action) { saveModele('export', action.payload, state.configurations); }, setPivotGrid: function (state, action) { saveModele('pivotGrid', action.payload, state.configurations); } } }); // Action creators are generated for each case reducer function
export var setTable = (_a = columnsConfigurationsSlice.actions, _a.setTable), setScheduler = _a.setScheduler, setExport = _a.setExport, setPivotGrid = _a.setPivotGrid;
export var gridConfigurationsReducer = columnsConfigurationsSlice.reducer;
